import React from 'react';
import './Grid.css';
import GridItem from '../GridItem/GridItem';

function Grid1(props:any) {
  const {gridId, preview } = props;
  return (
    <>
      {preview ? 
      <div className="grid grid1 preview">
        <div className="bg-img grid1-1" />
        <div className="bg-img grid1-2" />
      </div> : <div className="grid grid1">
        <GridItem className="bg-img grid1-1" gridId={gridId} position={1} />
        <GridItem className="bg-img grid1-2" gridId={gridId} position={2} />
      </div>}
    </>
  );
}

export default Grid1;