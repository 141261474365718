export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Query = {
  __typename?: 'Query';
  user?: Maybe<User>;
  userDivisionRoles?: Maybe<Array<Maybe<User>>>;
  allUserDivisionRoles?: Maybe<Array<Maybe<User>>>;
  divisionUsers?: Maybe<Array<Maybe<User>>>;
  accountUsers?: Maybe<Array<Maybe<User>>>;
  accountUsersProjects?: Maybe<Array<Maybe<User>>>;
  roles?: Maybe<Array<Maybe<Role>>>;
  permissions?: Maybe<Array<Maybe<Permission>>>;
  accounts?: Maybe<Array<Maybe<Account>>>;
  account?: Maybe<Account>;
  getCache?: Maybe<Scalars['String']>;
  projects?: Maybe<Array<Maybe<Project>>>;
  templates?: Maybe<Array<Maybe<Project>>>;
  division?: Maybe<Division>;
  divisions?: Maybe<Array<Maybe<Division>>>;
  project?: Maybe<Project>;
  projectUploads?: Maybe<ProjectUploads>;
  projectStatus?: Maybe<Scalars['String']>;
  projectPreviewStatus?: Maybe<Scalars['String']>;
  getVideoOrder?: Maybe<VideoOrder>;
  getVideoExportStatus?: Maybe<RenderingStatus>;
};


export type QueryUserArgs = {
  registerToken?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};


export type QueryUserDivisionRolesArgs = {
  divisionId: Scalars['String'];
};


export type QueryAllUserDivisionRolesArgs = {
  accountId?: Maybe<Scalars['String']>;
};


export type QueryDivisionUsersArgs = {
  divisionId: Scalars['String'];
};


export type QueryAccountUsersArgs = {
  accountId: Scalars['String'];
};


export type QueryAccountUsersProjectsArgs = {
  accountId: Scalars['String'];
};


export type QueryAccountArgs = {
  accountId: Scalars['String'];
};


export type QueryGetCacheArgs = {
  projectId: Scalars['String'];
};


export type QueryProjectsArgs = {
  userId?: Maybe<Scalars['String']>;
  divisionId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryDivisionArgs = {
  divisionId: Scalars['String'];
};


export type QueryDivisionsArgs = {
  accountId?: Maybe<Scalars['String']>;
};


export type QueryProjectArgs = {
  projectId: Scalars['String'];
};


export type QueryProjectUploadsArgs = {
  projectId: Scalars['String'];
};


export type QueryProjectStatusArgs = {
  projectId: Scalars['String'];
};


export type QueryProjectPreviewStatusArgs = {
  projectId: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Role>>>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  account?: Maybe<Account>;
  projects?: Maybe<Array<Maybe<Project>>>;
  disabled?: Maybe<Scalars['Boolean']>;
  lastLoggedIn?: Maybe<Scalars['String']>;
  archivedAt?: Maybe<Scalars['String']>;
  registrationMailSent?: Maybe<Scalars['Boolean']>;
  success?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Error>;
  division?: Maybe<Division>;
};

export type Role = {
  __typename?: 'Role';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Permission>>>;
  divisions?: Maybe<Array<Maybe<Division>>>;
};

export type Permission = {
  __typename?: 'Permission';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
};

export type Division = {
  __typename?: 'Division';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  account?: Maybe<Account>;
  divisions?: Maybe<Array<Maybe<Division>>>;
  users?: Maybe<Array<Maybe<User>>>;
  deleteable?: Maybe<Scalars['Boolean']>;
};

export type Account = {
  __typename?: 'Account';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  iss?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  deleteable?: Maybe<Scalars['Boolean']>;
};

export type Project = {
  __typename?: 'Project';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<BuildStatus>;
  previewStatus?: Maybe<PreviewStatus>;
  cache?: Maybe<Scalars['String']>;
  lastPublished?: Maybe<Scalars['String']>;
  lastPreviewed?: Maybe<Scalars['String']>;
  lastSaved?: Maybe<Scalars['String']>;
  lastAccessed?: Maybe<Scalars['String']>;
  lastAccessedBy?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  duplicateOf?: Maybe<Scalars['String']>;
  template?: Maybe<Template>;
};

export enum BuildStatus {
  Unbuilt = 'UNBUILT',
  Building = 'BUILDING',
  Invalidating = 'INVALIDATING',
  Built = 'BUILT'
}

export enum PreviewStatus {
  Unbuilt = 'UNBUILT',
  Building = 'BUILDING',
  Built = 'BUILT'
}

export type Template = {
  __typename?: 'Template';
  id?: Maybe<Scalars['String']>;
  accounts?: Maybe<Array<Maybe<Account>>>;
};

export type Error = {
  __typename?: 'Error';
  code?: Maybe<Scalars['Int']>;
  msg?: Maybe<Scalars['String']>;
};

export type ProjectUploads = {
  __typename?: 'ProjectUploads';
  uploads?: Maybe<Array<File>>;
};

export type File = {
  __typename?: 'File';
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status?: Maybe<FileStatus>;
  type: FileType;
};

export enum FileStatus {
  Submitted = 'SUBMITTED',
  Processing = 'PROCESSING',
  Complete = 'COMPLETE'
}

export enum FileType {
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type VideoOrder = {
  __typename?: 'VideoOrder';
  id: Scalars['String'];
  revisionLimit: Scalars['Int'];
  revisionCount: Scalars['Int'];
  socketIdEditing?: Maybe<Scalars['String']>;
  video: Video;
  content: VideoContent;
  template: VideoTemplate;
  export?: Maybe<VideoExport>;
};

export type Video = {
  __typename?: 'Video';
  state?: Maybe<Scalars['String']>;
};

export type VideoContent = {
  __typename?: 'VideoContent';
  images?: Maybe<Array<VideoContentImage>>;
  metaData?: Maybe<Scalars['String']>;
};

export type VideoContentImage = {
  __typename?: 'VideoContentImage';
  category: Scalars['String'];
  thumbnailUrl: Scalars['String'];
  imageUrl: Scalars['String'];
};

export type VideoTemplate = {
  __typename?: 'VideoTemplate';
  name: Scalars['String'];
  textColor: Scalars['String'];
  textBackground: Scalars['String'];
  textSize: TextSize;
  maxSlides: Scalars['Int'];
  secondsPerSlide: Scalars['Int'];
  logotype: Scalars['String'];
  animation: VideoAnimation;
  transition: VideoTransition;
  fps: Scalars['Int'];
};

export enum TextSize {
  Small = 'SMALL',
  Medium = 'MEDIUM'
}

export enum VideoAnimation {
  Zoom = 'ZOOM',
  None = 'NONE'
}

export enum VideoTransition {
  Fade = 'FADE'
}

export type VideoExport = {
  __typename?: 'VideoExport';
  exportedAt: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  status: RenderingStatus;
  exportedVideoState?: Maybe<Scalars['String']>;
};

export enum RenderingStatus {
  Rendering = 'RENDERING',
  Finished = 'FINISHED',
  Failed = 'FAILED'
}

export type Mutation = {
  __typename?: 'Mutation';
  login: LoginResponse;
  register?: Maybe<User>;
  recoverPassword: SuccessResponse;
  sendRegistrationMail: SuccessResponse;
  updateUserArchived: SuccessResponse;
  createProject: Project;
  deleteProject: SuccessResponse;
  duplicateProject: SuccessResponse;
  lastAccessed: SuccessResponse;
  saveCache: SuccessResponse;
  build: SuccessResponse;
  unpublish: SuccessResponse;
  updateTemplateAccount: SuccessResponse;
  updateProjectName: SuccessResponse;
  updateAccount: SuccessResponse;
  deleteAccount: SuccessResponse;
  createAccount?: Maybe<Account>;
  updateDivision: SuccessResponse;
  deleteDivision: SuccessResponse;
  createDivision?: Maybe<Division>;
  updateUser?: Maybe<User>;
  updateDivisionUserRoles: SuccessResponse;
  deleteUpload: SuccessResponse;
  uploadImgToBucket?: Maybe<Scalars['String']>;
  saveVideoState: SuccessResponse;
  exportVideo: SuccessResponse;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRegisterArgs = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  divisionId?: Maybe<Scalars['String']>;
  registerToken?: Maybe<Scalars['String']>;
};


export type MutationRecoverPasswordArgs = {
  email: Scalars['String'];
};


export type MutationSendRegistrationMailArgs = {
  userId: Scalars['String'];
};


export type MutationUpdateUserArchivedArgs = {
  userId: Scalars['String'];
  archived: Scalars['Boolean'];
};


export type MutationCreateProjectArgs = {
  userId?: Maybe<Scalars['String']>;
  newTemplate?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteProjectArgs = {
  projectId: Scalars['String'];
};


export type MutationDuplicateProjectArgs = {
  projectId: Scalars['String'];
  fromTemplate?: Maybe<Scalars['Boolean']>;
  template?: Maybe<Scalars['Boolean']>;
  toTemplate?: Maybe<Scalars['Boolean']>;
};


export type MutationLastAccessedArgs = {
  projectId: Scalars['String'];
};


export type MutationSaveCacheArgs = {
  projectId: Scalars['String'];
  cache: Scalars['String'];
};


export type MutationBuildArgs = {
  projectId: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
};


export type MutationUnpublishArgs = {
  projectId: Scalars['String'];
};


export type MutationUpdateTemplateAccountArgs = {
  template: Scalars['String'];
  accountId: Scalars['String'];
  disconnect?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateProjectNameArgs = {
  projectId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};


export type MutationUpdateAccountArgs = {
  accountId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteAccountArgs = {
  accountId: Scalars['String'];
};


export type MutationCreateAccountArgs = {
  name?: Maybe<Scalars['String']>;
};


export type MutationUpdateDivisionArgs = {
  divisionId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  parentDivisionId?: Maybe<Scalars['String']>;
};


export type MutationDeleteDivisionArgs = {
  divisionId: Scalars['String'];
};


export type MutationCreateDivisionArgs = {
  name: Scalars['String'];
  parentDivisionId: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  userId: Scalars['String'];
  name: Scalars['String'];
  divisionId: Scalars['String'];
  email: Scalars['String'];
  disabled: Scalars['Boolean'];
};


export type MutationUpdateDivisionUserRolesArgs = {
  userId: Scalars['String'];
  divisionId: Scalars['String'];
  addedRoleIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  removedRoleIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationDeleteUploadArgs = {
  fileId: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationUploadImgToBucketArgs = {
  videoContentImageIndex: Scalars['Int'];
};


export type MutationSaveVideoStateArgs = {
  videoState: Scalars['String'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  token?: Maybe<Scalars['String']>;
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Error>;
};

export type UserRole = {
  __typename?: 'UserRole';
  id?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  user?: Maybe<User>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}


export type GetVideoOrderQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVideoOrderQuery = (
  { __typename?: 'Query' }
  & { getVideoOrder?: Maybe<(
    { __typename?: 'VideoOrder' }
    & Pick<VideoOrder, 'id' | 'revisionLimit' | 'revisionCount' | 'socketIdEditing'>
    & { video: (
      { __typename?: 'Video' }
      & Pick<Video, 'state'>
    ), content: (
      { __typename?: 'VideoContent' }
      & Pick<VideoContent, 'metaData'>
      & { images?: Maybe<Array<(
        { __typename?: 'VideoContentImage' }
        & Pick<VideoContentImage, 'category' | 'thumbnailUrl' | 'imageUrl'>
      )>> }
    ), template: (
      { __typename?: 'VideoTemplate' }
      & Pick<VideoTemplate, 'name' | 'textColor' | 'textBackground' | 'textSize' | 'maxSlides' | 'secondsPerSlide' | 'logotype' | 'animation' | 'transition' | 'fps'>
    ), export?: Maybe<(
      { __typename?: 'VideoExport' }
      & Pick<VideoExport, 'exportedAt' | 'url' | 'status' | 'exportedVideoState'>
    )> }
  )> }
);

export type GetVideoExportStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVideoExportStatusQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getVideoExportStatus'>
);
