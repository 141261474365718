import React from 'react';
import './Grid.css';
import GridItem from '../GridItem/GridItem';

function Grid4(props:any) {
  const {gridId, preview } = props;
  return (
    <>
      {preview ? <div className="grid grid2 preview">
        <div className="bg-img grid4-1"/>
        <div className="bg-img grid4-2"/>
        <div className="bg-img grid4-3"/>
      </div> : <div className="grid grid2">
        <GridItem className="bg-img grid4-1" gridId={gridId} position={1}/>
        <GridItem className="bg-img grid4-2" gridId={gridId} position={2}/>
        <GridItem className="bg-img grid4-3" gridId={gridId} position={3}/>
      </div>}
    </>
  );
}

export default Grid4;