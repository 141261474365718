import React from "react";
import "./Grid7.css";
import GridItem from "../GridItem/GridItem";
import InputField from "../InputField/InputField";

function Grid9(props: any) {
  const { gridId, preview } = props;
  return (
    <>
    {preview ? 
      <div className="grid grid9 preview">
        <div className="bg-img grid9-container"></div>
        <div id="grid9-text-container">
          <div id="grid9-text-container-inner-1">
          </div>
        </div>
      </div>
      : <div className="grid grid9">
      <GridItem className="bg-img grid9-container" gridId={gridId} position={1}/>
      <div id="grid9-text-container">
        <div id="grid9-text-container-inner-1">
          <GridItem className="bg-img grid9-container grid9-small-container" gridId={gridId} position={2}/>
        </div>
      </div>
    </div>
    }
    </>
  );
}

export default Grid9;
