
import React, { useState, useEffect, useRef, useMemo } from 'react';
import './TextArea.css';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { UPDATE_GRID_TEXTAREA } from '../graphql/mutations';
import { GET_ITEM } from '../graphql/queries';
import ReactQuill from 'react-quill';
import './quill.css';
import ReactHtmlParser from 'react-html-parser';

function TextArea(props:any) {
  const { gridId, position, className } = props;
  const [edit, setEdit] = useState(false)
  const [updateTextArea] = useMutation(UPDATE_GRID_TEXTAREA);
  const inputRef = useRef<any>(null)
  const [bgColor, setBgColor] = useState('#fff')

  const [editorHtml, setEditorHtml] = useState<any>(null);
  const handleChange = (html:string) => {
  	setEditorHtml( html );
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.backgroundColor = bgColor;
    }
  }, [bgColor])

  // Only initilize modules once on creation
  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{size: []}],
          ['bold', 'italic', 'underline','link'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          [{ 'color': [] }, { 'background': [] }],
          ['clean']
        ],
        handlers: {
          'background': (value:any) => {
            if (value === false) {
              value = "#ffffff"
            }
            setBgColor(value);
          },
        }
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      }
    }
  }, [])
  
  const quillRef = useRef<any>(null)
  useEffect(() => {
    if (quillRef && quillRef.current) {
      quillRef.current.focus();
      const editor = quillRef.current.getEditor()
      if (editor && editor.root) {
        const editorEl:HTMLElement = editor.root;
        editorEl.classList.add("text-1")
      }
    }
  }, [edit])


  const {data} = useQuery(GET_ITEM, {variables:{gridId: gridId, position:position}})
  useEffect(()=>{
    if (data && data.item && data.item.text && data.item.text != editorHtml) {
      let savedBgColor = data.item.text.split("background-color: ")[1];
      if (savedBgColor) {
        savedBgColor = savedBgColor.split(";")[0];
        setBgColor(savedBgColor)
      }

      setEditorHtml(data.item.text)
    }
  }, [data])

  return <>
  <div 
    className="input-container"
    ref={inputRef}
    style={{position: 'relative', zIndex: 3, height: '100%'}}
  >
    {!edit && <div 
      onClick={()=>{
        if (!edit) {
          setEdit(true)
        }
      }}
      className={"ql-snow ql-editor text expand-text-area "+className}>
      {(editorHtml !== null ? ReactHtmlParser(editorHtml)  : "- TEXT -")}
    </div>}
    {edit && <>
    <div className="border-button ion-checkmark editor-confirm-button" onClick={()=>{
      if (editorHtml) {
        const html = `<div style="background-color: ${bgColor};">${editorHtml}</div>`
        updateTextArea({variables:{gridId: gridId, text: html, position: position}})
      }
      setEdit(false);
    }}></div>
    <ReactQuill
      ref={quillRef}
      style={{width: '100%', height: 'calc(100% - 42px)', marginBottom: 42}}
      theme="snow"
      onChange={handleChange}
      modules={modules}
      value={editorHtml}
      placeholder={"- TEXT -"}
    /></>}
  </div>
  </>
}

export default TextArea;
