import gql from 'graphql-tag';

export const GET_USER = gql`
  query getUser($registerToken: String, $email: String) {
    user(registerToken: $registerToken, email: $email) {
      id
      name
      email
      roleId
      permissions
      account {
        id
        name
        iss
        logoUrl
      }
      division {
        id
        name
      }
      projects {
        id
      }
    }
  }
`;

export const GET_DIVISION_USERS = gql`
  query getDivisionUsers($divisionId: String!) {
    divisionUsers(divisionId: $divisionId) {
      id
      name
      email
      lastLoggedIn
      disabled
      archivedAt
      registrationMailSent
      division {
        id
        name
      }
      projects {
        id
      }
    }
  }
`;

export const GET_ACCOUNT_USERS = gql`
  query getAccountUsers($accountId: String!) {
    accountUsers(accountId: $accountId) {
      id
      name
      email
      lastLoggedIn
      disabled
      archivedAt
      registrationMailSent
      division {
        id
        name
      }
      projects {
        id
      }
    }
  }
`;

export const GET_ACCOUNT_USERS_PROJECTS = gql`
  query getAccountUsersProjects($accountId: String!) {
    accountUsersProjects(accountId: $accountId) {
      id
      name
      projects {
        id
      }
    }
  }
`;

export const GET_ACCOUNTS = gql`
  query getAccounts {
    accounts {
      id
      name
      logoUrl
    }
  }
`;

export const GET_ACCOUNT = gql`
  query getAccount($accountId: String!) {
    account(accountId: $accountId) {
      id
      name
      logoUrl
      disabled
      deleteable
    }
  }
`;

export const GET_CACHE = gql`
  query getCache($projectId: String) {
    getCache(projectId: $projectId)
  }
`;

export const GET_PROJECT = gql`
  query project($projectId: String!) {
    project(projectId: $projectId) {
      id,
      name,
      status,
      previewStatus,
      cache,
      lastPublished,
      lastPreviewed,
      lastSaved,
      duplicateOf,
      template {
        id,
        accounts {
          id,
          name,
          logoUrl
        }
      }
    }
  }
`;

export const GET_PROJECT_UPLOADS = gql`
  query projectUploads($projectId: String!) {
    projectUploads(projectId: $projectId) {
      uploads {
        id,
        name,
        status,
        type
      }
    }
  }
`;

export const GET_PROJECT_STATUS = gql`
  query projectStatus($projectId: String!) {
    projectStatus(projectId: $projectId)
  }
`;

export const GET_PROJECT_PREVIEW_STATUS = gql`
  query projectPreviewStatus($projectId: String!) {
    projectPreviewStatus(projectId: $projectId)
  }
`;

export const GET_PROJECTS = gql`
  query getProjects($userId: String, $divisionId: String, $accountId: String, $limit: Int) {
    projects(userId: $userId, divisionId: $divisionId, accountId: $accountId, limit: $limit) {
      id,
      name,
      status,
      cache,
      lastSaved,
      lastPreviewed,
      lastPublished,
      lastAccessed,
      lastAccessedBy
      user {
        name
      },
      template {
        id
      }
    }
  }
`;

export const GET_TEMPLATES = gql`
  query getTemplates {
    templates {
      id,
      name
    }
  }
`;

export const GET_DIVISIONS = gql`
  query getDivisions($accountId: String) {
    divisions(accountId: $accountId) {
      id
      name
      divisions {
        id
        name
        divisions {
          id
          name
          divisions {
            id
            name
            divisions {
              id
              name
              divisions {
                id
                name
                divisions {
                  id
                  name
                  divisions {
                    id
                    name
                    divisions {
                      id
                      name
                      divisions {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_DIVISION = gql`
  query getDivision($divisionId: String!) {
    division(divisionId: $divisionId) {
      id
      name
      deleteable
    }
  }
`;

export const GET_USER_DIVISION_ROLES = gql`
  query getUserDivisionRoles($divisionId: String!) {
    userDivisionRoles(divisionId: $divisionId) {
      id
      name
      email
      roles {
        id
        name
        permissions {
          id
          name
          category
        }
      }
    }
  }
`;

export const GET_ALL_USER_DIVISION_ROLES = gql`
  query getAllUserDivisionRoles($accountId: String) {
    allUserDivisionRoles(accountId: $accountId) {
      id
      name
      email
      roles {
        id
        name
        permissions {
          id
          name
          category
        }
        divisions {
          id
          name
        }
      }
    }
  }
`;

export const GET_ROLES = gql`
  query getRoles {
    roles {
      id
      name
      permissions {
        id
        name
        category
      }
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query getPermissions {
    permissions {
      id
      name
      category
      action
    }
  }
`;

export const GET_SAVED_STATE = gql`
  query saved {
    saved @client
  }
`;

export const GET_TIMES_USED = gql`
  query timesUsed($url: String) {
    timesUsed(url: $url) @client
  }
`;

export const GET_SECTIONS = gql`
  query getSections {
    sections @client {
      id,
      tag,
      title,
      grids {
        id,
        type,
        items {
          ... on Item {
            id,
            url,
          }
          ... on Map {
            id,
            lat,
            lng,
            zoom
          }
          ... on Slider {
            id,
            images {
              url,
              startFrame {
                scale,
                translateX,
                translateY
              }
            }
          }
          ... on Image {
            url,
            id,
            startFrame {
              scale,
              translateX,
              translateY
            },
            endFrame {
              scale,
              translateX,
              translateY
            },
            animationSpeed,
            showInSlideshow
          }
          ... on TextArea {
            id,
            text
          }
        },
        texts {
          text,
          id
        }
      }
    }
  }
`;


export const GET_SECTION_GRIDS = gql`
  query sectionGrids($sectionId: String) {
    sectionGrids(sectionId: $sectionId) @client {
      id,
      type,
      items {
        ... on Item {
          id,
          url,
        }
        ... on Map {
          id,
          lat,
          lng,
          zoom
        }
        ... on Slider {
          id,
          images {
            url,
            startFrame {
              scale,
              translateX,
              translateY
            }
          }
        }
        ... on Image {
          url,
          id,
          startFrame {
            scale,
            translateX,
            translateY
          },
          endFrame {
            scale,
            translateX,
            translateY
          },
          animationSpeed,
          showInSlideshow
        },
        ... on TextArea {
          id,
          text
        }
      },
      texts {
        id,
        text
      }
    }
  }
`;

export const GET_ITEM = gql`
  query item($gridId: String, $position: Int) {
    item(gridId: $gridId, position: $position) @client {
      ... on Item {
          id,
          url,
      }
      ... on Map {
        id,
        lat,
        lng,
        zoom
      }
      ... on Slider {
        id,
        images {
          url,
          startFrame {
            scale,
            translateX,
            translateY
          }
        }
      }
      ... on Image {
        url,
        id,
        startFrame {
          scale,
          translateX,
          translateY
        },
        endFrame {
          scale,
          translateX,
          translateY
        },
        animationSpeed,
        showInSlideshow
      },
      ... on TextArea {
        id,
        text
      }
    }
  }
`;

export const GET_MAP = gql`
  query map($itemId: String) {
    map(itemId: $itemId) @client {
      lat,
      lng,
      zoom,
      id
    }
  }
`;

export const GET_SLIDER = gql`
  query slider($itemId: String) {
    slider(itemId: $itemId) @client {
      id,
      images {
        url,
        startFrame {
          scale,
          translateX,
          translateY
        }
      }
    }
  }
`;

export const GET_TEXT = gql`
  query text($textId: String) {
    text(textId: $textId) @client {
      id,
      text
    }
  }
`;

export const GET_SECTION_TITLE = gql`
  query sectionTitle($sectionId: String) {
    sectionTitle(sectionId: $sectionId) @client {
      title
    }
  }
`;

export const GET_SECTION_TAG = gql`
  query sectionTag($sectionId: String) {
    sectionTag(sectionId: $sectionId) @client {
      tag
    }
  }
`;