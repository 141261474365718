import React, { useEffect, useState } from "react";
import { BsFillImageFill } from "react-icons/bs";
import { MdAnimation, MdTextFields } from "react-icons/md";
import { GetVideoOrderQuery } from "../graphql/graphqlTypes-video-queries";
import { VideoState } from "./Video";
import { Panel } from "./VideoEditor";

type Props = {
  currentSlide: number | "new";
  setOpenPanel: Function;
  videoState: VideoState;
  videoOrderData: GetVideoOrderQuery;
};

function BottomNavbar(props: Props) {
  const { setOpenPanel, currentSlide, videoOrderData, videoState } = props;

  return (
    <div
      id="bottom-navbar"
      style={{
        backgroundColor: "#4F4F4F",
        display: "flex",
        width: "100%",
        padding: 20,
        zIndex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "33%",
          opacity:
            currentSlide === "new" &&
            videoOrderData.getVideoOrder.template.maxSlides <=
              videoState.slides.length
              ? 0.5
              : 1,
        }}
        onClick={() => {
          if (
            currentSlide !== "new" ||
            videoOrderData.getVideoOrder.template.maxSlides >
              videoState.slides.length
          ) {
            setOpenPanel(Panel.IMAGE);
          }
        }}
      >
        <BsFillImageFill style={{ fontSize: 16, color: "white" }} />
        <div
          style={{
            fontSize: 10,
            color: "white",
            fontWeight: 600,
            marginTop: 8,
          }}
        >
          Image
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "33%",
          opacity: currentSlide !== "new" ? 1 : 0.5,
        }}
        onClick={() => {
          if (currentSlide !== "new") {
            setOpenPanel(Panel.TEXT);
          }
        }}
      >
        <MdTextFields style={{ fontSize: 18, color: "white" }} />
        <div
          style={{
            marginTop: 6,
            fontSize: 10,
            color: "white",
            fontWeight: 600,
          }}
        >
          Text
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "33%",
          opacity: currentSlide !== "new" ? 1 : 0.5,
        }}
        onClick={() => {
          if (currentSlide !== "new") {
            setOpenPanel(Panel.ANIMATION);
          }
        }}
      >
        <MdAnimation style={{ fontSize: 16, color: "white" }} />
        <div
          style={{
            marginTop: 8,
            fontSize: 10,
            color: "white",
            fontWeight: 600,
          }}
        >
          Animation
        </div>
      </div>
    </div>
  );
}

export default BottomNavbar;
