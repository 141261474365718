
import React, { useState, useEffect } from 'react';
import './InputField.css';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { UPDATE_SECTION_TAG, UPDATE_SECTION_TITLE, UPDATE_GRID_TEXTS, UPDATE_PROJECT_NAME } from '../graphql/mutations';
import { GET_TEXT, GET_SECTION_TITLE, GET_SECTION_TAG, GET_PROJECT } from '../graphql/queries';
import { useParams } from 'react-router-dom';

function InputField(props:any) {
  const { type, sectionId, className, gridId, position, removeTag, removeTitle } = props;
  let { projectId } = useParams();
  const [edit, setEdit] = useState(false)
  const [text, setText] = useState<any>(null);
  const [updateSectionTag] = useMutation(UPDATE_SECTION_TAG);
  const [updateSectionTitle] = useMutation(UPDATE_SECTION_TITLE);
  const [updateGridTexts] = useMutation(UPDATE_GRID_TEXTS);
  const [updateProjectName] = useMutation(UPDATE_PROJECT_NAME);

  const {data: textData} = useQuery(GET_TEXT, {variables:{textId: gridId+"_text_"+position}, skip: type !== "text", fetchPolicy: 'no-cache'})
  const {data: titleData} = useQuery(GET_SECTION_TITLE, {variables:{sectionId: sectionId}, skip: type !== "title", fetchPolicy: 'no-cache'})
  const {data: tagData} = useQuery(GET_SECTION_TAG, {variables:{sectionId: sectionId}, skip: type !== "tag", fetchPolicy: 'no-cache'})
  const { data:projectData } = useQuery(GET_PROJECT, {variables:{projectId: projectId}, skip: !projectId || type !== "project", fetchPolicy: 'no-cache'});
  
  useEffect(()=>{
    if (textData && textData.text && textData.text.text != text) {
      setText(textData.text.text)
    }
    if (titleData && titleData.sectionTitle && titleData.sectionTitle.title != text) {
      setText(titleData.sectionTitle.title)
    }
    if (tagData && tagData.sectionTag && tagData.sectionTag.tag != text) {
      setText(tagData.sectionTag.tag)
    }
    if (projectData && projectData.project.name && projectData.project.name != text) {
      setText(projectData.project.name)
    }
  },[textData, titleData, tagData, projectData])

  const updateCache = () => {
    if (type === "title") {
      updateSectionTitle({variables:{title: text, sectionId: sectionId}})
      if (text === "") {
        removeTitle()
      }
    } else if (type === "tag") {
      updateSectionTag({variables:{tag: text, sectionId: sectionId}})
      if (text === "") {
        removeTag()
      }
    } else if (type === "text") {
      updateGridTexts({variables:{gridId:gridId, text:text, position: position}})
    } else if (type === "project") {
      if (text) {
        updateProjectName({variables:{projectId: projectId, name: text}})
      }
    }
  }

  return <>
  {<div 
    className="input-container"
    onBlur={()=>{
      setEdit(false);updateCache();
    }} 
    onClick={()=>setEdit(true)}
  >
    {!edit && text !== "__DELETED__" && <div 
      className={"text" + " " + className} 
      style={type === "title" ? {
          fontSize: text ? 'calc(6vw - '+text.length*0.03+'vw)' : '6vw', 
          lineHeight: text ? 'calc(6vw - '+text.length*0.03+'vw)' : '6vw',
          width: '100%'
        } : {
          minHeight: type !== "project" ? '2vw' : 'inherit', 
          width: '100%'
        }}>
      {(text !== null ? text  : "- TEXT -")}
    </div>}

    {edit && <>
      <input 
        autoFocus 
        className={"input" + " " + className} 
        type="text" 
        id="fname" 
        name="fname" 
        value={text !== null ? text : "- TEXT -"} 
        onChange={(e)=>{
          if (type === "project" && !e.target.value) return;
          setText(e.target.value)
        }} 
        onFocus={(e)=>{e.target.select()}}
        onKeyDown={(e) => {if(e.key === 'Enter'){setEdit(false);updateCache();}}}>
      </input>
    </>}
  </div>}
  </>
}


export default InputField;