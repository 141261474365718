import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  GetVideoOrderQuery,
  GetVideoOrderQueryVariables,
  VideoTemplate,
} from "../graphql/graphqlTypes-video-queries";
import { GET_VIDEO_ORDER } from "../graphql/video-queries";
import "./video.css";
import { AnimatePresence, motion } from "framer-motion";
import { MetaData, VideoState } from "./Video";
import { estimateVideoRenderTime, idleDebounce, useStateRef } from "../utils";
import { SAVE_VIDEO_STATE } from "../graphql/video-mutations";
import {
  SaveVideoStateMutation,
  SaveVideoStateMutationVariables,
} from "../graphql/graphqlTypes-video-mutations";
import * as io from "socket.io-client";
import LoadingScreen from "./LoadingScreen";
import SubNavbar from "./SubNavbar";
import VideoPlayer from "./VideoPlayer";
import ModalPanel from "./ModalPanel";
import BottomNavbar from "./BottomNavbar";
import TopNavbar from "./TopNavbar";
import Timeline from "./Timeline";

export enum Panel {
  NONE,
  IMAGE,
  TEXT,
  ANIMATION,
}

const idleTime = 1000 * 60 * 30; // 30 minutes

function VideoEditor() {
  const [videoState, setVideoState] = useState<VideoState>(null);
  const [videoTemplate, setVideoTemplate] = useState<VideoTemplate>(null);
  const [videoContentMetaData, setVideoContentMetaData] =
    useState<MetaData>(null);
  const [openPanel, setOpenPanel, openPanelRef] = useStateRef<Panel>(
    Panel.NONE
  );
  const [currentSlide, setCurrentSlide] = useState<number | "new">(null);
  const [currentFrame, setCurrentFrame, currentFrameRef] = useStateRef(0);
  const [expanded, setExpanded] = useState(false);
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const [showLockedMessage, setShowLockedMessage] = useState(false);
  const [showIdleMessage, setShowIdleMessage] = useState(false);
  const [socket, setSocket] = useState(null);
  const [showProgressBar, setShowProgressBar] = useState(false);

  // Turn of text marking (when longpressing for moving slide image)
  document.body.classList.add("noselect");

  // subNavRef for keeping track of its height which depends on length of metadata.address
  const subNavRef = useRef(null);

  const { data: videoOrderData, refetch: videoOrderDataRefetch } = useQuery<
    GetVideoOrderQuery,
    GetVideoOrderQueryVariables
  >(GET_VIDEO_ORDER, { fetchPolicy: "cache-and-network" });
  const [saveVideoState] = useMutation<
    SaveVideoStateMutation,
    SaveVideoStateMutationVariables
  >(SAVE_VIDEO_STATE);

  const increaseIdleTimeout = (socket) => {
    var returnedFunction = idleDebounce(function () {
      console.log("SEND IDLE");
      socket.emit("idle", true);
    }, idleTime);
    returnedFunction();
  };

  useEffect(() => {
    if (videoState) {
      const saveState = async (videoState) => {
        const saveResponse = await saveVideoState({
          variables: {
            videoState: JSON.stringify(videoState),
          },
          refetchQueries: [{ query: GET_VIDEO_ORDER }],
        });
        if (!saveResponse.data || !saveResponse.data.saveVideoState.success) {
          // TODO: Show error
        }
      };

      // Send save state mutation on videoState change
      if (
        JSON.stringify(videoState) !== videoOrderData.getVideoOrder.video.state
      ) {
        saveState(videoState);
      }

      // Postpone idle state each state change
      if (socket) {
        increaseIdleTimeout(socket);
      }
    }
  }, [videoState]);

  useEffect(() => {
    // Prevent "overscrolling" when saving as a PWA
    document.body.addEventListener(
      "touchmove",
      function (event) {
        event.preventDefault();
      },
      {
        passive: false,
      }
    );

    // Initiallize websocket connection
    const orderId = window.location.pathname.split("/video/")[1];
    const token = localStorage.getItem("jwt-" + orderId);
    const socket = io.connect(
      process.env.NODE_ENV === "production"
        ? "wss://viewfinder.se" //For staging: "ws://192.168.10.26:9002"
        : "ws://localhost:5001",
      {
        query: { token },
        transports: ["websocket"],
      }
    );

    socket.on("unlock", () => {
      setSocket(socket);
      setTimeout(() => {
        setShowLoadingScreen(false);
      }, 2000);

      // Set initial idle timeout - is delayed on state update
      increaseIdleTimeout(socket);
      socket.on("disconnect", () => {
        console.log("DISCONNECTED - SHOW IDLE");
        setShowIdleMessage(true);
        setShowLoadingScreen(true);
      });
      socket.on("rendering-finished", () => {
        console.log("RENDERING FINISHED", new Date().toISOString());
        // Rendering finished - refetch videoOrder data
        videoOrderDataRefetch();
      });
    });
    socket.on("lock", () => {
      setShowLockedMessage(true);
    });
  }, []);

  useEffect(() => {
    if (videoOrderData) {
      // Initilize videoTemplate and videoContentMetaData
      if (!videoTemplate) {
        setVideoTemplate(videoOrderData.getVideoOrder.template);
      }
      if (!videoContentMetaData) {
        setVideoContentMetaData(
          JSON.parse(videoOrderData.getVideoOrder.content.metaData)
        );
      }

      // Preload images
      videoOrderData.getVideoOrder.content.images.forEach((img) => {
        var image = new Image();
        image.src = img.imageUrl;

        var thumbnail = new Image();
        thumbnail.src = img.thumbnailUrl;
      });

      // Update video state to be in sync with db video state
      if (videoOrderData.getVideoOrder.video.state) {
        setVideoState(JSON.parse(videoOrderData.getVideoOrder.video.state));
      }

      // Show progress bar when rendering
      if (videoOrderData.getVideoOrder.export) {
        if (videoOrderData.getVideoOrder.export.status === "RENDERING") {
          console.log("STARTED RENDERING", new Date().toISOString());
          setShowProgressBar(true);
        } else {
          console.log("HIDE PROGRESS BAR", new Date().toISOString());
          setShowProgressBar(false);
        }
      }
    }
  }, [videoOrderData]);

  useEffect(() => {
    if (videoOrderData && videoState) {
      // Setting currentSlide based on frame
      const slideIndex = Math.floor(
        currentFrame /
          (videoOrderData.getVideoOrder.template.fps *
            videoOrderData.getVideoOrder.template.secondsPerSlide)
      );
      if (slideIndex > videoState.slides.length - 1) {
        setCurrentSlide("new");
      } else {
        setCurrentSlide(slideIndex);
      }
    }
  }, [currentFrame, videoOrderData, videoState]);

  if (!videoState) {
    return <></>;
  }

  return (
    <>
      <AnimatePresence exitBeforeEnter={true}>
        {showLoadingScreen && (
          <LoadingScreen
            showIdleMessage={showIdleMessage}
            showLockedMessage={showLockedMessage}
          />
        )}
      </AnimatePresence>

      {!showLoadingScreen && (
        <div
          style={{
            width: "100vw",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            overflow: "hidden",
            position: "relative",
            backgroundColor: "#232323",
          }}
        >
          <TopNavbar
            videoExport={videoOrderData && videoOrderData.getVideoOrder.export}
            exportLimitReached={
              videoOrderData &&
              videoOrderData.getVideoOrder.revisionCount ===
                videoOrderData.getVideoOrder.revisionLimit
            }
            setCurrentFrame={setCurrentFrame}
            setVideoState={setVideoState}
            videoState={videoState}
            videoOrderData={videoOrderData}
            setCurrentSlide={setCurrentSlide}
          />

          <AnimatePresence exitBeforeEnter={true}>
            {showProgressBar && (
              <motion.div
                id="progress-bar"
                exit={{ height: 0 }}
                initial={{ height: 0 }}
                animate={{ height: 6 }}
                transition={{ duration: 0.5 }}
                style={{
                  width: "100vw",
                  backgroundColor: "#232323",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <motion.div
                  animate={{ width: "99%" }}
                  initial={{ width: "0%" }}
                  style={{
                    background:
                      "linear-gradient(91deg, rgb(0, 148, 255) 0%, rgb(5 109 185) 100%)",
                    height: 4,
                  }}
                  transition={{
                    duration: estimateVideoRenderTime(
                      videoState.slides.length,
                      videoTemplate.secondsPerSlide,
                      videoTemplate.fps,
                      videoOrderData.getVideoOrder.export.exportedAt
                    ),
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>

          <SubNavbar
            expanded={expanded}
            setVideoState={setVideoState}
            subNavRef={subNavRef}
            videoContentMetaData={videoContentMetaData}
            videoState={videoState}
          />

          <VideoPlayer
            currentFrame={currentFrame}
            currentSlide={currentSlide}
            expanded={expanded}
            setCurrentFrame={setCurrentFrame}
            setExpanded={setExpanded}
            setVideoState={setVideoState}
            subNavRef={subNavRef}
            videoOrderData={videoOrderData}
            videoState={videoState}
            videoTemplate={videoTemplate}
            currentFrameRef={currentFrameRef}
            openPanel={openPanel}
            openPanelRef={openPanelRef}
            progressBarShown={showProgressBar}
          />

          {/* Placeholder to keep the height when expanding the video */}
          {expanded && (
            <div
              style={{
                minHeight: "100%",
              }}
            ></div>
          )}

          <Timeline
            videoState={videoState}
            setVideoState={setVideoState}
            videoTemplate={videoTemplate}
            setCurrentSlide={setCurrentSlide}
            currentSlide={currentSlide}
            setOpenPanel={setOpenPanel}
            setCurrentFrame={setCurrentFrame}
            currentFrame={currentFrame}
          />

          <BottomNavbar
            setOpenPanel={setOpenPanel}
            currentSlide={currentSlide}
            videoOrderData={videoOrderData}
            videoState={videoState}
          />

          <ModalPanel
            currentSlide={currentSlide}
            openPanel={openPanel}
            setCurrentFrame={setCurrentFrame}
            setCurrentSlide={setCurrentSlide}
            setOpenPanel={setOpenPanel}
            setVideoState={setVideoState}
            videoOrderData={videoOrderData}
            videoState={videoState}
            videoTemplate={videoTemplate}
          />
        </div>
      )}
    </>
  );
}

export default VideoEditor;
