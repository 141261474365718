import React, { useEffect, useState } from 'react';
import './ConfirmDialog.css';

function ConfirmDialog(props:any) {
  const [disableConfirm, setDisableConfirm] = useState(false)
  useEffect(()=>{
    document.addEventListener('keydown', function(e) {
      if (e.key == "Escape") {
        props.onCancel();
      } else if (e.key == "Enter") {
        props.onCancel();
      }
    });
  }, [])

  let confirmButtonText = "Delete"
  if (props.type === "save") confirmButtonText = "Leave";
  if (props.type === "publish") confirmButtonText = "Publish";
  if (props.type === "update") confirmButtonText = "Update";
  if (props.type === "unpublish") confirmButtonText = "Unpublish";
  if (props.type === "create_ad") confirmButtonText = "Create";
  if (props.type.includes("duplicate")) confirmButtonText = "Duplicate";
  
  return (
    <>
      <div className="confirm-bg" onClick={props.onCancel}></div>
      <div className="confirm-container">
        <div className="confirm-title">
          {props.type !== "locked" ? "Confirm "+ confirmButtonText : "Locked"}
        </div>
        {props.type === "file" && <div className="confirm-message">
          <div>Are you sure you want to remove this file? </div>
          <div>If the file is present on the published site it will be affected as well.</div>
        </div> }
        {props.type === "project" && <div className="confirm-message">
          <div>Are you sure you want to remove this ad? </div>
          <div>This will completely remove the ad including the published version.</div>
        </div> }
        {props.type === "template" && <div className="confirm-message">
          <div>Are you sure you want to remove this template? </div>
        </div> }
        {props.type === "duplicate" && <div className="confirm-message">
          <div>Are you sure you want to duplicate this ad? </div>
        </div> }
        {props.type === "duplicateTemplate" && <div className="confirm-message">
          <div>Are you sure you want to duplicate this template? </div>
        </div> }
        {props.type === "duplicateAsTemplate" && <div className="confirm-message">
          <div>Are you sure you want to duplicate this ad as a template? </div>
        </div> }
        {props.type === "save" && <div className="confirm-message">
          <div>Are you sure you want to leave this page? </div>
          <div>There are unsaved changes.</div>
        </div> }
        {props.type === "publish" && <div className="confirm-message">
          <div>Are you sure you want to publish this ad?</div>
        </div> }
        {props.type === "update" && <div className="confirm-message">
          <div>Are you sure you want to update this ad?</div>
        </div> }
        {props.type === "unpublish" && <div className="confirm-message">
          <div>Are you sure you want to unpublish this ad?</div>
        </div> }
        {props.type === "locked" && <div className="confirm-message">
          <div><span style={{fontWeight: 400}}>{props.name}</span> is currently editing this ad.</div>
        </div> }
        {props.type === "create_ad" && <div className="confirm-message">
          <div>Are you sure you want to create a new ad for <span style={{fontWeight: 400}}>{props.name}</span>?</div>
        </div> }
        <div className="confirm-button-container">
          <div className="border-button" style={{margin: 10, height: 34}} onClick={props.onCancel}>
            <span style={{marginBottom: 2}}>{props.type !== "locked" ? "Cancel" : "Ok" }</span>
          </div>
          {props.type !== "locked" &&  <div className="border-button" style={{margin: 10, height: 34, backgroundColor: '#eeeeee', color: '#232323', opacity: disableConfirm ? 0.5 : 1}} 
          onClick={()=>{
            if (!disableConfirm) {
              setDisableConfirm(true);
              props.onConfirm();
            }
          }}>
            <span style={{marginBottom: 2}}>{confirmButtonText}</span>
          </div>}
        </div>
      </div>
    </>
  );
}

export default ConfirmDialog;