import { GET_SECTIONS, GET_SAVED_STATE } from './queries';
import { gql } from 'apollo-boost';
import { FRAGMENT_SECTION_GRIDS, FRAGMENT_SECTION, FRAGMENT_SECTION_TAG, FRAGMENT_SECTION_TITLE, FRAGMENT_GRID_TEXTS, FRAGMENT_TEXT, FRAGMENT_GRID_ITEMS, FRAGMENT_ITEM, FRAGMENT_MAP, FRAGMENT_SLIDER, FRAGMENT_IMAGE, FRAGMENT_MAP_ZOOM, FRAGMENT_MAP_FULL, FRAGMENT_TEXTAREA, FRAGMENT_TIMES_USED } from './fragments';
const resolvers = {
  Mutation: {
    saved: (_:any, { saved }: any, {cache, client}: any) => {
      client.writeQuery({
        query: GET_SAVED_STATE,
        data: {
          saved: saved,
        },
      });
    },
    addSection: (_:any, a: any, {cache, client}: any) => {
      const data = client.readQuery({ query: GET_SECTIONS });
      let sections = data.sections.slice(); // Clone array (important)

      let nextFreeSectionIndex = 1;
      sections.forEach((section:any)=>{
        let index = parseInt(section.id);
        if (index >= nextFreeSectionIndex) {
          nextFreeSectionIndex = index+1;
        }
      })
      let newSection = {id:nextFreeSectionIndex+"", tag:"- TEXT -", title:"- TEXT -", grids: [], __typename: "Section"}
      
      if (sections[sections.length-1] && sections[sections.length-1].id === "99") {
        sections.splice(sections.length-1,0,newSection);
        client.writeQuery({
          query: GET_SECTIONS,
          data: {
            sections: sections,
          },
        });
      } else {
        client.writeQuery({
          query: GET_SECTIONS,
          data: {
            sections: [...sections, newSection],
          },
        });
      }

      return true;
    },
    addHeader: (_:any, a: any, {cache, client}: any) => {
      const data = client.readQuery({ query: GET_SECTIONS });
      let grid = {id:"0_1", type: "header", items: [], texts:[], __typename: "Grid"}
      let newSection = {id:"0", tag:"", title:"", grids:[grid], __typename: "Section"}
      client.writeQuery({
        query: GET_SECTIONS,
        data: {
          sections: [newSection, ...data.sections],
        },
      });
      return true;
    },
    addFooter: (_:any, a: any, {cache, client}: any) => {
      const data = client.readQuery({ query: GET_SECTIONS });
      let grid = {id:"99_1", type: "footer", items: [], texts:[], __typename: "Grid"}
      let newSection = {id:"99", tag:"", title:"", grids:[grid], __typename: "Section"}

      client.writeQuery({
        query: GET_SECTIONS,
        data: {
          sections: [...data.sections, newSection],
        },
      });
      return true;
    },
    addGrid: async (_:any, {sectionId, type}: any, {cache, client}: any) => {

      const section = client.readFragment({
        id: sectionId,
        fragment: FRAGMENT_SECTION_GRIDS,
      });

      let nextFreeGridIndex = 1;
      section.grids.forEach((grid:any)=>{
        let index = parseInt(grid.id.split("_")[1]);
        if (index >= nextFreeGridIndex) {
          nextFreeGridIndex = index+1;
        }
      })

      client.writeFragment({
        id: sectionId,
        fragment: FRAGMENT_SECTION,
        data: {
          grids:[...section.grids, {id:sectionId+"_"+nextFreeGridIndex, type: type, items: [], texts: [], __typename: "Grid"}],
          __typename: 'Section'
        },
      });

      return true;
    },
    updateSectionTag: (_:any, { sectionId, tag }: any, {client}: any) => {
      // if (tag) {
        client.writeFragment({
          id: sectionId,
          fragment: FRAGMENT_SECTION_TAG,
          data: {
            tag: tag,
            __typename: 'Section'
          },
        });
      // }
    },
    updateSectionTitle: (_:any, { sectionId, title }: any, {client}: any) => {
      // if (title) {
        client.writeFragment({
          id: sectionId,
          fragment: FRAGMENT_SECTION_TITLE,
          data: {
            title: title,
            __typename: 'Section'
          },
        });
      // }
    },
    updateGridTexts: (_:any, { gridId, text, position }: any, {client}: any) => {
      const grid = client.readFragment({
        id: gridId,
        fragment: FRAGMENT_GRID_TEXTS,
      });
      let filteredTexts = grid.texts.filter((gridText:any)=>{
        if (gridText.id === gridId+"_text_"+position) {
          return false
        }
        return true;
      })
      client.writeFragment({
        id: gridId,
        fragment: FRAGMENT_GRID_TEXTS,
        data: {
          texts:[...filteredTexts, {text: text, id: gridId+"_text_"+position, __typename: "Text"}],
          __typename: 'Grid'
        },
      });
    },
    updateGridTextArea: (_:any, { gridId, text, position }: any, {client}: any) => {
      client.writeFragment({
        id: gridId+"_item_"+position,
        fragment: FRAGMENT_TEXTAREA,
        data: {
          text: text,
          id: gridId+"_item_"+position,
          __typename: 'TextArea'
        },
      });
    },
    updateGridIframe: (_:any, { gridId, url, position }: any, {client}: any) => {
      client.writeFragment({
        id: gridId+"_item_"+position,
        fragment: FRAGMENT_ITEM,
        data: {
          url: url,
          id: gridId+"_item_"+position,
          __typename: 'Item'
        },
      });
    },
    updateGridMap: (_:any, { gridId, lat, lng, position, zoom }: any, {client}: any) => {

      if (zoom) {
        client.writeFragment({
          id: gridId+"_item_"+position,
          fragment: FRAGMENT_MAP_ZOOM,
          data: {
            zoom: zoom,
            id: gridId+"_item_"+position,
            __typename: 'Map'
          },
        });
      } else {
        client.writeFragment({
          id: gridId+"_item_"+position,
          fragment: FRAGMENT_MAP,
          data: {
            lat: lat,
            lng: lng,
            id: gridId+"_item_"+position,
            __typename: 'Map'
          },
        });
      }
    },
    updateGridSlider: (_:any, { gridId, position, index, url }: any, {client}: any) => {
      const slider = client.readFragment({
        id: gridId+"_item_"+position,
        fragment: FRAGMENT_SLIDER,
      });
      const images = Array.from(slider.images);

      let initialStartFrame =  {
        scale: 1,
        translateX: 0,
        translateY: 0,
        __typename: "Position"
      }

      if (images[index]) {
        images.splice(index, 1, {url: url, startFrame: initialStartFrame, __typename: "SliderImage"})
      } else {
        images.push({url: url, startFrame: initialStartFrame, __typename: "SliderImage"})
      }

      client.writeFragment({
        id: gridId+"_item_"+position,
        fragment: FRAGMENT_SLIDER,
        data: {
          images: images,
          id: gridId+"_item_"+position,
          __typename: 'Slider'
        },
      });
    },
    updateGridSliderImage: (_:any, { itemId, index, scale, translateX, translateY }:any, {client}: any) => {
      const slider = client.readFragment({
        id: itemId,
        fragment: FRAGMENT_SLIDER,
      });
      const images = Array.from(slider.images);
      let image:any = images[index];
      let startFrame =  {
        scale: parseFloat(scale.toFixed(2)),
        translateX: parseFloat(translateX.toFixed(2)),
        translateY: parseFloat(translateY.toFixed(2)),
        __typename: "Position"
      }
      images.splice(index, 1, {...image, startFrame: startFrame})

      client.writeFragment({
        id: itemId,
        fragment: FRAGMENT_SLIDER,
        data: {
          images: images,
          id: itemId,
          __typename: 'Slider'
        },
      });
      return true;
    },
    deleteGridSliderImage: (_:any, { itemId, index }:any, {client}: any) => {
      const slider = client.readFragment({
        id: itemId,
        fragment: FRAGMENT_SLIDER,
      });
      const images = Array.from(slider.images);

      images.splice(index, 1)

      client.writeFragment({
        id: itemId,
        fragment: FRAGMENT_SLIDER,
        data: {
          images: images,
          id: itemId,
          __typename: 'Slider'
        },
      });
    },
    deleteGridItem: (_:any, { gridId, position }: any, {client}: any) => {
      const grid = client.readFragment({
        id: gridId,
        fragment: FRAGMENT_GRID_ITEMS,
      });
      let foundIndex:number = -1;
      grid.items.find((item:any, index:number)=>{
        if(item.id === gridId+"_item_"+position) {
          foundIndex = index;
          return true
        };
        return false;
      })
      if (foundIndex !== -1) {
        grid.items.splice(foundIndex, 1)
        client.writeFragment({
          id: gridId,
          fragment: FRAGMENT_GRID_ITEMS,
          data: {
            items:[...grid.items],
            __typename: 'Grid'
          },
        });
      }
    },
    createGridItem: (_:any, { gridId, position, type, url, lat, lng }: any, {client}: any) => {
      const grid = client.readFragment({
        id: gridId,
        fragment: FRAGMENT_GRID_ITEMS,
      });
      if (type === "image") {
        client.writeFragment({
          id: gridId,
          fragment: FRAGMENT_GRID_ITEMS,
          data: {
            items:[...grid.items, {
              url: url, 
              id: gridId+"_item_"+position, 
              startFrame: {scale: 1, translateX: 0, translateY: 0, __typename: 'Position'},
              endFrame: {scale: 1, translateX: 0, translateY: 0, __typename: 'Position'},
              animationSpeed: 1,
              showInSlideshow: true,
              __typename: 'Image'
            }],
            __typename: 'Grid'
          },
        });
      } else if (type === "video") {
        client.writeFragment({
          id: gridId,
          fragment: FRAGMENT_GRID_ITEMS,
          data: {
            items:[...grid.items, {url: url, id: gridId+"_item_"+position, __typename: "Item"}],
            __typename: 'Grid'
          },
        });
      } else if (type === "iframe") {
        client.writeFragment({
          id: gridId,
          fragment: FRAGMENT_GRID_ITEMS,
          data: {
            items:[...grid.items, {url: "iframe_url", id: gridId+"_item_"+position, __typename: "Item"}],
            __typename: 'Grid'
          },
        });
      } else if (type === "slider") {
        client.writeFragment({
          id: gridId,
          fragment: FRAGMENT_GRID_ITEMS,
          data: {
            items:[...grid.items, {images:[], id: gridId+"_item_"+position, __typename: "Slider"}],
            __typename: 'Grid'
          },
        });
      } else if (type === "map") {
        client.writeFragment({
          id: gridId,
          fragment: FRAGMENT_GRID_ITEMS,
          data: {
            items:[...grid.items, {lat: 59.3251172, lng: 18.0710935, zoom: 15, id: gridId+"_item_"+position, __typename: "Map"}],
            __typename: 'Grid'
          },
        });
      } else if (type === "text") {
        client.writeFragment({
          id: gridId,
          fragment: FRAGMENT_GRID_ITEMS,
          data: {
            items:[...grid.items, {id: gridId+"_item_"+position, text:"", __typename: "TextArea"}],
            __typename: 'Grid'
          },
        });
      }
      
    },
    updateTimesUsed: (_:any, { url, gridId, position, index }: any, {client}: any) => {

      // 1. Decrement "times used" counter for replaced image/video
      const grid = client.readFragment({
        id: gridId,
        fragment: FRAGMENT_GRID_ITEMS,
      });
      const prevItem = grid.items.find((item:any, index:number)=>{
        if(item.id === gridId+"_item_"+position) {
          return true
        };
        return false;
      })
      if (prevItem) {
        let prevUrl = prevItem.url;
        if (!prevUrl && Number.isInteger(index) && prevItem.images && prevItem.images[index]) {
          // Get previous Slider image url
          prevUrl = prevItem.images[index].url;
        }
        const file = client.readFragment({
          id: prevUrl,
          fragment: FRAGMENT_TIMES_USED,
        });
        if (file) {
          client.writeFragment({
            id: prevUrl,
            fragment: FRAGMENT_TIMES_USED,
            data: {
              timesUsed: file.timesUsed-1,
              __typename: 'TimesUsed'
            },
          });
        }
      }
      
      // 2. Increment/create "times used" counter for added image/video
      const file = client.readFragment({
        id: url,
        fragment: FRAGMENT_TIMES_USED,
      });
      if (!file) {
        client.writeFragment({
          id: url,
          fragment: FRAGMENT_TIMES_USED,
          data: {
            timesUsed: 1,
            __typename: 'TimesUsed'
          },
        });
      } else {
        client.writeFragment({
          id: url,
          fragment: FRAGMENT_TIMES_USED,
          data: {
            timesUsed: file.timesUsed+1,
            __typename: 'TimesUsed'
          },
        });
      }
    },
    updateGridImage: (_:any, { imageId, frame, scale, translateX, translateY, speed, showInSlideshow }: any, {client}: any) => {

      const image = client.readFragment({
        id: imageId,
        fragment: FRAGMENT_IMAGE,
      });

      if (frame === 1) {
        client.writeFragment({
          id: imageId,
          fragment: FRAGMENT_IMAGE,
          data: {
            startFrame: {scale: scale, translateX: translateX, translateY: translateY, __typename: 'Position'},
            endFrame: {scale: image.endFrame.scale, translateX: image.endFrame.translateX, translateY: image.endFrame.translateY, __typename: 'Position'},
            animationSpeed: image.animationSpeed,
            showInSlideshow: image.showInSlideshow,
            __typename: 'Image'
          },
        });
      } else if (frame === 2) {
        client.writeFragment({
          id: imageId,
          fragment: FRAGMENT_IMAGE,
          data: {
            startFrame: {scale: image.startFrame.scale, translateX: image.startFrame.translateX, translateY: image.startFrame.translateY, __typename: 'Position'},
            endFrame: {scale: scale, translateX: translateX, translateY: translateY, __typename: 'Position'},
            animationSpeed: image.animationSpeed,
            showInSlideshow: image.showInSlideshow,
            __typename: 'Image'
          },
        }); 
      } else if (speed) {
        // Update speed
        client.writeFragment({
          id: imageId,
          fragment: FRAGMENT_IMAGE,
          data: {
            startFrame: {scale: image.startFrame.scale, translateX: image.startFrame.translateX, translateY: image.startFrame.translateY, __typename: 'Position'},
            endFrame: {scale: image.endFrame.scale, translateX: image.endFrame.translateX, translateY: image.endFrame.translateY, __typename: 'Position'},
            animationSpeed: speed,
            showInSlideshow: image.showInSlideshow,
            __typename: 'Image'
          },
        }); 
      } else {
        client.writeFragment({
          id: imageId,
          fragment: FRAGMENT_IMAGE,
          data: {
            startFrame: {scale: image.startFrame.scale, translateX: image.startFrame.translateX, translateY: image.startFrame.translateY, __typename: 'Position'},
            endFrame: {scale: image.endFrame.scale, translateX: image.endFrame.translateX, translateY: image.endFrame.translateY, __typename: 'Position'},
            animationSpeed: image.animationSpeed,
            showInSlideshow: showInSlideshow,
            __typename: 'Image'
          },
        });
      }
    },
    reorderGrid: (_:any, { gridId, sectionSourceId, sectionDestinationId, gridSourceIndex, gridDestinationIndex }: any, {client}: any) => {
      
      if (sectionSourceId === sectionDestinationId) {
        // Grid moved inside section
        let grids = client.readFragment({
          id: sectionDestinationId,
          fragment: FRAGMENT_SECTION_GRIDS,
        }).grids;
        let newGrids = Array.from(grids);
        let gridItem = {...grids[gridSourceIndex]};
        newGrids.splice(gridSourceIndex, 1);
        newGrids.splice(gridDestinationIndex, 0, gridItem)
        client.writeFragment({
          id: sectionDestinationId,
          fragment: FRAGMENT_SECTION,
          data: {
            grids: newGrids,
            __typename: 'Section'
          },
        });
      } else {
        // Grid moved to new section
        let sourceGrids = client.readFragment({
          id: sectionSourceId,
          fragment: FRAGMENT_SECTION_GRIDS,
        }).grids;
        let newSourceGrids = Array.from(sourceGrids);

        let destinationGrids = client.readFragment({
          id: sectionDestinationId,
          fragment: FRAGMENT_SECTION_GRIDS,
        }).grids;
        let newDestinationGrids = Array.from(destinationGrids);


        let nextFreeGridIndex = 1;
        newDestinationGrids.forEach((grid:any)=>{
          let index = parseInt(grid.id.split("_")[1]);
          if (index >= nextFreeGridIndex) {
            nextFreeGridIndex = index+1;
          }
        })

        // Change grid item ids to correct section and grid
        let gridItems = sourceGrids[gridSourceIndex].items.map((item:any)=>{
          return {...item, id: sectionDestinationId+"_"+nextFreeGridIndex+"_item_"+item.id.split("_item_")[1]}
        })
        // Crete the new grid
        let grid = {
          ...sourceGrids[gridSourceIndex], 
          id: sectionDestinationId+"_"+nextFreeGridIndex,
          items: gridItems
        }
        // Write the new grid to FRAGMENT_GRID_ITEMS cache
        client.writeFragment({
          id: grid.id,
          fragment: FRAGMENT_GRID_ITEMS,
          data: {
            items:[...grid.items],
            __typename: 'Grid'
          },
        });
        newSourceGrids.splice(gridSourceIndex, 1);
        newDestinationGrids.splice(gridDestinationIndex, 0, grid)
        
        // Write new sections to FRAGMENT_SECTION cache
        client.writeFragment({
          id: sectionSourceId,
          fragment: FRAGMENT_SECTION,
          data: {
            grids: newSourceGrids,
            __typename: 'Section'
          },
        });
        client.writeFragment({
          id: sectionDestinationId,
          fragment: FRAGMENT_SECTION,
          data: {
            grids: newDestinationGrids,
            __typename: 'Section'
          },
        });
      }
      return true;
    },
    reorderSection: (_:any, { sectionId, sectionSourceIndex, sectionDestinationIndex }: any, {client}: any) => {
      const data = client.readQuery({ query: GET_SECTIONS });
      let newSections = data.sections.slice(); // Clone array (important)
      let section = {...newSections[sectionSourceIndex]}
      newSections.splice(sectionSourceIndex, 1);
      newSections.splice(sectionDestinationIndex, 0, section)
      client.writeQuery({
        query: GET_SECTIONS,
        data: {
          sections: newSections,
        },
      });
      return true;
    }, 
    deleteSection: (_:any, { sectionIndex }: any, {client}: any) => {
      const data = client.readQuery({ query: GET_SECTIONS });
      let newSections = data.sections.slice(); // Clone array (important)
      newSections.splice(sectionIndex, 1);
      client.writeQuery({
        query: GET_SECTIONS,
        data: {
          sections: newSections,
        },
      });
      return true;
    },
    deleteGrid: (_:any, { sectionId,  gridIndex}: any, {client}: any) => {
      let grids = client.readFragment({
        id: sectionId,
        fragment: FRAGMENT_SECTION_GRIDS,
      }).grids;
      let newGrids = Array.from(grids);
      newGrids.splice(gridIndex, 1);
      client.writeFragment({
        id: sectionId,
        fragment: FRAGMENT_SECTION,
        data: {
          grids: newGrids,
          __typename: 'Section'
        },
      });
    }
  },
  Query: {
    sectionGrids: (_:any, { sectionId }: any, {client}: any) => {
      let grids = client.readFragment({
        id: sectionId,
        fragment: FRAGMENT_SECTION_GRIDS,
      }).grids;
      return grids;
    },
    item: (_:any, { gridId, position }: any, {client}: any) => {
      const grid = client.readFragment({
        id: gridId,
        fragment: FRAGMENT_GRID_ITEMS,
      });
      return grid.items.find((item:any)=>{
        if(item.id === gridId+"_item_"+position) return true;
        return false;
      })
    },
    map: (_:any, { itemId }: any, {client}: any) => {
      const map = client.readFragment({
        id: itemId,
        fragment: FRAGMENT_MAP_FULL,
      });
      return map;
    },
    slider: (_:any, { itemId }: any, {client}: any) => {
      const slider = client.readFragment({
        id: itemId,
        fragment: FRAGMENT_SLIDER,
      });
      return slider;
    },
    text: (_:any, { textId }: any, {client}: any) => {
      const text = client.readFragment({
        id: textId,
        fragment: FRAGMENT_TEXT,
      });
      return text;
    },
    sectionTitle: (_:any, { sectionId }: any, {client}: any) => {
      const title = client.readFragment({
        id: sectionId,
        fragment: FRAGMENT_SECTION_TITLE,
      });
      return title;
    },
    sectionTag: (_:any, { sectionId }: any, {client}: any) => {
      const tag = client.readFragment({
        id: sectionId,
        fragment: FRAGMENT_SECTION_TAG,
      });
      return tag;
    },
    timesUsed: (_:any, { url }: any, {client}: any) => {
      const file = client.readFragment({
        id: url,
        fragment: FRAGMENT_TIMES_USED,
      });
      if (file) {
        return file.timesUsed;
      } else {
        return 0;
      }
    }
  }
}

export default resolvers;