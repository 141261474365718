import { useQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { GET_TIMES_USED } from '../graphql/queries';
import './FileUpload.css';
import { useParams } from 'react-router-dom';

function FileUpload(props:any) {
  const { id, url, name, type, setDeleteDialog } = props;
  let { projectId }:any = useParams();
  const [timesUsed, setTimesUsed] = useState(0)
  const [loaded, setloaded] = useState(false);
  const cacheUrl = type === "image" ? projectId+"/img/"+name : "https://husfoto-exposure.s3.eu-north-1.amazonaws.com/"+projectId+"/vid/"+name;
  const { data, loading, refetch } = useQuery(GET_TIMES_USED, {variables:{url: cacheUrl}});
  refetch();

  useEffect(() => {
    if (data && data.timesUsed !== timesUsed) {
      setTimesUsed(data.timesUsed);
    }
  }, [data])

  function dragImg(ev:any) {
    var img = document.createElement("img");
    if (ev.target.getAttribute('src')) {
      img.src = ev.target.getAttribute('src');
      ev.dataTransfer.setDragImage(img, img.width/2, img.height/2);
      ev.dataTransfer.setData("img", ev.target.id);
    }
  }

  function dragVid(ev:any) {
    var img = document.createElement("img");
    if (ev.target.getAttribute('src')) {
      img.src = ev.target.getAttribute('src');
      ev.dataTransfer.setDragImage(img, img.width/2, img.height/2);
      ev.dataTransfer.setData("vid", ev.target.id);
    }
  }


  return (
    <div style={{position:'relative'}} key={name}>
        <img className="img" src={url} id={name} draggable="true" 
        onDragStart={(e)=> {
          if (type === "image") dragImg(e);
          if (type === "video") dragVid(e);
        }}
        onLoad={(e)=>{
          //@ts-ignore
          document.getElementById("delete-"+id).style.left = (99-e.target.width/2)+"px"
          //@ts-ignore
          document.getElementById("delete-"+id).style.display = "block"
          //@ts-ignore
          document.getElementById("times-used-"+id).style.right = (90-e.target.width/2)+"px"
          setloaded(true);

        }}
        ></img>
        <div id={"delete-"+id} className="ion-trash-b delete-upload" title={"Delete file"} onClick={async ()=>{
        setDeleteDialog(id);
        }}></div>
        <div id={"times-used-"+id} className="times-used" title={"Number of times used in the ad"} style={{
          display: loaded && timesUsed ? "flex" : "none",
          fontSize: 10,
          fontWeight: 700
        }}>{timesUsed}</div>
    </div>
  );
}

export default FileUpload;