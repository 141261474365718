import React from "react";
import "./Grid7.css";
import GridItem from "../GridItem/GridItem";
import InputField from "../InputField/InputField";

function Grid7(props: any) {
  const { gridId, preview } = props;
  return (
    <>
    {preview ? 
      <div className="grid grid7 preview">
        <div className="bg-img grid7-container"></div>
        <div id="grid7-text-container">
          <div id="grid7-text-container-inner-1">
            <div style={{paddingLeft: '0.5vw'}}> Text</div>
          </div>
          <div id="grid7-text-container-inner-2">
            <div style={{marginLeft: '-1vw'}}> Text</div>
          </div>
        </div>
      </div>
      : <div className="grid grid7">
      <GridItem className="bg-img grid7-container" gridId={gridId} position={1}/>
      <div id="grid7-text-container">
        <div id="grid7-text-container-inner-1">
          <InputField position={1} type={"text"} gridId={gridId} className="heading-3"/>
          <InputField position={2} type={"text"} gridId={gridId} className="heading-1"/>
        </div>
        <div id="grid7-text-container-inner-2">
          <InputField position={3} type={"text"} gridId={gridId} className="text-block text-1"/>
          <InputField position={4} type={"text"} gridId={gridId} className="text-block text-1"/>
          <InputField position={5} type={"text"} gridId={gridId} className="text-block text-1"/>
        </div>
      </div>
    </div>
    }
    </>
  );
}

export default Grid7;
