import React, { useState, useRef } from 'react';
import './GridItem.css';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CREATE_GRID_ITEM } from '../graphql/mutations';
import { GET_ITEM } from '../graphql/queries';
import Map from '../Map/Map';
import Slider from '../Slider/Slider';
import Image from '../Image/Image';
import { useParams } from 'react-router-dom';
import Iframe from '../Iframe/Iframe';
import TextArea from '../TextArea/TextArea';

function GridItem(props:any) {
  let { projectId } = useParams<any>();
  const [url, setUrl] = useState("");
  const [type, setType] = useState("");
  const {gridId, position} = props;
  const [createGridItem] = useMutation(CREATE_GRID_ITEM);
  const {data, loading, refetch} = useQuery(GET_ITEM, {variables:{gridId: gridId, position:position}, fetchPolicy: 'no-cache'})
  const dropContainerEl = useRef(null);
  if (data && data.item && data.item.url && data.item.url != url) {
    setUrl(data.item.url)
  }
  if (data && data.item && data.item.url && !type) {
    if (data.item.endFrame) {
      setType("image")
    } else {
      setType("video")
    }
  }
  if (data && data.item && data.item.lat && !type) {
    setType("map")
  }
  if (data && data.item && data.item.images && !type) {
    setType("slider")
  }
  if (data && data.item && data.item.url && !data.item.url.includes("img/") && !data.item.url.includes("vid/") && !type) {
    setType("iframe")
  }
  if (data && data.item && data.item.text !== undefined && !type) {
    setType("text")
  }
  if (!loading && !data && (type !== "" || url !== "") ) {
    setType("");
    setUrl("");
  }
  function dragOver(ev:any) {
    if (type === "slider" && ev.dataTransfer.types && ev.dataTransfer.types.includes("img")) {
      ev.preventDefault();
      return;
    }
    let el:any = dropContainerEl.current;
    if (el) {
      el.style.zIndex = 10;
      el.style.boxShadow = "inset 0px 0px 0px 1px #3b3b3b";
    }
    ev.preventDefault();
  }
  function dragLeave(ev:any) {
    let el:any = dropContainerEl.current;
    if (el) {
      el.style.zIndex = -1;
      el.style.boxShadow = 'none';
    }
    ev.preventDefault();
  }
  
  async function drop(ev:any) {
    let el:any = dropContainerEl.current;
    if (el) {
      el.style.zIndex = -1;
      el.style.boxShadow = 'none';
    }
    ev.preventDefault();
    var transferData = ev.dataTransfer.getData("img");
    if (transferData) {
      const imgUrl = projectId+"/img/"+transferData;
      createGridItem({variables:{gridId: gridId, url: imgUrl, position: position, type: "image"}})
      setType("image")
      refetch();
    } else if (ev.dataTransfer.getData("iframe")) {
      createGridItem({variables:{gridId: gridId, position: position, type: "iframe"}})
      setType("iframe")
      refetch();
    } else if (ev.dataTransfer.getData("vid")) {
      transferData = ev.dataTransfer.getData("vid");
      const vidUrl = "https://husfoto-exposure.s3.eu-north-1.amazonaws.com/"+projectId+"/vid/"+transferData;
      createGridItem({variables:{gridId: gridId, url: vidUrl, position: position, type: "video"}})
      setType("video")
      refetch();
    } else if (ev.dataTransfer.getData("slider")) {
      await createGridItem({variables:{gridId: gridId, position: position, type: "slider"}})
      setType("slider");
      refetch();
    } else if (ev.dataTransfer.getData("map")) {
      await createGridItem({variables:{gridId: gridId, position: position, type: "map"}})
      setType("map");
      refetch();
    } else if (ev.dataTransfer.getData("text")) {
      await createGridItem({variables:{gridId: gridId, position: position, type: "text"}})
      setType("text");
      refetch();
    }
  }

  return (
    <div className={props.className} style={type || url ? {backgroundColor: 'transparent'} : {}} onDragOver={(e)=>{dragOver(e)}}>
      <div ref={dropContainerEl} onDrop={async (e)=>{await drop(e)}} onDragLeave={(e)=>{dragLeave(e)}} className="drop-container"></div>
      {url && type === "image" && <Image url={url} gridId={gridId} position={position} />}
      {type === "iframe" && <Iframe url={url} gridId={gridId} position={position} refetchGridItem={()=>{refetch()}} />}
      {url && type === "video" && 
        <div key={"vid_"+url} className="background-video">
          <video poster={url} autoPlay={true} loop={true} muted={true} playsInline={true} data-object-fit="cover">
              <source src={url.replace(".0000000.jpg", "").replace(".0000001.jpg", "")+"-processed.webm"} type='video/webm'/>
              <source src={url.replace(".0000000.jpg", "").replace(".0000001.jpg", "")+"-processed.mp4"} type='video/mp4'/>
          </video>
        </div>
      }
      {type === "map" && <Map key={gridId+"_"+position+"-map"} gridId={gridId} position={position} />}
      {type === "slider" && <Slider key={gridId+"_"+position+"-slider"} gridId={gridId} position={position} />}
      {type === "text" && <TextArea gridId={gridId} position={position} className="text-block text-1" />}
    </div>
  );
}

export default GridItem;