import React, { useState, useEffect } from 'react';
import './App.css';
import Section from './Section/Section';
import { ModalContext } from './context';
import Modal from './Modal/Modal';
import { useQuery, useApolloClient, useMutation } from '@apollo/react-hooks';
import { GET_SECTIONS, GET_PROJECT } from './graphql/queries'
import AddButton, { AddButtonType } from './AddButton/AddButton';
import Drawer from './Drawer/Drawer';
import Navbar from './Navbar/Navbar';
import { useParams, useHistory } from 'react-router-dom';
import ProjectModal from './ProjectModal/ProjectModal';
import {ReactComponent as Icon}  from './icon.svg';
import { deflateData, inflateData } from './compression-utils';
import Expandable from './Expandable/Expandable';
import Lottie from 'react-lottie';
import animationData from './animationVF.json';
import useInterval from 'react-useinterval';
import { LAST_ACCESSED, SAVE_CACHE } from './graphql/mutations';

function App() {
  let client = useApolloClient();
  let history = useHistory();
  let { projectId } = useParams<any>();
  // console.log(projectId);

  const [project, setProject] = useState(null);
  
  const [showModal, setShowModal] = useState(false);
  const [modalSectionId, setModalSectionId] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [showProjectModal, setShowProjectModal] = useState(!projectId);
  const { data:projectData, loading: projectLoading } = useQuery(GET_PROJECT, {skip: !projectId || showProjectModal, variables:{projectId: projectId}, fetchPolicy:'network-only'});
  const { data, refetch } = useQuery(GET_SECTIONS, {skip: !projectData});
  const [ lastAccessed ] = useMutation(LAST_ACCESSED)
  const [ saveCache ] = useMutation(SAVE_CACHE);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


  // Update lastAccess every 60 seconds
  const updateLastAccessed = async (projectId:String) => {
    if (projectId && project && !showProjectModal) {
      await lastAccessed({variables:{projectId: projectId}})
    } 
  };
  useInterval(updateLastAccessed, 60000, projectId);
  useEffect(() => {
    updateLastAccessed(projectId)
  }, [project])

  useEffect(()=>{
    if (projectData && !project) {
      let decompressedCache = inflateData(projectData.project.cache);
      if (projectData.project.duplicateOf) {
        // Change to new projectId for item url:s
        let newCache = JSON.stringify(decompressedCache);
        newCache = newCache.split(projectData.project.duplicateOf).join(projectData.project.id);
        decompressedCache = JSON.parse(newCache);
        // Save new cache
        saveCache({variables:{projectId: projectData.project.id, cache: deflateData(decompressedCache)}});
      }
      client.cache.restore(decompressedCache)
      setProject(projectData.project)
    }
  }, [projectData])

  useEffect(()=>{
    if (!projectLoading && projectData) {
      setShowProjectModal(false);
    }
  }, [projectLoading])

  if (!projectLoading && !projectData && !showProjectModal) {
    setShowProjectModal(true);
    history.push({
      pathname: '/builder'
    })
  }

  let showAddHeader = true;
  let showAddFooter = true;
  if (data && data.sections && data.sections[0] && data.sections[0].grids && data.sections[0].grids[0] && data.sections[0].grids[0].type === "header") {
    showAddHeader = false;
  }

  if (data && data.sections && data.sections[data.sections.length-1] && data.sections[data.sections.length-1].grids && data.sections[data.sections.length-1].grids[0] && data.sections[data.sections.length-1].grids[0].type === "footer") {
    showAddFooter = false;
  }

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer)
  }

  return (
    <>
    

    {!data ? <div className="App">
      <Navbar />
      {showProjectModal && <ProjectModal closeProjectModal={()=>{setShowProjectModal(false)}} />}
      <div style={{width: '100%', height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: '10vh', zIndex:-1 }}>
        <div className="loading">
          <Icon className="icon" style={{width: 250, fill:'#232323'}}/>
          {/* <div>
            <Lottie 
            options={defaultOptions}
              height={400}
              width={400}
            />
          </div> */}
        </div>
      </div>
      {/* <object className="loading-svg" type="image/svg+xml" data={Animated}>svg-animation</object> */}
    </div> : 
    <ModalContext.Provider value={{toggle: (sectionId)=>{if (sectionId){setModalSectionId(sectionId)}; setShowModal(!showModal);}}}>
      <div className="App">
        <Navbar toggleDrawer={toggleDrawer} />
        <div style={{width: '100%', height:90}}></div>
        {showModal && <Modal sectionId={modalSectionId} />}
        {showDrawer && <Drawer />}
        {showAddHeader && <AddButton type={AddButtonType.header} />}
        {data && data.sections && data.sections.map((section:any, index:number)=>{
          if (!showAddFooter && index === data.sections.length-1) {
            return null;
          }
          return <Section key={section.id} section={section} />
        })}
        <AddButton type={AddButtonType.section} />
        {!showAddFooter && <Section key={data.sections.length-1} section={data.sections[data.sections.length-1]} />}
        {showAddFooter && <AddButton type={AddButtonType.footer} />}
      </div>
    </ModalContext.Provider>
    }
    </>
  );
}

export default App;
