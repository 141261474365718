import { useMutation } from "@apollo/react-hooks";
import React, { useEffect, useRef, useState } from "react";
import { BiRedo, BiUndo } from "react-icons/bi";
import { VideoExport } from "../graphql/graphqlTypes";
import {
  ExportVideoMutation,
  ExportVideoMutationVariables,
} from "../graphql/graphqlTypes-video-mutations";
import { EXPORT_VIDEO } from "../graphql/video-mutations";
import {
  GetVideoOrderQuery,
  RenderingStatus,
} from "../graphql/graphqlTypes-video-queries";
import { ReactComponent as Logo } from "../icon.svg";
import { debounce } from "../utils";
import { GET_VIDEO_ORDER } from "../graphql/video-queries";
import { VideoState } from "./Video";
import { motion } from "framer-motion";
import { FiShare } from "react-icons/fi";
import { RiCoinFill } from "react-icons/ri";
import { isMobile, isAndroid } from "react-device-detect";
import { MdDownload, MdIosShare, MdShare } from "react-icons/md";

type Props = {
  videoExport?: VideoExport;
  exportLimitReached: boolean;
  videoState: VideoState;
  setVideoState: Function;
  setCurrentFrame: Function;
  videoOrderData: GetVideoOrderQuery;
  setCurrentSlide: Function;
};

// isUndoing and isRedoing is used for differntiating between a videoState update do to an undo/redo
// and a videoState update (adding a slide, changing resoluton, etc.)
// Used in videoStateHistory for keeping track of the change history
let isUndoing = false;
let isRedoing = false;

function TopNavbar(props: Props) {
  const {
    videoExport,
    exportLimitReached,
    videoState,
    setVideoState,
    setCurrentFrame,
    videoOrderData,
    setCurrentSlide,
  } = props;

  const [exportVideo] = useMutation<
    ExportVideoMutation,
    ExportVideoMutationVariables
  >(EXPORT_VIDEO);

  const [videoStateHistory, setVideoStateHistory] = useState<string[]>(null);
  const [videoStateHistoryIndex, setVideoStateHistoryIndex] =
    useState<number>(0);

  // When clicking export button - temporary disable the button until the videoOrder is refetched
  const [temporaryDisableExportButton, setTemporaryDisableExportButton] =
    useState(false);

  const [shareLoading, setShareLoading] = useState(false);

  const undoRef = useRef(null);
  const redoRef = useRef(null);

  const canUndo =
    videoStateHistory && !!videoStateHistory[videoStateHistoryIndex - 1];
  const canRedo =
    videoStateHistory && !!videoStateHistory[videoStateHistoryIndex + 1];
  const redo = () => {
    if (canRedo && !isRedoing && !isUndoing) {
      setTimeout(() => {
        isRedoing = true;
        // "Force" currentSlide change to update text state in ModalPanel
        setCurrentSlide(0);
        if (
          JSON.parse(videoStateHistory[videoStateHistoryIndex + 1]).slides
            .length < videoState.slides.length
        ) {
          setCurrentFrame(0);
        }

        setVideoState(
          JSON.parse(videoStateHistory[videoStateHistoryIndex + 1])
        );
        setVideoStateHistoryIndex(videoStateHistoryIndex + 1);
      }, 0);
    }
  };
  const undo = () => {
    if (canUndo && !isUndoing && !isRedoing) {
      setTimeout(() => {
        isUndoing = true;
        // "Force" currentSlide change to update text state in ModalPanel
        setCurrentSlide(0);
        if (
          JSON.parse(videoStateHistory[videoStateHistoryIndex - 1]).slides
            .length < videoState.slides.length
        ) {
          setCurrentFrame(0);
        }

        setVideoState(
          JSON.parse(videoStateHistory[videoStateHistoryIndex - 1])
        );
        setVideoStateHistoryIndex(videoStateHistoryIndex - 1);
      }, 0);
    }
  };

  useEffect(() => {
    document.onkeyup = (e) => {
      if (e.keyCode === 90 && e.ctrlKey) {
        undoRef.current.click();
      } else if (e.keyCode === 89 && e.ctrlKey) {
        redoRef.current.click();
      }
    };
  }, []);

  useEffect(() => {
    if (videoOrderData) {
      if (videoStateHistory) {
        if (!isUndoing && !isRedoing) {
          // New video state saved (videoOrderData updates after save due to refetch query)
          // => remove history that is after history index (if user has clicked undo and then add new slide for example)
          let newHistory = videoStateHistory;
          newHistory.splice(videoStateHistoryIndex + 1);
          newHistory = [
            ...newHistory,
            videoOrderData.getVideoOrder.video.state,
          ];
          setVideoStateHistory(newHistory);
          setVideoStateHistoryIndex(videoStateHistoryIndex + 1);
        }
      } else {
        // Initilize video state history
        setVideoStateHistory([videoOrderData.getVideoOrder.video.state]);
      }
      isUndoing = false;
      isRedoing = false;
      setTemporaryDisableExportButton(false);
    }
  }, [videoOrderData]);

  return (
    <div
      style={{
        padding: 20,
        width: "calc(100vw - 40px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#2F2F2F",
      }}
    >
      <Logo style={{ fill: "white", height: 25 }} />
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          ref={undoRef}
          onClick={() => {
            undo();
          }}
        >
          <BiUndo
            style={{
              fontSize: 26,
              opacity: canUndo ? 1 : 0.5,
              cursor: canUndo ? "pointer" : "default",
            }}
            color="white"
          />
        </div>
        <div
          onClick={() => {
            redo();
          }}
          ref={redoRef}
        >
          <BiRedo
            style={{
              fontSize: 26,
              marginLeft: 6,
              opacity: canRedo ? 1 : 0.5,
              cursor: canRedo ? "pointer" : "default",
            }}
            color="white"
          />
        </div>
        <div
          style={{
            width: 1,
            backgroundColor: "white",
            margin: "0px 10px",
            height: 24,
          }}
        ></div>

        <motion.div
          key={videoOrderData.getVideoOrder.revisionCount}
          animate={{
            opacity: [0, 1],
            transition: {
              duration: 1,
            },
          }}
          style={{
            position: "absolute",
            right: 82,
            top: 8,
            zIndex: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <RiCoinFill
            style={{
              fontSize: 10,
              lineHeight: "10px",
              color: "white",
              fontWeight: 700,
            }}
          />

          <span
            style={{
              marginLeft: 2,
              color: "white",
              fontSize: 8,
              lineHeight: "8px",
              fontWeight: 700,
            }}
          >{`${
            videoOrderData.getVideoOrder.revisionLimit -
            videoOrderData.getVideoOrder.revisionCount
          }`}</span>
        </motion.div>

        <div
          onClick={() => {
            if (
              !temporaryDisableExportButton &&
              !exportLimitReached &&
              (!videoExport ||
                (videoExport &&
                  videoExport.status !== RenderingStatus.Rendering &&
                  videoExport.exportedVideoState !==
                    JSON.stringify(videoState)))
            ) {
              // Prevent spamming export
              setTemporaryDisableExportButton(true);
              exportVideo({ refetchQueries: [{ query: GET_VIDEO_ORDER }] });
            }
          }}
          style={{
            outline: "none",
            marginLeft: 10,
            height: 26,
            width: 80,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 6,
            position: "relative",
            overflow: "hidden",
            color: "black",
            fontSize: 10,
            fontWeight: 700,
            cursor:
              !temporaryDisableExportButton &&
              !exportLimitReached &&
              (!videoExport ||
                (videoExport &&
                  videoExport.status !== RenderingStatus.Rendering &&
                  videoExport.exportedVideoState !==
                    JSON.stringify(videoState)))
                ? "pointer"
                : "default",
            opacity:
              !temporaryDisableExportButton &&
              !exportLimitReached &&
              (!videoExport ||
                (videoExport &&
                  videoExport.status !== RenderingStatus.Rendering &&
                  videoExport.exportedVideoState !==
                    JSON.stringify(videoState)))
                ? 1
                : 0.5,
          }}
        >
          {videoExport && videoExport.status === RenderingStatus.Rendering && (
            <div
              className="animated-loading-background"
              style={{
                height: 300,
                width: 500,
                position: "absolute",
                zIndex: 0,
              }}
            ></div>
          )}
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              width: "100%",
              height: "100%",
              backgroundColor:
                videoExport && videoExport.status === RenderingStatus.Rendering
                  ? "transparent"
                  : "white",
              borderRadius: 6,
              color:
                videoExport && videoExport.status === RenderingStatus.Rendering
                  ? "white"
                  : "black",
              border: "1px solid white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 10,
              fontWeight: 700,
            }}
          >
            {videoExport && videoExport.status === RenderingStatus.Rendering
              ? "Creating"
              : "Create"}
          </div>
        </div>
        <motion.div
          animate={
            !temporaryDisableExportButton &&
            videoExport &&
            videoExport.url && {
              scale: [1, 1.2, 1],
              transition: {
                duration: 1,
              },
            }
          }
          onClick={async () => {
            if (
              !temporaryDisableExportButton &&
              videoExport &&
              videoExport.url
            ) {
              if (isMobile) {
                // Mobile - share
                let failedToShare = false;
                let winNavigator: any = window.navigator;
                if (winNavigator && winNavigator.share) {
                  try {
                    setShareLoading(true);
                    const response = await fetch(
                      "/download-video?token=" +
                        new URL(window.location.href).searchParams.get("token")
                    );
                    const header = response.headers.get("Content-Disposition");
                    const parts = header.split(";");
                    let filename = parts[1].split("=")[1];
                    const buffer = await response.arrayBuffer();

                    const vid = new File([buffer], filename, {
                      type: "video/mp4",
                    });
                    const files = [vid];
                    setShareLoading(false);

                    if (winNavigator.canShare({ files })) {
                      await winNavigator
                        .share({ files })
                        .then(() => {
                          // Success
                        })
                        .catch((error) => {
                          // If user cancels the save this will also throw an error here
                        });
                    } else {
                      failedToShare = true;
                    }
                  } catch (e) {
                    failedToShare = true;
                    setShareLoading(false);
                  }
                } else {
                  failedToShare = true;
                }

                if (failedToShare) {
                  window.open(
                    "/download-video?token=" +
                      new URL(window.location.href).searchParams.get("token"),
                    "_self"
                  );
                }
              } else {
                // Desktop - download
                window.open(
                  "/download-video?token=" +
                    new URL(window.location.href).searchParams.get("token"),
                  "_self"
                );
              }
            }
          }}
          style={{
            marginLeft: 10,
            padding: "8px 14px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 6,
            background:
              !temporaryDisableExportButton && videoExport && videoExport.url
                ? "linear-gradient(91deg, rgb(0, 148, 255) 0%, rgb(5 109 185) 100%)"
                : "white",
            color:
              !temporaryDisableExportButton && videoExport && videoExport.url
                ? "white"
                : "black",
            fontWeight: 700,
            opacity:
              !temporaryDisableExportButton && videoExport && videoExport.url
                ? 1
                : 0.5,
            cursor:
              !temporaryDisableExportButton && videoExport && videoExport.url
                ? "pointer"
                : "default",
          }}
        >
          {!shareLoading && (
            <>
              {isMobile ? (
                <>
                  {isAndroid ? (
                    <MdShare
                      style={{ fontSize: 12, width: 20, fontWeight: 700 }}
                    />
                  ) : (
                    <MdIosShare
                      style={{ fontSize: 12, width: 20, fontWeight: 700 }}
                    />
                  )}
                </>
              ) : (
                <MdDownload
                  style={{ fontSize: 12, width: 20, fontWeight: 700 }}
                />
              )}
            </>
          )}
          {shareLoading && (
            <div
              style={{
                width: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="loader"></div>
            </div>
          )}
        </motion.div>
      </div>
    </div>
  );
}

export default TopNavbar;
