import { InputLabel, MenuItem, Select } from "@material-ui/core";
import { AnimateSharedLayout, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import Sheet from "react-modal-sheet";
import { GetVideoOrderQuery } from "../graphql/graphqlTypes-video-queries";
import { debounce } from "../utils";
import { VideoState, VideoTemplate } from "./Video";
import { Panel } from "./VideoEditor";

type Props = {
  openPanel: Panel;
  setOpenPanel: Function;
  currentSlide: "new" | number;
  setCurrentFrame: Function;
  videoOrderData: GetVideoOrderQuery;
  videoState: VideoState;
  setVideoState: Function;
  videoTemplate: VideoTemplate;
  setCurrentSlide: Function;
};

function ModalPanel(props: Props) {
  const {
    openPanel,
    setOpenPanel,
    currentSlide,
    setCurrentFrame,
    videoOrderData,
    videoState,
    setVideoState,
    videoTemplate,
    setCurrentSlide,
  } = props;

  const sheetContentRef = useRef(null);
  const [imageCategory, setImageCategory] = useState<"OBJECT" | "OTHER">(
    "OBJECT"
  );
  const [text, setText] = useState("");

  useEffect(() => {
    setTimeout(() => {
      if (sheetContentRef.current) {
        sheetContentRef.current.classList.add("hide-scrollbar");
      }
    }, 0);
  }, [openPanel]);

  useEffect(() => {
    // Set initial text on slide change
    if (
      currentSlide !== null &&
      currentSlide !== "new" &&
      videoState.slides[currentSlide]
    ) {
      setText(videoState.slides[currentSlide].text);
    }
  }, [currentSlide]);

  return (
    <>
      <Sheet
        isOpen={openPanel !== Panel.NONE}
        onClose={() => setOpenPanel(Panel.NONE)}
        onOpenEnd={() => {
          // setFrame to middle of curent frame for the text to be visible
          if (openPanel === Panel.TEXT && typeof currentSlide === "number") {
            const framesPerSlide =
              videoOrderData.getVideoOrder.template.secondsPerSlide *
              videoOrderData.getVideoOrder.template.fps;
            setCurrentFrame(
              (currentSlide + 1) * framesPerSlide - framesPerSlide / 2
            );
          }
        }}
        snapPoints={
          openPanel === Panel.IMAGE
            ? [0.9, 0.5, 0]
            : openPanel === Panel.TEXT
            ? [0.4, 0]
            : [0.3, 0]
        }
      >
        <Sheet.Container
          style={{
            backgroundColor: "rgb(47, 47, 47)",
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
          }}
          onViewportBoxUpdate={() => {}}
        >
          <Sheet.Header onViewportBoxUpdate={() => {}} />
          <Sheet.Content
            style={{ marginTop: 10 }}
            ref={sheetContentRef}
            onViewportBoxUpdate={() => {}}
          >
            {openPanel === Panel.IMAGE && (
              <AnimateSharedLayout>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "50%",
                    margin: "auto",
                  }}
                >
                  {["OBJECT" as "OBJECT", "OTHER" as "OTHER"].map(
                    (category) => {
                      return (
                        <div
                          key={category}
                          onClick={() => setImageCategory(category)}
                          style={{
                            color: "white",
                            padding: 20,
                            paddingTop: 0,
                            fontWeight: 700,
                            cursor: "pointer",
                            fontSize: 12,
                            letterSpacing: 1,
                          }}
                        >
                          {category[0] +
                            category.slice(1, category.length).toLowerCase()}
                          {category === imageCategory ? (
                            <motion.div
                              style={{
                                marginTop: 10,
                                borderBottom: "2px solid white",
                              }}
                              layoutId="underline"
                            />
                          ) : null}
                        </div>
                      );
                    }
                  )}
                </div>
              </AnimateSharedLayout>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                width: "100%",
                overflowY: "auto",
                marginTop: openPanel === Panel.IMAGE ? 0 : -20, // To center content in panel
                height: openPanel === Panel.IMAGE ? "fit-content" : "100%",
              }}
            >
              {videoOrderData && openPanel === Panel.IMAGE && (
                <>
                  {videoOrderData &&
                    videoOrderData.getVideoOrder.content.images
                      .filter((img) => img.category === imageCategory)
                      .map((img, i) => {
                        return (
                          <img
                            key={i}
                            src={img.thumbnailUrl}
                            style={{
                              height: 80,
                              padding: 10,
                              cursor: "pointer",
                            }}
                            onClick={async () => {
                              let newVideoState: VideoState;
                              if (currentSlide === "new") {
                                newVideoState = {
                                  ...videoState,
                                  slides: [
                                    ...videoState.slides,
                                    {
                                      imageUrl: img.imageUrl,
                                      thumbnailUrl: img.thumbnailUrl,
                                      text: "",
                                      animation: videoTemplate.animation,
                                    },
                                  ],
                                };
                              } else {
                                newVideoState = {
                                  ...videoState,
                                  slides: videoState.slides.map((slide, i) => {
                                    if (i === currentSlide) {
                                      return {
                                        ...slide,
                                        imageUrl: img.imageUrl,
                                        thumbnailUrl: img.thumbnailUrl,
                                      };
                                    } else {
                                      return slide;
                                    }
                                  }),
                                };
                              }

                              setVideoState(newVideoState);
                              if (currentSlide === "new") {
                                const newSlideIndex =
                                  newVideoState.slides.length - 1;
                                setCurrentSlide(newSlideIndex);
                                let currentFrame = Math.round(
                                  newSlideIndex *
                                    videoOrderData.getVideoOrder.template
                                      .secondsPerSlide *
                                    videoOrderData.getVideoOrder.template.fps +
                                    (videoOrderData.getVideoOrder.template
                                      .secondsPerSlide *
                                      videoOrderData.getVideoOrder.template
                                        .fps) /
                                      2
                                );
                                setCurrentFrame(currentFrame);
                              }

                              setOpenPanel(Panel.NONE);
                            }}
                          ></img>
                        );
                      })}
                </>
              )}

              {videoOrderData && openPanel === Panel.TEXT && (
                <div>
                  <div
                    style={{
                      width: "80vw",
                      display: "flex",
                      flexDirection: "column",
                      fontWeight: 500,
                    }}
                  >
                    <label
                      htmlFor="text-input"
                      style={{
                        color: "white",
                        maxWidth: "100%",
                        textAlign: "left",
                        padding: 10,
                        paddingBottom: 20,
                        fontWeight: 700,
                        letterSpacing: 0.5,
                        fontSize: 14,
                      }}
                    >
                      Text
                    </label>
                    <input
                      id="text"
                      type="text"
                      value={text}
                      onChange={(e) => {
                        const newText = e.target.value;
                        setText(newText);
                        var returnedFunction = debounce(function () {
                          let slides = videoState.slides;
                          slides = slides.map((slide, i) => {
                            if (currentSlide === i) {
                              return { ...slide, text: newText };
                            }
                            return slide;
                          });
                          setVideoState({ ...videoState, slides: slides });
                        }, 500);
                        returnedFunction();
                      }}
                      style={{
                        maxWidth: "100%",
                        backgroundColor: "transparent",
                        outline: "none",
                        color: "white",
                        border: "1px solid white",
                        borderRadius: 10,
                        padding: 18,
                        fontSize: 12,
                      }}
                    ></input>
                  </div>
                  <div
                    style={{
                      width: 150,
                      padding: "0px 12px",
                      display: "flex",
                      flexDirection: "column",
                      fontWeight: 500,
                      fontSize: 12,
                    }}
                  >
                    <Select
                      labelId="select-text-label"
                      id="select-text"
                      className="video"
                      displayEmpty={true}
                      renderValue={(value: any) => {
                        let foundIndex;
                        const found = videoState.slides.find((s, i) => {
                          if (i !== currentSlide && s.text === value) {
                            foundIndex = i + 1;
                            return true;
                          }
                        });
                        return found
                          ? "Same as: Slide " + foundIndex
                          : "Same as ...";
                      }}
                      style={{ width: "100%" }}
                      value={videoState.slides[currentSlide].text}
                      onChange={(e) => {
                        if (e.target.value) {
                          let newVideoState = {
                            ...videoState,
                            slides: videoState.slides.map((slide, i) => {
                              if (i === currentSlide) {
                                let text = e.target.value as string;
                                setText(text);
                                return {
                                  ...slide,
                                  text: text,
                                };
                              } else {
                                return slide;
                              }
                            }),
                          };
                          setVideoState(newVideoState);
                        }
                      }}
                    >
                      {videoState.slides.map((slide, i) => {
                        if (i === currentSlide) {
                          return null;
                        }
                        return (
                          <MenuItem value={slide.text} style={{ fontSize: 12 }}>
                            {`Slide ${i + 1}: ${slide.text}`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              )}

              {videoOrderData && openPanel === Panel.ANIMATION && (
                <div
                  style={{
                    width: "80vw",
                    display: "flex",
                    flexDirection: "column",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  <InputLabel id="select-animation-label" className="video">
                    Animation
                  </InputLabel>
                  <Select
                    labelId="select-animation-label"
                    id="select-animation"
                    className="video"
                    value={
                      typeof currentSlide === "number" &&
                      videoState.slides[currentSlide].animation
                    }
                    onChange={(e) => {
                      if (e.target.value) {
                        let newVideoState = {
                          ...videoState,
                          slides: videoState.slides.map((slide, i) => {
                            if (i === currentSlide) {
                              return {
                                ...slide,
                                animation: e.target.value as any,
                              };
                            } else {
                              return slide;
                            }
                          }),
                        };
                        setVideoState(newVideoState);
                      }
                    }}
                  >
                    <MenuItem value={"NONE"} style={{ fontSize: 12 }}>
                      None
                    </MenuItem>
                    <MenuItem value={"ZOOM"} style={{ fontSize: 12 }}>
                      Zoom
                    </MenuItem>
                  </Select>
                </div>
              )}
            </div>
          </Sheet.Content>
        </Sheet.Container>
      </Sheet>

      <div
        id="open-panel-bg"
        style={{
          height: "100%",
          width: "100vw",
          bottom: openPanel === Panel.NONE ? "-100%" : 0,
          backgroundColor: "black",
          opacity: openPanel === Panel.NONE ? 0 : 0.5,
          zIndex: 9999,
          position: "fixed",
        }}
        onClick={() => {
          setOpenPanel(Panel.NONE);
        }}
      ></div>
    </>
  );
}

export default ModalPanel;
