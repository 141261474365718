import { useQuery } from '@apollo/react-hooks';
import { MenuItem, TextField } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { BiArchiveIn, BiMailSend } from 'react-icons/bi';
import { IoMdClose, IoMdCheckmark } from 'react-icons/io';
import { IoMdAdd } from 'react-icons/io';
import { GET_DIVISION } from '../graphql/queries';
import './DataCombo.css'

function DataCombo(props:any) {

  const { label, initValue, onConfirm, options, disabled } = props
  const [value, setValue] = useState<any>(null)

  return <>
    <TextField
      select
      label={label}
      value={value !== null ? value : initValue}
      disabled={disabled}
      inputProps={{
        style: {fontSize: 24}
      }}
      onChange={(event)=>{
        setValue(event.target.value)
        onConfirm(event.target.value)
      }}
    >
      {options.map((option:any) => (
        <MenuItem key={option.name} value={option.value}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  </>

}

export default DataCombo