import React, { useEffect, useState } from "react";

type Props = {
  children: JSX.Element;
  frame: number;
  startFrame: number;
  endFrame: number;
  zoomFactor: number;
  initialZoom?: number;
};

function Zoom(props: Props) {
  const { children, frame, startFrame, endFrame, zoomFactor, initialZoom } =
    props;

  let zoom = initialZoom ? initialZoom : 1;
  let zoomDuration = endFrame - startFrame;
  if (frame >= startFrame && frame <= endFrame) {
    zoom = zoom + ((frame - startFrame) / zoomDuration) * zoomFactor;
  }

  return (
    <div
      style={{
        transform: `scale(${zoom})`,
        width: "100%",
        height: "100%",
        position: "absolute",
      }}
    >
      {children}
    </div>
  );
}

export default Zoom;
