import React from 'react';
import './Header.css';
import GridItem from '../GridItem/GridItem';
import InputField from '../InputField/InputField';
function Header(props:any) {
  const {gridId } = props;
  return (
    <header id="top" className="section">
      <div id="header-grid">
        <h4 id="header-title">
          <InputField position={1} type={"text"} gridId={gridId} className="heading-3"/> 
        </h4>
        <GridItem className="bg-img header-image-1" gridId={gridId} position={1} />
        <GridItem className="bg-img header-logo" gridId={gridId} position={2} />
        <GridItem className="bg-img border header-image-4" gridId={gridId} position={3} />
        <div id="header-image-grid" className="grid grid1 preview">
          <GridItem className="bg-img grid1-1" gridId={gridId} position={4} />
          <GridItem className="bg-img grid1-2" gridId={gridId} position={5} />
        </div>
        <div id="header-text-container">
          <div id="header-text-container-inner-1">
            <InputField position={2} type={"text"} gridId={gridId} className="heading-3"/>
            <InputField position={3} type={"text"} gridId={gridId} className="heading-1"/>
          </div>
          <div id="header-text-container-inner-2">
            <InputField position={4} type={"text"} gridId={gridId} className="text-block text-1"/>
            <InputField position={5} type={"text"} gridId={gridId} className="text-block text-1"/>
            <InputField position={6} type={"text"} gridId={gridId} className="text-block text-1"/>
            <InputField position={7} type={"text"} gridId={gridId} className="text-block text-2 text-align-right"/>
            {/* <div className="text-block text-2 text-align-right">Förmedlas av: <a className="link" href="#broker">Namn Namnsson</a></div> */}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;