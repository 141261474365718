import React, { useState, useEffect, useRef } from 'react';
import './Register.css'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { REGISTER } from './graphql/mutations';
import { GET_USER } from './graphql/queries';
import {ReactComponent as Logo}  from './logo-hor.svg';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { ImUserPlus, ImUserCheck, ImUser } from 'react-icons/im'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#232323'
    }
  }
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        marginTop: theme.spacing(4),
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: 'white'
      },
    },
  }),
);

export default function Register() {
  const recover = useLocation().pathname === "/builder/recover";
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [complete, setComplete] = useState(false);
  const [responseError, setResponseError] = useState('');
  const [success, setSuccess] = useState(false);
  let history = useHistory();
  const [ mutate ] = useMutation(REGISTER);

  const passwordRef = useRef<any>(null)

  let token:string|null = null;
  // Is a registration for new user
  token = new URLSearchParams(useLocation().search).get("token");

  const { data: registerUserData } = useQuery(GET_USER, {
    fetchPolicy: 'network-only', 
    variables:{registerToken: token},
    skip: !token
  });

  useEffect(() => {
    if (registerUserData) {
      if (registerUserData.user) {
        setName(registerUserData.user.name)
        setEmail(registerUserData.user.email)
      } else {
        history.push({
          pathname: '/builder/login' 
        })
      }
    }
  }, [registerUserData])

  if (!token) {
    history.push({
      pathname: '/builder/login' 
    })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case "password":
        setPassword(event.target.value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (password) {
      setComplete(true);
    } else {
      setComplete(false);
    }
  }, [password])

  useEffect(() => {
    if (passwordRef && passwordRef.current) {
      passwordRef.current.focus();
    }
  }, [])

  return <>
    <div className="register-logo-text" style={{display: 'flex', margin: 0}}>
      <div className="navbar-back ion-ios-arrow-back" style={{marginRight: 50, color: 'white', width: 80}} onClick={()=>{
        history.push({
          pathname: '/builder/login' 
        })
      }}></div>
      <Logo style={{width: 200}} />
    </div>
    <div className="register-container" style={{
    backgroundColor: '#232323'
  }}>
    {<div className="register-input-container" style={{padding: 80, backgroundColor: 'white'}}>
      {<>
      {!success && !recover && <div style={{fontSize: 70, color: '#232323'}}><ImUserPlus /></div>}
      {!success && recover && <div style={{fontSize: 70, color: '#232323'}}><ImUser /></div>}
      {!success && <div style={{marginBottom: 70, width: '100%'}}><MuiThemeProvider theme={theme}>
      <form className={classes.root} noValidate autoComplete="off">
      <FormControl>
        <InputLabel htmlFor="name">Name</InputLabel>
        <Input 
          disabled={true}
          id="name" 
          value={name} 
        />
      </FormControl>
      <FormControl>
        <InputLabel htmlFor="email">Email</InputLabel>
        <Input
          disabled={true}
          id="email"
          value={email}
        />
      </FormControl>
      <FormControl>
        <InputLabel htmlFor="password">New password</InputLabel>
        <Input
          inputRef={passwordRef}
          inputProps={{
            maxLength: 30,
            type:"password"
          }}
          id="password"
          value={password}
          onChange={handleChange}
        />
      </FormControl>
    </form>
      </MuiThemeProvider></div>}

      {!success && <div tabIndex={0} className={`register-button ${complete && "active"}`} onClick={async ()=>{
        try {
          if (complete) {
            let data:any;
            await mutate({variables:{password: password, registerToken: token}}).then((res)=>{
              data = res.data;
            })
            if (data && data.register.success) {
              setSuccess(true);
            } else if(data && data.register.error.msg) {
              setResponseError(data.register.error.msg)
            } else {
              setResponseError("Unknown error")
            }
          }
        } catch (e) {
          setResponseError("Unknown error")
        }
      }}> 
      {!recover && <>REGISTER</>}
      {recover && <>SET NEW PASSWORD</>}
      </div>}
      {responseError && !success && <div style={{padding: 20}}>{responseError}</div>}
      {success && <>
        <div style={{fontSize: 80}}><ImUserCheck /></div>
        {!recover && <div>Registration complete</div>}
        {recover && <div>New password set</div>}
        <div className={`register-button active`} style={{marginTop: 30}} onClick={async ()=>{
          history.push({
            pathname: '/builder/login' 
          })
        }}>LOGIN</div>
      </>}
      </>}
    </div>}
  </div>
  </>
}