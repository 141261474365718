import React, { useEffect, useState } from "react";

type Props = {
  children: JSX.Element;
  frame: number;
  startFrame: number;
  endFrame: number;
  isNextSlide?: boolean;
};

function FadeIn(props: Props) {
  const { children, frame, startFrame, endFrame, isNextSlide } = props;

  let opacity = 0;
  let fadeDuration = endFrame - startFrame;
  if (frame >= startFrame && frame <= endFrame) {
    opacity = (frame - startFrame) / fadeDuration;
  }
  if (frame > endFrame) {
    opacity = 1;
  }

  return (
    <div
      style={{
        opacity: opacity,
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: isNextSlide ? 2 : 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
}

export default FadeIn;
