import React, { useState, useEffect, useRef } from 'react';
import Navbar from './Navbar/Navbar';
import SortableTree from './SortableTree/SortableTree';
import './Settings.css'
import DataTable from './DataTable/DataTable'
import DataInput from './DataInput/DataInput'
import DataCombo from './DataCombo/DataCombo'
import { CgListTree } from 'react-icons/cg';
import { IoMdAdd, IoMdClose, IoMdTrash } from 'react-icons/io';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_ACCOUNT, GET_ACCOUNTS, GET_DIVISION, GET_DIVISIONS, GET_USER } from './graphql/queries';
import { CREATE_ACCOUNT, CREATE_DIVISION, DELETE_ACCOUNT, DELETE_DIVISION, UPDATE_ACCOUNT, UPDATE_DIVISION } from './graphql/mutations';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MdEdit } from 'react-icons/md';
import { BiArrowToLeft } from 'react-icons/bi';

function Settings() {

  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: '#FFFFFF'
      }
    }
  });

  const { data:userData} = useQuery(GET_USER, {fetchPolicy: 'network-only'});
  
  const [showOrganization, setShowOrganization] = useState(true)
  const [selected, setSelected] = useState<{type: String, id: String, parent?: String} | null>(null)
  const [comboAccount, setComboAccount] = useState(null);

  const { data: accountsData } = useQuery(GET_ACCOUNTS, {fetchPolicy: 'network-only'});
  const [ updateAccount ] = useMutation(UPDATE_ACCOUNT)
  const [ createAccount ] = useMutation(CREATE_ACCOUNT)
  const [ deleteAccount ] = useMutation(DELETE_ACCOUNT)
  const [ updateDivision ] = useMutation(UPDATE_DIVISION)
  const [ deleteDivision ] = useMutation(DELETE_DIVISION)
  const [ createDivision ] = useMutation(CREATE_DIVISION)

  const { data: divisionData, loading: divisionDataLoading, refetch:divisionRefetch } = useQuery(GET_DIVISION, {
    variables:{divisionId: selected && selected.type === "division" ? selected.id : null}, 
    skip: !selected || selected.id === "new-division" || selected.type !== "division",
    fetchPolicy: 'network-only'
  });
  const { data: accountData, loading: accountDataLoading, refetch:accountRefetch } = useQuery(GET_ACCOUNT, {
    variables:{accountId: selected && selected.type === "account" ? selected.id : null}, 
    skip: !selected || selected.id === "new-account" || selected.type !== "account",
    fetchPolicy: 'network-only'
  });

  const [tab, setTab] = useState(0);

  const settingsEditContainerRef = useRef(null)
  const [tableWidth, setTableWidth] = useState(0)

  useEffect(() => {
    if (userData) {
      // Set initial combo value
      setComboAccount(userData.user.account);
    }
  }, [userData])

  let eventListener = null;
  useEffect(() => {
    if (eventListener) {
      window.removeEventListener('resize', eventListener)
    }
    const handleResize = () => {
      if (showOrganization) {
        setTableWidth(window.innerWidth - 568);
      } else {
        setTableWidth(window.innerWidth - 205);
      }
    }
    eventListener = window.addEventListener('resize', handleResize)
    handleResize();
  }, [showOrganization])

  return (
    <>
      <div className="App" id="settings">
        <Navbar />
        <div className="settings-container-wrapper">
          <div className="people-container" 
            style={{minWidth: 400, marginLeft: showOrganization ? 0 : -335}}
          >
            <div className="people-container-header" onClick={()=>{setShowOrganization(!showOrganization)}}>
              <div>Organization</div>
              <div>
                {!showOrganization && <CgListTree style={{fontSize: 24}}/>}
                {showOrganization && <BiArrowToLeft style={{fontSize: 26}}/>}
              </div>
            </div>
            <div className="people-container-inner" style={{width: 'calc(100% - 85px)', overflowY: 'hidden'}}>
              {userData && userData.user.permissions.includes("ViewAccounts") && <div style={{display: 'flex', alignItems: 'center', marginBottom: 20}}>
                <Autocomplete
                  id="account-combo"
                  options={accountsData ? accountsData.accounts : []}
                  getOptionLabel={(option:any) => option.name}
                  getOptionSelected={(option, value) => {
                    if (option && value) return option.id === value.id
                  } }
                  value={comboAccount}
                  onChange={(event: any, newValue: any) => {
                    if (newValue) {
                      setComboAccount(newValue);
                      setSelected(null)
                    }
                  }}
                  style={{ padding: 5, width: '80%' }}
                  renderInput={(params) => <div style={{display: 'flex', alignItems: 'center'}}>
                    <TextField label="Account" {...params} />
                  </div>}
                />
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <MdEdit 
                  title="Edit account" 
                  className="account-edit" 
                  style={{
                    opacity: !comboAccount ? 0.5 : 1,
                    cursor: !comboAccount ? 'default' : 'pointer'
                  }}
                  onClick={(e)=>{
                    if (!comboAccount) return
                    setSelected({type:"account", id: comboAccount.id});
                  }} />
                    
                  {userData && userData.user.permissions.includes("CreateAccounts") && <div 
                    className="add-button-circle" 
                    style={{
                      position: 'static', 
                      marginLeft: 10, 
                      marginTop: 14,
                      opacity: !comboAccount ? 0.5 : 1,
                      cursor: !comboAccount ? 'default' : 'pointer'
                    }}
                    onClick={()=>{
                      if (!comboAccount) return
                      setComboAccount(null)
                      setSelected({type:"account", id: "new-account"});
                    }}
                  >
                    <IoMdAdd title="Add account" />
                  </div>}
                </div>
              </div>}
              <SortableTree setSelectedCallback={setSelected} accountId={comboAccount && comboAccount.id} selectedId={selected && selected.id} />
            </div>
          </div>
          <div ref={settingsEditContainerRef} id="settings-edit-container" style={{
            backgroundColor:selected? '#2c2c2c':'#232323', 
            transition:'all 1s', 
            // width: selected ? '100%' : '50%',
            // maxWidth: 'calc(100vw - 500px)'
          }}>
          {selected && <div style={{padding: '0px 40px', width: '100%'}}>

            {/* DIVISION & DIVISION OVERVIEW EDIT AREA */}
            {selected && (selected.type === "division" || selected.type === "overview") && <>
              {selected.type === "division" && userData && userData.user.permissions.includes("EditDivisions") && <div style={{
                  position:'relative', 
                  display:'flex', 
                  width: '100%', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  minHeight: 60
                }}>
                {!divisionDataLoading && <DataInput 
                  label="Division name" 
                  autoFocus={true}
                  initValue={selected.id === "new-division" ? null : divisionData && divisionData.division.name}
                  onConfirm={(name:String)=>{
                    if (selected.id === "new-division") {
                      createDivision({variables:{name: name, parentDivisionId: selected.parent}, refetchQueries: [{query: GET_DIVISIONS, variables:{accountId: comboAccount.id}}]})
                      .then((res)=>{
                        divisionRefetch({divisionId: res.data.createDivision.id}).then(()=>{
                          setSelected({id: res.data.createDivision.id, type: "division"})
                        })
                      })
                    } else {
                      updateDivision({variables:{divisionId: selected.id, name: name}, refetchQueries: [{query: GET_DIVISIONS, variables:{accountId: comboAccount.id}}]})
                      setSelected({id: selected.id, type: "division"})
                    }
                  }} 
                />}
                  {userData && userData.user.permissions.includes("DeleteDivisions") && selected.id !== "new-division" && <div style={{
                    width: 60, 
                    cursor: divisionData && divisionData.division.deleteable ? 'pointer' : 'default',
                    opacity: divisionData && divisionData.division.deleteable ? 1 : 0.5
                  }} onClick={()=>{
                    if (!divisionData || !divisionData.division.deleteable) {
                      return
                    }
                    deleteDivision({variables:{divisionId: selected.id}, refetchQueries: [{query: GET_DIVISIONS, variables:{accountId: comboAccount.id}}]})
                    // Deselect
                    setSelected(null)

                  }}>
                    <IoMdTrash title="Delete" className="trash" />
                  </div>}
              </div>}

              {selected.type === "division" && divisionData && userData && !userData.user.permissions.includes("EditDivisions") && <div style={{fontSize: 24, color: 'white'}}>
                {divisionData && divisionData.division.name}
              </div>}

              {selected.type === "overview" && <div style={{fontSize: 24, color: 'white'}}>
                Overview
              </div>}
            
              {selected.id !== "new-division" && <>
                <MuiThemeProvider theme={theme}>
                  <Tabs
                    style={{marginBottom: 20, marginTop: 30}}
                    value={tab}
                    indicatorColor="secondary"
                    textColor="secondary"
                    onChange={(event, newValue)=>setTab(newValue)}
                    disableRipple={true}
                  >
                    <Tab style={{minWidth: 100}} disableRipple={true} label="Users" />
                    <Tab style={{minWidth: 100}} disableRipple={true} label="Roles" />
                    <Tab style={{minWidth: 100}} disableRipple={true} label="Permissions" />
                  </Tabs>
                </MuiThemeProvider>

                {tab === 0 && <DataTable usersTable={true} division={selected.id} account={comboAccount} refetchDivisionCallback={divisionRefetch} />}
                {tab === 1 && <DataTable rolesTable={true} division={selected.id} account={comboAccount} refetchDivisionCallback={divisionRefetch} />}
                {tab === 2 && <DataTable permissionsTable={true} division={selected.id} tableWidth={tableWidth} account={comboAccount} />}
              </>}
            </>}

            {/* ACCOUNT EDIT AREA */}
            {selected && selected.type === "account" && <>
              <div 
              style={{position:'relative', display:'flex', marginBottom: 40 , width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                {!accountDataLoading && <DataInput 
                  label="Account name"
                  autoFocus={true} 
                  initValue={accountData && selected.id !== "new-account" && accountData.account.name} 
                  onConfirm={(name:String)=>{
                    if (selected.id === "new-account") {
                      createAccount({variables:{name: name}}).then((res)=>{
                        accountRefetch({accountId: res.data.createAccount.id}).then(()=>{
                          setSelected({id: res.data.createAccount.id, type: "account"})
                          setComboAccount({id:res.data.createAccount.id, name:res.data.createAccount.name});
                        })
                      })
                    } else {
                      updateAccount({variables:{accountId: selected.id, name: name}, refetchQueries:[{query: GET_ACCOUNT, variables: {accountId: selected.id}}]})
                    }
                  }} 
                />}
                  {userData && userData.user.permissions.includes("DeleteAccounts") && selected.id !== "new-account" && <div style={{
                    width: 60, 
                    cursor: accountData && accountData.account.deleteable ? 'pointer' : 'default',
                    opacity: accountData && accountData.account.deleteable ? 1 : 0.5
                  }} onClick={async ()=>{
                    if (!accountData || !accountData.account.deleteable) {
                      return
                    }
                    await deleteAccount({variables:{accountId: selected.id}, refetchQueries: [{query: GET_ACCOUNTS}]})
                    setSelected(null)
                    setComboAccount(null);
                  }}>
                    <IoMdTrash title="Delete" className="trash" />
                  </div>}
              </div>
              <div style={{position:'relative', display:'flex', marginBottom: 40, width: '100%'}}>
                {!accountDataLoading && <DataInput 
                  label="Logo URL" 
                  disabled={!accountData}
                  initValue={accountData && selected.id !== "new-account" && accountData.account.logoUrl} 
                  onConfirm={(logoUrl:String)=>{
                    updateAccount({variables:{accountId: selected.id, logoUrl: logoUrl}})
                  }} 
                />}
              </div>
              {/* Cannot disable own account */}
              {userData && userData.user.account.id !== selected.id && <div style={{position:'relative', display:'flex', marginBottom: 40, width: '100%'}}>
              {!accountDataLoading && <DataCombo 
                  label={"Status"}
                  disabled={!accountData}
                  initValue={accountData && selected.id !== "new-account" && accountData.account.disabled}
                  options={[{name: "Disabled", value: true}, {name: "Enabled", value: false}]}
                  onConfirm={(disabled:boolean)=>{
                    if (selected.id !== "new-account") {
                      updateAccount({variables:{accountId: selected.id, disabled: disabled}})
                    } 
                  }}
                />}
              </div>}
            </>}

          </div>}
        </div>
      </div>
    </div>
  </>
  )

}

export default Settings