import React, { useState } from 'react';
import './Section.css';
import Grid1 from '../Grid/Grid1';
import Grid2 from '../Grid/Grid2';
import Grid3 from '../Grid/Grid3';
import Grid4 from '../Grid/Grid4';
import Grid5 from '../Grid/Grid5';
import AddButton from '../AddButton/AddButton';
import { AddButtonType } from '../AddButton/AddButton';
import InputField from '../InputField/InputField';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { useQuery } from '@apollo/react-hooks';
import { GET_SECTION_GRIDS } from '../graphql/queries';
import Grid6 from '../Grid/Grid6';
import Grid7 from '../Grid/Grid7';
import Grid8 from '../Grid/Grid8';
import Grid9 from '../Grid/Grid9';
import Expandable from '../Expandable/Expandable';

function Section(props:any) {
  const {id, tag, title}  = props.section;
  const [sectionTag, setSectionTag] = useState(tag);
  const [sectionTitle, setSectionTitle] = useState(title);
  const [grids, setGrids] = useState<any>([])
  const [isHeader, setIsHeader] = useState(false)
  const [isFooter, setIsFooter] = useState(false)
  const {data, refetch} = useQuery(GET_SECTION_GRIDS, {variables:{sectionId: id}, fetchPolicy: 'no-cache'})
  refetch(); // Refetch needed because otherwise the resolver is only called once
  if (data && data.sectionGrids && grids != data.sectionGrids) {
    if (!isHeader && data.sectionGrids[0] && data.sectionGrids[0].type === "header") {
      setIsHeader(true)
    }
    if (!isFooter && data.sectionGrids[0] && data.sectionGrids[0].type === "footer") {
      setIsFooter(true)
    }
    setGrids(data.sectionGrids)
  }

  const titleDeleted = sectionTitle === "__DELETED__" || sectionTitle === "";
  const tagDeleted = sectionTag === "__DELETED__" || sectionTag === "";

  return (
    <div className="section">
      { !isHeader && !isFooter && (!titleDeleted || !tagDeleted) &&
       <div className={!titleDeleted && !tagDeleted ? "title-grid" : !titleDeleted ? "title-grid title-only" : "title-grid tag-only"}>
        {!tagDeleted && <div className="tag heading-4">
          <InputField type={"tag"} sectionId={id} removeTag={()=>{setSectionTag("")}} className="heading-4"/> 
        </div>}
        {!titleDeleted && <div className="title heading-2">
          <InputField type={"title"} sectionId={id} removeTitle={()=>{setSectionTitle("")}} className="heading-2" /> 
        </div>}
      </div> }
      {grids && grids.map((grid:any, index: number)=>{
        let stackExpandables = false;
        if (grids[index-1] && grids[index-1].type === "10" && grid.type === "10") {
          stackExpandables = true;
        }
        switch (grid.type) {
          case "1": return <Grid1 key={grid.id} gridId={grid.id} />
          case "2": return <Grid2 key={grid.id} gridId={grid.id} />
          case "3": return <Grid3 key={grid.id} gridId={grid.id} />
          case "4": return <Grid4 key={grid.id} gridId={grid.id} />
          case "5": return <Grid5 key={grid.id} gridId={grid.id} />
          case "6": return <Grid6 key={grid.id} gridId={grid.id} />
          case "7": return <Grid7 key={grid.id} gridId={grid.id} />
          case "8": return <Grid8 key={grid.id} gridId={grid.id} />
          case "9": return <Grid9 key={grid.id} gridId={grid.id} />
          case "10": return <Expandable key={grid.id} gridId={grid.id} stack={stackExpandables} />
          case "header": return <Header key={grid.id} gridId={grid.id} />
          case "footer": return <Footer key={grid.id} gridId={grid.id} />
          default: return null
        }
      })}
      {!isHeader && !isFooter && <AddButton type={AddButtonType.grid} sectionId={id} />}
    </div>
  );
}

export default Section;