import React, { useState, useEffect } from 'react'
import './Expandable.css'
import GridItem from '../GridItem/GridItem';
import InputField from '../InputField/InputField';
import { useQuery } from '@apollo/react-hooks';
import { GET_ITEM } from '../graphql/queries';

function Expandable({preview, gridId, stack}:any) {
  const {data} = useQuery(GET_ITEM, {variables:{gridId: gridId, position:1}})
  const [expanded, setExpanded] = useState(false)
  const [fixedHeight, setFixedHeight] = useState(true)
  const [height, setHeight] = useState<any>(0)

  if (fixedHeight && data && data.item && data.item.__typename === "TextArea") {
    setFixedHeight(false);
  } else if (!fixedHeight && data && data.item && data.item.__typename !== "TextArea") {
    setFixedHeight(true);
  }

  useEffect(() => {
    if (fixedHeight) {
       setHeight(expanded ? '20vw' : 0)
    } else {
      setHeight(expanded ? 'auto' : 0)
    }
  }, [expanded, fixedHeight])
  

  return (
    <>
    {preview ? 
    <div style={{height: '100%', width:'100%'}}>
      <div style={{height: '30%', display: 'flex', justifyContent: 'space-between', paddingLeft: 10, paddingRight: 10}}>
        <div>Text</div>
        <div className="ion-ios-arrow-down"></div>
      </div>
      <div style={{height: '70%', backgroundColor: '#eee'}}></div>
    </div>
    : 
    <div className="expand-grid-container" style={{borderTop: stack ? 'none' : '2px solid #eee'}}>
      <div className="expand-row" onClick={(e)=>{
          setExpanded(!expanded);
        }}>
        <div><InputField position={1} type={"text"} gridId={gridId} className="expand-grid-text"/></div>
        <div className="ion-ios-arrow-down expand-grid-arrow" style={expanded ? {transform: 'rotateZ(180deg)'} : {transform: 'rotateZ(0deg)'}}></div>
      </div>
      <div className="grid grid10" style={{height: height, gridTemplateRows: fixedHeight ? '20vw' : '1fr' }}>
          <GridItem className="bg-img grid10-1" gridId={gridId} position={1}/>
      </div>
    </div>}
  </>
  )
}

export default Expandable


