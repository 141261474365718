import React, { useEffect, useRef, useState } from 'react';
import './ProjectModal.css';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_ACCOUNTS, GET_ACCOUNT_USERS, GET_DIVISIONS, GET_DIVISION_USERS, GET_PROJECTS, GET_USER } from '../graphql/queries';
import { CREATE_PROJECT, DELETE_PROJECT, DUPLICATE_PROJECT } from '../graphql/mutations';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import {ReactComponent as Logo}  from '../icon.svg';
import { inflateData } from '../compression-utils';
import formatDate from '../formatDate';
import moment from 'moment';
import TemplateModal from '../TemplateModal/TemplateModal'
import { BiArrowToLeft, BiDuplicate } from 'react-icons/bi'
import { IoMdTrash } from 'react-icons/io'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { useStateRef } from '../utils'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { FaFilter } from "react-icons/fa";

// State stored in localstorage to bring the User back to the previous state 
// when navigating back to this view
interface vfModalState {
  filterUser: String; // Last selected user
  limit: Number;  // Number of projects last fetched
  projectId: String; // Project last visited
}
function getLocalstorageValue(key:"filterUser"|"filterDivision"|"filterAccount"|"limit"|"projectId") {
  let state = localStorage.getItem("vf-modal-state");
  if (state) {
    let JsonState:vfModalState = JSON.parse(state);
    if (JsonState) {
      return JsonState[key]
    }
  }
}

function ProjectModal(props:any) {
  const [done, setDone] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [users, setUsers] = useState<any>([]);
  const [comboAccount, setComboAccount] = useState<any>(null);
  const [comboDivision, setComboDivision] = useState<any>(null);
  const [comboUser, setComboUser] = useState<any>(null);
  const [accountDivisions, setAccountDivisions] = useState<any>([]);
  const [width, setWidth] = useState(Math.floor(window.innerWidth/(340+40))*340);
  const [showFilter, setShowFilter] = useState(false)
  const [showScrollTop, setShowScrollTop] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState<any>(null);
  const [deleteTemplateDialog, setDeleteTemplateDialog] = useState<any>(null);
  const [duplicateDialog, setDuplicateDialog] = useState<any>(null);
  const [duplicateTemplateDialog, setDuplicateTemplateDialog] = useState<any>(null);
  const [duplicateAsTemplateDialog, setDuplicateAsTemplateDialog] = useState<any>(null);
  const [lockedDialog, setLockedDialog] = useState<any>(null);
  const [addProjectDialog, setAddprojectDialog] = useState<any>(null);
  const [fetchDate, setFetchDate] = useState<any>(null);
  const [projects, setProjects, projectsRef] = useStateRef([]);
  const [limit, setLimit, limitRef] = useStateRef(getLocalstorageValue("limit") ? getLocalstorageValue("limit") : 10);
  const [expandAddButton, setExpandAddButton] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const { data:projectsData, loading:projectsLoading, refetch:projectsRefetch } = useQuery(
    GET_PROJECTS, 
    {fetchPolicy: 'network-only', 
    variables: {
      accountId: comboAccount && comboAccount.id, 
      divisionId: comboDivision && comboDivision.id, 
      userId: comboUser && comboUser.id,
      limit: limit
    },
    skip: !comboAccount && !comboDivision && !comboUser
  });
  const [ createProject ] = useMutation(CREATE_PROJECT);
  const [ deleteProject ] = useMutation(DELETE_PROJECT);
  const [ duplicateProject ] = useMutation(DUPLICATE_PROJECT);
  const { data: userData } = useQuery(GET_USER, {fetchPolicy: 'network-only'});

  const { data: accountsData } = useQuery(GET_ACCOUNTS, {fetchPolicy: 'network-only'});
  const { data: divisionsData } = useQuery(GET_DIVISIONS, {fetchPolicy: 'network-only', variables:{accountId: comboAccount && comboAccount.id}, skip: !user || !user.permissions.includes("ViewDivisions") || !comboAccount});
  const { data: accountUsersData } = useQuery(GET_ACCOUNT_USERS, {fetchPolicy: 'network-only', variables:{accountId: comboAccount && comboAccount.id}, skip: !user || !user.permissions.includes("ViewDivisions") || !comboAccount || comboDivision});
  const { data: divisionUsersData } = useQuery(GET_DIVISION_USERS, {fetchPolicy: 'network-only', variables:{divisionId: comboDivision && comboDivision.id}, skip: !user || !user.permissions.includes("ViewDivisions") || !comboDivision});
  const containerRef = useRef<any>(null)

  useEffect(() => {
    if (projectsData) {
      setProjects(projectsData.projects);
      setFetchDate(new Date().toISOString());
    }
  }, [projectsData])

  useEffect(() => {
    if (userData) {
      // Set initial combo values
      setComboAccount(window.localStorage.getItem("vf-modal-state") ? getLocalstorageValue("filterAccount") : userData.user.account);
      setComboDivision(window.localStorage.getItem("vf-modal-state") ? getLocalstorageValue("filterDivision") : userData.user.division);
      setComboUser(window.localStorage.getItem("vf-modal-state") ? getLocalstorageValue("filterUser") : userData.user);
      
      setUser(userData.user)
    }
  }, [userData])

  useEffect(() => {
    if (divisionsData) {
      // Fetched divisions filtered on account
      let divisions = [];
      const recursive = (division, indent) => {
        divisions.push({id:division.id, name:division.name, indent: indent})
        if (!division.divisions) return;
        division.divisions.forEach(division => {
          recursive(division, indent+1)
        });
      }
      recursive(divisionsData.divisions[0], 0)
      setAccountDivisions(divisions)
    }
  }, [divisionsData])

  useEffect(() => {
    // Fetched users filtered on account
    if (accountUsersData) {
      
      // // An ads admin should always be able to find himself in Users combo when filtered on account
      // // (accountUsers does not fetch own user)
      // if (comboAccount.id === userData.user.account.id) {
      //   const found = accountUsersData.accountUsers.find(user => {
      //     return user.id === userData.user.id
      //   });
      //   if (!found) {
      //     setUsers([...accountUsersData.accountUsers, userData.user])
      //     return;
      //   }
      // }

      setUsers(accountUsersData.accountUsers)
    }
  }, [accountUsersData])

  useEffect(() => {
    if (divisionUsersData) {
      // Fetched users filtered on division
      setUsers(divisionUsersData.divisionUsers)
    }
  }, [divisionUsersData])
  

  useEffect(() => {
    if (userData) { // Preventing setting filter initially when combo boxes are null
      let newLimit = limit;
      if (
        getLocalstorageValue("filterAccount")?.id !== comboAccount?.id ||
        getLocalstorageValue("filterAccount")?.id !== comboAccount?.id ||
        getLocalstorageValue("filterDivision")?.id !== comboDivision?.id ||
        getLocalstorageValue("filterUser")?.id !== comboUser?.id
      ) {
        // Switched filter -> scroll to top and set limit back to 10
        containerRef.current.scrollTop = 0;
        newLimit = 10;
        setLimit(newLimit);
      }

      // Update local storage state
      let filter = {filterAccount: comboAccount, filterDivision: comboDivision, filterUser: comboUser, limit: newLimit, projectId: getLocalstorageValue("projectId")}
      localStorage.setItem("vf-modal-state", JSON.stringify(filter));
    }
  }, [comboAccount, comboDivision, comboUser, limit])

  const calculateNumerOfCols = () => {
    const container:any = containerRef.current;
    if (container) {
      let nColumns = Math.floor(container.clientWidth/(340+40))
      if (!nColumns) {
        nColumns = 1;
      }
      setWidth(nColumns*340);
    }
  }

  const fetchMoreProjects = () => {
    const container:any = containerRef.current;
    if (container) {
      if ((containerRef.current.scrollTop+containerRef.current.clientHeight) > containerRef.current.scrollHeight-100 ||
          (containerRef.current.clientHeight === containerRef.current.scrollHeight)) {
          // Reached bottom of the container
          if (projectsRef.current.length === limitRef.current) {
            // Number of projects recieved is the same as the requested limit -> There are more projects to fetch
            setLimit(limitRef.current+10)
          }
      }
    }
  }

  useEffect(() => {
    const container:any = containerRef.current;
    if (container) {
      if (containerRef.current && containerRef.current.scrollHeight > window.innerHeight) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    }
  })

  useEffect(()=>{
    if (!projectsLoading && containerRef.current) {
      window.addEventListener('resize', calculateNumerOfCols)
      containerRef.current.addEventListener('scroll', fetchMoreProjects)
      // Set intial numer of columns
      calculateNumerOfCols();
      // Check if more projects needs to be fetched right away (for screens displaying more than 10 projects)
      fetchMoreProjects();
    }
  }, [projectsLoading])

  useEffect(() => {
    // Scroll to the last visited project
    if (projects.length) {
      const projectThumbnailEl = document.getElementById(getLocalstorageValue("projectId")+"");
      if (projectThumbnailEl) {
        containerRef.current.scrollTop = projectThumbnailEl.offsetTop;
        let filter = {filterAccount: getLocalstorageValue("filterAccount"), filterDivision: getLocalstorageValue("filterDivision"), filterUser: getLocalstorageValue("filterUser"), limit: getLocalstorageValue("limit"), projectId: ""}
        localStorage.setItem("vf-modal-state", JSON.stringify(filter));
      }
    }
  }, [projects])

  const CloudFrontUrl = "https://dkj4aap2z9ncw.cloudfront.net"


  const FilterTags = () => {
    let filterTags = []

    if ( (user && user.permissions.includes("ViewAccounts")) && comboAccount && comboDivision && comboUser) {
      filterTags = [{name: comboAccount.name, type: 'account'}, {name: comboDivision.name, type: 'division'}, {name: comboUser.name, type: 'user'}]
    } else if ((user && user.permissions.includes("ViewAccounts")) && comboAccount && comboUser) {
      filterTags = [{name: comboAccount.name, type: 'account'}, {name: comboUser.name, type: 'user'}]
    } else if (comboDivision && comboUser) {
      filterTags = [{name: comboDivision.name, type: 'division'}, {name: comboUser.name, type: 'user'}]
    } else if ((user && user.permissions.includes("ViewAccounts")) && comboAccount && comboDivision) {
      filterTags = [{name: comboAccount.name, type: 'account'}, {name: comboDivision.name, type: 'division'}]
    }

    if (!filterTags.length) {
      if ((user && user.permissions.includes("ViewAccounts")) && comboAccount) {
        filterTags = [{name: comboAccount.name, type: 'account'}]
      } else if (comboDivision) {
        filterTags = [{name: comboDivision.name, type: 'division'}]
      } else if (comboUser) {
        filterTags = [{name: comboUser.name, type: 'user'}]
      }
    }

    return <div style={{position:'absolute', top: -60, marginLeft: 20, color:'white'}}>
      <span style={{fontSize: 36, marginRight: 20}}>Ads</span>
      {(user && user.permissions.includes("ViewDivisions")) && filterTags.map((tag,index) => {
        return <>
          <span className="filter-tag" 
            onClick={()=>{
              if (tag.type === "account") {
                setComboDivision(null);
                setComboUser(null);
              } else if (tag.type === "division") {
                setComboUser(null);
              }
            }}
          >{tag.name}</span>
          {index !== filterTags.length-1 && <span style={{margin:'0px 4px'}}>{">"}</span>}
        </>
      })}
    </div>
  }

  return (
    <>
    {showTemplateModal && <TemplateModal closeModal={()=>{setShowTemplateModal(false)}} refetchProjects={projectsRefetch} />}
    <div className="project-container-wrapper" style={{opacity: done ? 0 :  1}}>
      {(user && user.permissions.includes("ViewDivisions")) && <div className="people-container" style={{minWidth: 400, marginLeft: showFilter ? 0 : -335}}>
        <div className="people-container-header" onClick={()=>{setShowFilter(!showFilter)}}>
          <div>Filter</div>
          {showFilter ? <BiArrowToLeft style={{fontSize: 26}} /> : <FaFilter style={{fontSize: 16}}/> }
        </div>
        <div className="people-container-inner" style={{width: 'calc(100% - 85px)'}}>
        {user && user.permissions.includes("ViewAccounts") && <Autocomplete
          id="account-filter-combo"
          options={accountsData ? accountsData.accounts : []}
          getOptionLabel={(option:any) => option.name}
          getOptionSelected={(option, value) => {
            if (option && value) return option.id === value.id
          } }
          value={comboAccount}
          onChange={(event: any, newValue: any) => {
            if (newValue) {
              setComboDivision(null)
              setComboUser(null)
              setComboAccount(newValue);
            }
          }}
          style={{ padding: 5, marginRight: 10, width:'100%', marginBottom:10 }}
          renderInput={(params) => <div style={{display: 'flex', alignItems: 'center'}}>
            <TextField label="Account" {...params} />
          </div>}
        />}
        {user && user.permissions.includes("ViewDivisions") && <Autocomplete
          id="division-filter-combo"
          options={accountDivisions}
          getOptionLabel={(option) => option.name}
          renderOption={(option) => {
            return <div style={{marginLeft: option.indent ? option.indent*15 : 0}}> 
              {option.name}
            </div>
          }}
          getOptionSelected={(option, value) => {
            if (option && value) return option.id === value.id
          } }
          value={comboDivision}
          onChange={(event: any, newValue: any) => {
            setComboUser(null)
            setComboDivision(newValue);
          }}
          style={{ padding: 5, marginRight: 10, width:'100%', marginBottom:10 }}
          renderInput={(params) => <div style={{display: 'flex', alignItems: 'center'}}>
            <TextField label="Division" {...params} />
          </div>}
        />}
        {user && user.permissions.includes("ViewUsers") && <Autocomplete
          id="user-filter-combo"
          options={users}
          getOptionLabel={(option:any) => option.name}
          getOptionSelected={(option, value) => {
            if (option && value) return option.id === value.id
          } }
          value={comboUser}
            onChange={(event: any, newValue: any) => {
              setComboUser(newValue);
            }}
          style={{ padding: 5, marginRight: 10, width:'100%', marginBottom:10 }}
          renderInput={(params) => <div style={{display: 'flex', alignItems: 'center'}}>
            <TextField label="User" {...params} />
          </div>}
        />}
          <div 
            className="people-row reset" 
            style={{width: '100%', marginTop: 30}}
            onClick={()=>{
              setComboAccount(userData.user.account)
              setComboDivision(userData.user.division)
              setComboUser(userData.user);
            }}
          >
            RESET
          </div>
        </div>
      </div>}
      <div className="project-container" ref={containerRef} onClick={()=>setExpandAddButton(false)}>
        {(user && user.permissions.includes("CreateAds") && comboUser && user.id === comboUser.id) && <div className={`new-ad-button ${expandAddButton ? 'expanded' : 'ion-plus'}`} onClick={async (e)=>{
          e.stopPropagation();
          if (!expandAddButton) {
            setExpandAddButton(true);
          }
        }}><div><div className={`button-container ${expandAddButton && 'expanded'}`}>{expandAddButton && <>
          <div className="button" onClick={async () => {
            await createProject({variables:{userId: user.id}})
            if ((projects.length - limit) === 0) {
              // Number of projects becomes more than the limit -> Fetch more projects
              setLimit(limit+10)
            } else {
              projectsRefetch();
            }
            setExpandAddButton(false);
          }}> <span className="ion-plus"></span><span style={{marginBottom: 4, marginLeft: 6}}>Ad</span></div>
          {(user && user.permissions.includes("CreateTemplates")) && <div className="button" onClick={async ()=>{
            // Create new template
            await createProject({variables:{userId: user.id, newTemplate: true}})
            if ((projects.length - limit) === 0) {
              // Number of projects becomes more than the limit -> Fetch more projects
              setLimit(limit+10)
            } else {
              projectsRefetch();
            }
            setExpandAddButton(false);
          }}> <span className="ion-plus"></span><span style={{marginBottom: 4, marginLeft: 6}}>Template</span></div>}
          <div className="button" onClick={()=>{
            setExpandAddButton(false);
            setShowTemplateModal(true);

          }}> <span className="ion-plus"></span><span style={{marginBottom: 4, marginLeft: 6}}>Ad from template ...</span></div>
        </>}</div></div></div>}
        {deleteDialog && <ConfirmDialog 
          type = "project"
          onConfirm={async ()=>{
            await deleteProject({variables:{projectId: deleteDialog}})
            setDeleteDialog(null)
            projectsRefetch();
          }} 
          onCancel={()=>{
            setDeleteDialog(null)
          }} 
        />}
        {deleteTemplateDialog && <ConfirmDialog 
          type = "template"
          onConfirm={async ()=>{
            await deleteProject({variables:{projectId: deleteTemplateDialog}})
            setDeleteTemplateDialog(null)
            projectsRefetch();
          }} 
          onCancel={()=>{
            setDeleteTemplateDialog(null)
          }} 
        />}
        {duplicateDialog && <ConfirmDialog 
          type = "duplicate"
          onConfirm={async ()=>{
            await duplicateProject({variables:{projectId: duplicateDialog}})
            setDuplicateDialog(null)
            projectsRefetch();
          }} 
          onCancel={()=>{
            setDuplicateDialog(null)
          }} 
        />}
        {duplicateTemplateDialog && <ConfirmDialog 
          type = "duplicateTemplate"
          onConfirm={async ()=>{
            await duplicateProject({variables:{projectId: duplicateTemplateDialog, template: true}})
            setDuplicateTemplateDialog(null)
            projectsRefetch();
          }} 
          onCancel={()=>{
            setDuplicateTemplateDialog(null)
          }} 
        />}
        {duplicateAsTemplateDialog && <ConfirmDialog 
          type = "duplicateAsTemplate"
          onConfirm={async ()=>{
            await duplicateProject({variables:{projectId: duplicateAsTemplateDialog, toTemplate: true}})
            setDuplicateAsTemplateDialog(null)
            projectsRefetch();
          }} 
          onCancel={()=>{
            setDuplicateAsTemplateDialog(null)
          }} 
        />}
        {lockedDialog && <ConfirmDialog 
          type = "locked"
          name = {lockedDialog}
          onCancel={async ()=>{
            setLockedDialog(null)
          }}
        />}
        {addProjectDialog && <ConfirmDialog 
          type = "create_ad"
          name = {addProjectDialog}
          onCancel={async ()=>{
            setAddprojectDialog(null)
          }}
          onConfirm={async ()=>{
            setAddprojectDialog(null)
            await createProject({variables:{userId: user.id}})
            if ((projects.length - limit) === 0) {
              // Number of projects becomes more than the limit -> Fetch more projects
              setLimit(limit+10)
            } else {
              projectsRefetch();
            }
          }}
        />}
        {!done && <>
        <div style={{width: width, transition: 'all 1s', position: 'relative'}}>
              {userData && <FilterTags />}
              {projects.map((project:any, index:any) => {
                const cache = inflateData(project.cache);
                let url = "";
                let objectTitle = "";
                if (cache["0_1_item_1"] && cache["0_1_item_1"].url) {
                  const imageRequest = JSON.stringify({
                    bucket: "husfoto-exposure",
                    key: cache["0_1_item_1"].url.replace("https://husfoto-exposure.s3.eu-north-1.amazonaws.com/",""),
                    edits: {
                      resize: {
                        width: 300,
                        fit: "inside"
                      }
                    }
                  });
                  url = `${CloudFrontUrl}/${btoa(imageRequest)}`;
                }
                if (cache["0_1_text_1"] && cache["0_1_text_1"].text !== "__DELETED__") {
                  objectTitle = cache["0_1_text_1"].text
                }
              return <React.Fragment key={index}>
                <div id={project.id} className={!comboUser ? "all project-layout-container" : "project-layout-container"}>
                  {project.template && <div className="template-label">TEMPLATE</div>}
                  <div className="project-item" 
                  style={{
                    backgroundSize: "cover", 
                    backgroundImage:'url("'+url+'")',
                    backgroundPosition: 'center'
                  }}
                  onClick={()=>{
                    if (project.lastAccessed && moment(project.lastAccessed) > moment(fetchDate).subtract(1, "minute").subtract(1, "second")) {
                      setLockedDialog(project.lastAccessedBy)
                    } else {
                      setDone(true);
                      let filter = {filterAccount: getLocalstorageValue("filterAccount"), filterDivision: getLocalstorageValue("filterDivision"), filterUser: getLocalstorageValue("filterUser"), limit: getLocalstorageValue("limit"), projectId: project.id}
                      localStorage.setItem("vf-modal-state", JSON.stringify(filter));
                      setTimeout(()=>{
                        props.closeProjectModal();
                        window.location.href = '/builder/'+project.id; //relative to domain
                      },1000)
                    }

                  }}> 
                    <div style={{width: 90}}>
                      <Logo style={{fill: url ? 'transparent' : 'white'}} />
                    </div>
                  </div>
                  {project.status === "BUILT" && <div className="published" style={{cursor: 'pointer'}} onClick={()=>{
                    window.open('/'+project.id,'_blank');
                  }}>
                    <div className="ion-eye"></div>
                    {<div style={{fontSize: 12, fontWeight: 100, marginLeft: 8}}>{formatDate(project.lastPublished)}</div>}
                  </div>}
                  { project.lastAccessed && moment(project.lastAccessed) > moment(fetchDate).subtract(1, "minute").subtract(1, "second") && <div className="locked" style={{cursor: 'pointer'}} onClick={()=>{
                    setLockedDialog(project.lastAccessedBy)
                  }}>
                    <div className="ion-ios-locked"></div>
                  </div>}
                  {!comboUser && <div className="project-owner-container"><span style={{fontSize: 16, marginRight: 6}} className="ion-ios-person"></span>{project.user && project.user.name}</div>}
                  <div className="project-card-footer">
                    <div>
                      <div style={{color: '#bbb', fontSize: 14}}>{project.name}</div>
                      <div style={{fontSize: 12, fontWeight: 500, fontVariant: 'all-small-caps'}}>{objectTitle}</div>
                    </div>
                    <div style={{display: 'flex', position: 'relative'}}>
                      {(user && user.permissions.includes("DuplicateAds") && comboUser && user.id === comboUser.id) && <div className="footer-button" title="Duplicate" onClick={async (e)=>{
                        if (!project.template && (user && user.permissions.includes("CreateTemplates"))) {
                          if (document.getElementById(project.id+"-duplicate")?.classList.contains("visible")) {
                            document.getElementById(project.id+"-duplicate")?.classList.remove("visible");
                          } else {
                            document.getElementById(project.id+"-duplicate")?.classList.add("visible");
                          }
                        } else if (project.template) {
                          setDuplicateTemplateDialog(project.id)
                        } else {
                          setDuplicateDialog(project.id)
                        }
                      }}>
                        <BiDuplicate title="Duplicate" />
                        {!project.template && (user && user.permissions.includes("CreateTemplates")) && 
                          <span className="duplicate-arrow-down">
                            <MdKeyboardArrowDown />
                          </span>}
                      </div>}
                      <div id={project.id+"-duplicate"} className={`duplicate-arrow-pop-up`}>
                        <div className="option" onClick={()=>{
                          setDuplicateDialog(project.id)
                          document.getElementById(project.id+"-duplicate")?.classList.remove("visible");
                        }}>Duplicate</div>
                        <div className="option" onClick={()=>{
                          setDuplicateAsTemplateDialog(project.id)
                          document.getElementById(project.id+"-duplicate")?.classList.remove("visible");
                        }}>Duplicate as template</div>
                      </div>
                      <div style={{marginLeft: 10}} className="footer-button" title="Delete" onClick={async (e)=>{
                        if (project.template) {
                          setDeleteTemplateDialog(project.id)
                        } else {
                          setDeleteDialog(project.id) 
                        }
                      }}><IoMdTrash title="Delete" /></div>
                    </div>
                  </div>
                </div></React.Fragment>
              })}
              {showScrollTop && <div style={{
                display:'flex', 
                justifyContent: 'center', 
                marginTop: 50,
                height: 200,
                cursor: 'pointer'
              }} onClick={()=>{if(containerRef) containerRef.current.scrollTop = 0}}>
                <div className="scroll-top-container"><span>To top</span> <span style={{fontSize:20, verticalAlign: 'top', marginLeft: 10}} className="ion-arrow-up-b"></span></div>
              </div>}
          </div>
        </>
        }
      </div>
    </div>
    </>
  );
}

export default ProjectModal;
