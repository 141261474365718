import React, { useEffect, useState, useRef } from "react";
import "./Slider.css";
import "../Image/Image.css";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_SLIDER } from "../graphql/queries";
import { UPDATE_GRID_SLIDER, UPDATE_GRID_SLIDER_IMAGE, DELETE_GRID_SLIDER_IMAGE } from "../graphql/mutations";
import { useParams } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function Slider(props: any) {
  let { projectId } = useParams<any>();
  const sliderEl = useRef(null);
  const containerEl = useRef(null);
  const [toolbarExpanded, setToolbarExpanded] = useState(false);
  const [startScale, setStartScale] = useState(1);
  const [startTranslateX, setStartTranslateX] = useState(0);
  const [startTranslateY, setStartTranslateY] = useState(0);
  const { gridId, position} = props;
  const [images, setImages] = useState<any>([]);
  const [currentImageUrl, setCurrentImageUrl] = useState("none");
  const { data, loading, refetch } = useQuery(GET_SLIDER, {variables: {itemId: gridId+"_item_"+position}})
  const [updateGridSlider] = useMutation(UPDATE_GRID_SLIDER);
  const [updateGridSliderImage] = useMutation(UPDATE_GRID_SLIDER_IMAGE);
  const [deleteGridSliderImage] = useMutation(DELETE_GRID_SLIDER_IMAGE);
  const [height, setHeight] = useState(1);
  const [width, setWidth] = useState(1);
  const [slideIndex, setSlideIndex] = useState(0);
  let prevScale = 1;
  let prevX = 0;
  let prevY = 0;

  useEffect(()=>{
    const handleResize = () => {
      // Update width and height state (so that the relative translate stays correct)
      const sliderContainer:any = sliderEl.current;
      if (sliderContainer) {
        setWidth(sliderContainer.clientWidth);
        setHeight(sliderContainer.clientHeight);
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize();
  }, [])


  if (data && data.slider && data.slider.images && data.slider.images != images) {
    setImages(data.slider.images)
  }

  
  function dragOver(ev:any) {
    let el:any = containerEl.current;
    if (el) {
      el.style.boxShadow = "inset 0px 0px 0px 1px #3b3b3b";
    }
    
    ev.preventDefault();
  }
  function dragLeave(ev:any) {
    let el:any = containerEl.current;
    if (el) {
      el.style.boxShadow = 'none';
    }
    ev.preventDefault();
  }

  function drop(ev:any) {
    let el:any = containerEl.current;
    if (el) {
      el.style.boxShadow = 'none';
    }
    ev.preventDefault();
    var transferData = ev.dataTransfer.getData("img");
    if (transferData) {
      const imgUrl = projectId+"/img/"+transferData;
      updateGridSlider({variables:{gridId: gridId, position: position, index: slideIndex, url: imgUrl}})
    }
  }

  useEffect(()=>{
    var slides = images;
    if (slideIndex > slides.length) {setSlideIndex(0)}
    if (slideIndex < 0) {setSlideIndex(slides.length)}
  }, [slideIndex])

  useEffect(()=>{
    if (sliderEl.current && images[slideIndex]) {
      let startFrame = images[slideIndex].startFrame;
      setStartTranslateX(startFrame.translateX);
      setStartTranslateY(startFrame.translateY);
      setStartScale(startFrame.scale);

      //@ts-ignore    
      const containerWidth = sliderEl.current.clientWidth;
      const CloudFrontUrl = "https://dkj4aap2z9ncw.cloudfront.net"
      let scaleCompensate = startFrame.scale;
      let resizeWidth = parseInt((containerWidth*scaleCompensate).toString());
      if (resizeWidth > 1920) {
        // Avoid AWS lambda limit of 6MB
        resizeWidth = 1920;
      }
      const imageRequest = JSON.stringify({
        bucket: "husfoto-exposure",
        key: images[slideIndex].url,
        edits: {
          resize: {
            width: resizeWidth,
            fit: "inside"
          }
        }
      });
      setCurrentImageUrl(`${CloudFrontUrl}/${btoa(imageRequest)}`);
    } else {
      setCurrentImageUrl("none");
    }
  }, [slideIndex, images])

  function resetState() {
    setStartTranslateX(0);
    setStartTranslateY(0);
    setStartScale(1);
    updateGridSliderImage({variables:{
      itemId: gridId+"_item_"+position, 
      index: slideIndex, 
      scale: 1, 
      translateX: 0, 
      translateY: 0
    }})
  }

  function updateCache() {
    setStartTranslateX(prevX);
    setStartTranslateY(prevY);
    setStartScale(prevScale);
    updateGridSliderImage({variables:{
      itemId: gridId+"_item_"+position, 
      index: slideIndex, 
      scale: prevScale, 
      translateX: prevX, 
      translateY: prevY
    }})
  }

  return (
    <div ref={sliderEl} className="slider" style={{cursor: toolbarExpanded ? "all-scroll" : "auto"}}>
      {!toolbarExpanded && <>
        <div onDrop={(e)=>{drop(e)}} onDragOver={(e)=>{dragOver(e)}} onDragLeave={(e)=>{dragLeave(e)}} className="drop-container" style={{height: '100%', marginTop: 0, zIndex: 1}}>
          <div 
            className="slide bg-img" 
            style={{
              backgroundImage: `url("${currentImageUrl}")`,
              transform: "translate("+startTranslateX+"%, "+startTranslateY+"%"+")"+" scale("+startScale+")",
              backgroundColor: currentImageUrl === "none" ? '#eee' : 'transparent',
              backgroundSize: "contain", 
              transformOrigin: "0% 0%"
            }}
            ref={containerEl}
          >
            {/* {!images[slideIndex] && <div style={{display:'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}><div className="heading-3 drop-area">DROP IMAGE HERE</div></div>} */}
          </div>
        </div>
        <a className="prev slider-control" onClick={()=>{setSlideIndex(slideIndex-1)}}><i className="ion-chevron-left"></i></a>
        <a className="next slider-control" onClick={()=>{setSlideIndex(slideIndex+1)}}><i className="ion-chevron-right"></i></a>
      </>}
      {toolbarExpanded && <TransformWrapper
        scale={startScale}
        positionX={(startTranslateX/100)*width}
        positionY={(startTranslateY/100)*height}
        defaultScale={startScale}
        defaultPositionX={(startTranslateX/100)*width}
        defaultPositionY={(startTranslateY/100)*height}
        options={{
          limitToBounds: false,
          minScale:0.1
        }}
        wheel={{
          step: width/height > 1.6 ? 30 : 1
        }}
      >
      {({ scale, positionX, positionY }:any) => {
          if (scale !== prevScale) {
            prevScale = scale;
          } 
          if (positionX !== prevX) {
            prevX = (positionX/width)*100;
          }
          if (positionY !== prevY) {
            prevY = (positionY/height)*100;
  
          }
        return <React.Fragment>
          <TransformComponent>
            {toolbarExpanded && <img src={currentImageUrl} alt="test" className="transform-img" />}            
          </TransformComponent>
        </React.Fragment>
      }}
    </TransformWrapper>}
        
        
        {images[slideIndex] && <div className="settings-container" style={toolbarExpanded ? { width: 180, overflowX: 'auto', height: 85} : { width: 50, overflowX: 'hidden', height:50 }}>
        <div style={{display: 'flex', height: toolbarExpanded ? '50%' : '100%'}}>
          <div style={{position: 'relative'}} className={toolbarExpanded ? "ion-checkmark settings-icon expanded" : "ion-android-settings settings-icon"} onClick={()=>{
              if (toolbarExpanded) {
                updateCache();
              }
              setToolbarExpanded(!toolbarExpanded)
            }}>{!toolbarExpanded && startTranslateX ? 
              <div style={{position:'absolute', color:'#a0ffa0', top:10, left: 10, fontSize: 16}} 
              className="ion-checkmark-circled settings-icon"></div> : ""}</div>
          <div className={"ion-ios-skipbackward settings-icon"}></div>
            <div className={"ion-ios-undo-outline settings-icon"} onClick={()=>{
              resetState();
            }}></div>
            <div className={"ion-trash-b settings-icon"} onClick={()=>{
              setToolbarExpanded(false)
              deleteGridSliderImage({variables:{
                itemId: gridId+"_item_"+position, 
                index: slideIndex
              }})
            }}></div>
        </div>
        {toolbarExpanded && <div className="settings-text" style={{width: 180}}>{"Editing position"}</div>}
      </div>}
    </div>
  );
}

export default Slider;
