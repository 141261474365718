import { useQuery } from '@apollo/react-hooks';
import { MenuItem, TextField } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { BiArchiveIn, BiMailSend } from 'react-icons/bi';
import { IoMdClose, IoMdCheckmark } from 'react-icons/io';
import { IoMdAdd } from 'react-icons/io';
import { GET_ACCOUNT_USERS, GET_ALL_USER_DIVISION_ROLES, GET_DIVISION, GET_DIVISIONS, GET_DIVISION_USERS, GET_PERMISSIONS, GET_ROLES, GET_USER, GET_USER_DIVISION_ROLES } from '../graphql/queries';
import './DataTable.css'
import DataTableRow from '../DataTableRow/DataTableRow'
import DataTableRoleRow from '../DataTableRoleRow/DataTableRoleRow'
import DataTablePermissionRow from '../DataTablePermissionRow/DataTablePermissionRow'
import { borderRadius } from '@material-ui/system';

function DataTable(props:any) {
  
  const { data:userData} = useQuery(GET_USER, {fetchPolicy: 'network-only'});

  const [users, setUsers] = useState<any>([])
  const [editingNewUser, setEditingNewUser] = useState(false)
  const [allRoles, setAllRoles] = useState(null)
  const [allUsers, setAllUsers] = useState(null)
  const [allDivisions, setAllDivisions] = useState(null)
  const [allPermissions, setAllPermissions] = useState(null)
  const [categoriesColspan, setCategoriesColspan] = useState([])

  const { division, account, usersTable, rolesTable, permissionsTable, tableWidth, refetchDivisionCallback} = props;

  const { data: divisionUsersData, loading:divisionUsersLoading,  refetch:divisionUsersRefetch } = useQuery(GET_DIVISION_USERS, {variables:{divisionId: division}, skip: !usersTable || !division, fetchPolicy: 'no-cache'});
  const { data: accountUsersData, loading:accountUsersLoading, refetch:accountUsersRefetch } = useQuery(GET_ACCOUNT_USERS, {variables:{accountId: account.id}, skip: !usersTable || division, fetchPolicy: 'no-cache'});

  const { data: userDivisionRolesData, loading:userDivisionRolesLoading, refetch:userDivisionRolesRefetch } = useQuery(
    GET_USER_DIVISION_ROLES, 
    {variables:{divisionId: division}, skip: (!rolesTable && !permissionsTable) || !division, fetchPolicy: 'no-cache'}
  );
  const { data: allUserDivisionRolesData, loading:allUserDivisionRolesLoading, refetch:allUserDivisionRolesRefetch } = useQuery(
    GET_ALL_USER_DIVISION_ROLES, 
    {variables:{accountId: account.id}, skip: (!rolesTable && !permissionsTable) || division, fetchPolicy: 'no-cache'}
  );

  const { data: permissionsData, loading: permissionsLoading } = useQuery(GET_PERMISSIONS, {fetchPolicy: 'no-cache'});
  const { data: rolesData, loading: rolesLoading } = useQuery(GET_ROLES, {fetchPolicy: 'no-cache'});
  const { data: divisionsData, loading: divisionsLoading } = useQuery(GET_DIVISIONS, {
    variables: {accountId: account.id},
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    setEditingNewUser(false);
  }, [division]);

  useEffect(() => {
    if (rolesData) {
      let roles = rolesData.roles.filter((option: any)=>{
        if (!option.name.includes("Creator")) return true
      })
      setAllRoles(roles)
    }
  }, [rolesData])

  useEffect(() => {
    if (divisionsData) {
      let divisions = [];
      const recursive = (division) => {
        divisions.push({id:division.id, name:division.name})
        if (!division.divisions) return;
        division.divisions.forEach(division => {
          recursive(division)
        });
      }
      recursive(divisionsData.divisions[0])
      setAllDivisions(divisions)
    }
  }, [divisionsData])


useEffect(() => {
  if (permissionsData) {
    
    let permissions = permissionsData.permissions;
    setAllPermissions(permissions)


    let currentCategory = permissions[0].category
    let lastIndex = 0;
    let colSpans = [];
    permissions.forEach((permission, index)=>{
      if (permission.category !== currentCategory) {
        colSpans.push({name: currentCategory, span: index - lastIndex})
        currentCategory = permission.category;
        lastIndex = index;
      }
      if (index === permissions.length-1) {
        colSpans.push({name: currentCategory, span: (index+1) - lastIndex})
      }
    })
    setCategoriesColspan(colSpans)
  }
}, [permissionsData])


  const { data: usersData } = useQuery(GET_ACCOUNT_USERS, {variables:{accountId: account.id}, fetchPolicy: 'no-cache'});
  useEffect(() => {
    if (usersData) {
      // Set all available users for account (used in DataTableRolesRow)
      setAllUsers(usersData.accountUsers)
    }
  }, [usersData])

  useEffect(() => {
    if (divisionUsersData && usersTable) {
      setUsers(divisionUsersData.divisionUsers)
    }
  }, [divisionUsersData])

  useEffect(() => {
    if (accountUsersData && usersTable) {
      setUsers(accountUsersData.accountUsers)
    }
  }, [accountUsersData])

  useEffect(() => {
    if (userDivisionRolesData && rolesTable) {
      setUsers(userDivisionRolesData.userDivisionRoles)
    }
  }, [userDivisionRolesData])

  useEffect(() => {
    if (allUserDivisionRolesData && (rolesTable || permissionsTable)) {
      setUsers(allUserDivisionRolesData.allUserDivisionRoles);
    }
  }, [allUserDivisionRolesData])

  useEffect(() => {
    if (userDivisionRolesData && permissionsTable) {
      const usersWithPermissions = userDivisionRolesData.userDivisionRoles.map((user)=>{
        const roles = user.roles;
        let permissions = [];
        roles.forEach(role => {
          permissions.push(...role.permissions)
        });
        return {id: user.id, name: user.name, permissions: permissions}
      })
      setUsers(usersWithPermissions)
    }
  }, [userDivisionRolesData])

  const loading = divisionUsersLoading || accountUsersLoading || userDivisionRolesLoading || allUserDivisionRolesLoading ||
  permissionsLoading || rolesLoading || divisionsLoading;

  const canEdit = userData && userData.user.permissions.includes("EditUsers");

  return (
    <>
      {usersTable && <><div className="table-container tableFixHead">
        <table style={{width: '100%', borderCollapse: 'collapse'}}>
          <thead>
            <tr>
              <th scope="col" style={{textAlign: 'left', padding: 10}}>
                Name
              </th>
              <th scope="col" style={{textAlign: 'left', padding: 10}}>
                Email
              </th>
              {!division && <th scope="col" style={{textAlign: 'left', padding: 10}}>
                Division
              </th>}
              <th scope="col" style={{textAlign: 'left', padding: 10}}>
                Status
              </th>
              <th scope="col">
                {/* Mail */}
              </th>
            </tr>
          </thead>
          {!loading && <tbody style={{backgroundColor: 'white'}}>
            {allDivisions && users.map((user:any, index:number)=>{
              return <DataTableRow key={division ? "division_"+user.id : "overview_"+user.id} user={user} division={division} 
              refetch={()=>{
                if (division) {
                  divisionUsersRefetch();
                  // For refetching "deleteable" for division
                  refetchDivisionCallback();
                } else {
                  // Overview
                  accountUsersRefetch();
                }
              }}
              newRowAdded={()=>{
                setEditingNewUser(false);
              }} removeNewRow={()=>{
                if (divisionUsersData) {
                  setUsers(divisionUsersData.divisionUsers)
                } else if (accountUsersData) {
                  setUsers(accountUsersData.accountUsers)
                }
                setEditingNewUser(false);
              }} 
              allDivisions={allDivisions}
              editable={canEdit}
              />
            })}
          </tbody>}
        </table>
      </div>
      {userData && userData.user.permissions.includes("CreateUsers") && 
        <div className={`add-row ${editingNewUser && "disabled"}`} onClick={()=>{
            if (!editingNewUser) {
              setEditingNewUser(true);
              setUsers([...users, {name: null, email: null, disabled: false}])
            }
          }}>
          <div style={{
            display: 'flex', 
            justifyContent:'center', 
            alignItems: 'center',
            opacity: editingNewUser ? 0.2 : 1,
            transition: 'all 0.5s'
          }}>
            <IoMdAdd />
            <div style={{marginBottom:4, marginLeft:4}}>Add user</div>
          </div>
        </div>
      }
      </>}

      {rolesTable && <><div className="table-container tableFixHead">
        <table style={{width: '100%', borderCollapse: 'collapse'}}>
          <thead>
            <tr>
              {!division && <th scope="col" style={{textAlign: 'left', padding: 10}}>
                {/* Expand button */}
              </th>}
              <th scope="col" style={{textAlign: 'left', padding: 10}}>
                Name
              </th>
              <th scope="col" style={{textAlign: 'left', padding: 10}}>
                Roles
              </th>
              <th scope="col">
                {/* Delete */}
              </th>
            </tr>
          </thead>
          {!loading && <tbody style={{backgroundColor: 'white'}}>
            {users.map((user:any, index:number)=>{
              return <DataTableRoleRow 
              key={division ? "division_"+user.id : "overview_"+user.id} 
              user={user} 
              allDivisions={allDivisions}
              allRoles={allRoles}
              allUsers={allUsers}
              division={division} 
              refetch={()=>{
                if (division) {
                  userDivisionRolesRefetch();
                  // For refetching "deleteable" for division
                  refetchDivisionCallback();
                } else {
                  allUserDivisionRolesRefetch();
                }
              }}
              newRowAdded={()=>{
                if (division) {
                  setEditingNewUser(false);
                }
              }} 
              removeNewRow={()=>{
                if (division) {
                  if (userDivisionRolesData) {
                    setUsers(userDivisionRolesData.userDivisionRoles)
                  } else if (allUserDivisionRolesData) {
                    setUsers(allUserDivisionRolesData.allUserDivisionRoles)
                  }
                  setEditingNewUser(false);
                }
              }} 
              editable={canEdit}
              />
            })}
          </tbody>}
        </table>
      </div>
      {division && userData && userData.user.permissions.includes("EditUsers") && 
        <div className={`add-row ${editingNewUser && "disabled"}`} onClick={()=>{
            if (!editingNewUser) {
              setEditingNewUser(true);
              setUsers([...users, {name: null, roles: []}])
            }
          }}>
          <div style={{
            display: 'flex', 
            justifyContent:'center', 
            alignItems: 'center',
            opacity: editingNewUser ? 0.2 : 1,
            transition: 'all 0.5s'
          }}>
            <IoMdAdd />
            <div style={{marginBottom:4, marginLeft:4}}>Add role</div>
          </div>
        </div>
      }
      </>}

      {permissionsTable && <div 
        className="table-container tableFixHead permissions-table"
        style={{
          maxWidth: tableWidth+'px',
          transition: 'all 1s',
          overflowX: 'scroll'
        }}>
        <table style={{width: '100%', borderCollapse: 'collapse'}}>
          <thead>
            <tr className="permission-categories">
              <th style={{border: 'none'}}></th>
              {!division && <th style={{border: 'none'}}></th>}
              {categoriesColspan.map((category, index)=>{
                  return <th key={"category_"+index} colSpan={category.span}>{category.name}</th>
                })
                }
            </tr>
            <tr className="permissions">
              {!division && <th scope="col" style={{textAlign: 'left', padding: 10}}>
                {/* Expand button */}
              </th>}
              <th scope="col" style={{textAlign: 'left', padding: 10, width: 150, verticalAlign: 'bottom'}}>
                Name
              </th>
              {allPermissions && allPermissions.map((permission, index)=>{
                return <th key={"permission_"+index} scope="col" style={{
                  textAlign: 'left', 
                  padding: 10,
                  // border: '1px solid #ddd', 
                  borderBottom: 'none',
                  borderTop: 'none'
                }}>
                  <div className="text-vertical">{permission.action}</div>
                </th>
              })
              }
            </tr>
          </thead>
          {!loading && <tbody style={{backgroundColor: 'white'}}>
            {users.map((user:any, index:number)=>{
              return <DataTablePermissionRow 
              key={division ? "division_"+user.id : "overview_"+user.id} 
              allDivisions={allDivisions}
              allPermissions={allPermissions}
              user={user} 
              division={division} 
              />
            })}
          </tbody>}
        </table>
      </div>}

    </>
  )

}

export default DataTable