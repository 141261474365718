import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import {
  AiFillPauseCircle,
  AiFillPlayCircle,
  AiFillStepBackward,
  AiFillStepForward,
} from "react-icons/ai";

type Props = {
  play: boolean;
  setPlay: Function;
  currentFrame: number;
  setCurrentFrame: Function;
  totalFps: number;
};

function Controls(props: Props) {
  const { play, setPlay, currentFrame, setCurrentFrame, totalFps } = props;
  return (
    <motion.div
      id="controls-container"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        padding: "20px 0px 20px 0px",
        // backgroundColor: "#2F2F2F",
        zIndex: 30,
      }}
      layoutId={"controls"}
    >
      <AiFillStepBackward
        style={{ color: "white", fontSize: 22, cursor: "pointer" }}
        onClick={(e) => {
          e.stopPropagation();
          setPlay(false);
          if (currentFrame > 0) {
            setCurrentFrame(currentFrame - 1);
          }
        }}
      />
      {!play && (
        <AiFillPlayCircle
          style={{
            color: "white",
            fontSize: 34,
            marginLeft: 10,
            marginRight: 10,
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setPlay(true);
          }}
        />
      )}
      {play && (
        <AiFillPauseCircle
          style={{
            color: "white",
            fontSize: 34,
            marginLeft: 10,
            marginRight: 10,
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setPlay(false);
          }}
        />
      )}
      <AiFillStepForward
        style={{ color: "white", fontSize: 22, cursor: "pointer" }}
        onClick={(e) => {
          e.stopPropagation();
          setPlay(false);
          if (currentFrame < totalFps) {
            setCurrentFrame(currentFrame + 1);
          }
        }}
      />
    </motion.div>
  );
}
export default Controls;
