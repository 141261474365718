import React, { useState, useEffect, useRef } from 'react';
import './Login.css'
import { TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { LOGIN, RECOVER_PASSWORD } from './graphql/mutations';
import { GET_USER } from './graphql/queries';
import {ReactComponent as Logo}  from './icon-mask-white.svg';
import {ReactComponent as LogoText}  from './logo.svg';
import { BsBoxArrowInRight } from 'react-icons/bs'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '40%'
      }
    },
  }),
);

export default function Login() {
  const classes = useStyles();
  let history = useHistory();
  const [loggedIn, setLoggedIn] = useState(false)
  const [email, setEmail] = useState('');
  const [emailComplete, setEmailComplete] = useState(false);
  const [emailFound, setEmailFound] = useState(false);
  const [password, setPassword] = useState('');
  const [responseError, setResponseError] = useState('');
  const [showRecover, setShowRecover] = useState(false);
  const [recoverEmailSent, setRecoverEmailSent] = useState(false);

  const [bgIndex] = useState(1)
  const [ login ] = useMutation(LOGIN);
  const [ recover ] = useMutation(RECOVER_PASSWORD);
  
  const emailRef = useRef<any>(null)
  const loginRef = useRef<any>(null)
  const passwordRef = useRef<any>(null)

  let client = useApolloClient();

  useEffect(() => {
    client.query({query: GET_USER, fetchPolicy: 'network-only'})
    .then((res)=>{
      if (res.data) {
        // Already logged in: redirect to builder
        setLoggedIn(true);
        setTimeout(()=>{
          history.push({
            pathname: '/builder' 
          })
        }, 1000)
      }
    })
    .catch((err)=>{
      // Not logged in: do nothing
    })
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case "email":
        setEmail(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (email && email.match(/^\S+@\S+\.\S+$/)) {
      setEmailComplete(true);
    } else {
      setEmailComplete(false);
    }
  }, [email])
  

  return <div style={{height:'100vh'}}>
  <div className="login-nav">
    <div className="login-logo-text"><LogoText style={{width: '12vw', minWidth: 150}} /></div>
  </div>
  <div className="login-container" style={{
    backgroundColor: loggedIn ? '#232323' : 'white',
    bottom: loggedIn ? 0 : 60,
    height: loggedIn ? '100%' : 'calc(100% - 10vh)'
  }}>
    {!loggedIn && <>
      <div className="login-logo-container" style={{
        backgroundImage:  process.env.NODE_ENV === 'production' ?
        'url("/build/img/bg'+bgIndex+'.jpg")' :
        'url("/img/bg'+bgIndex+'.jpg")',
        filter: 'opacity(0.5)',
      }}>
        <div>
          <Logo style={{
            fill: loggedIn ? '#232323' : 'white', 
            height: '110%', 
            width: '110%', 
            marginLeft: '-5%',
            marginTop: '-5%'
          }} />
        </div>
      </div>
    </>}


    {!loggedIn && <div className="login-input-container">
    <form className={classes.root} style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
      }} noValidate autoComplete="off">
      {!emailFound && <TextField error={!!responseError} onKeyDown={(e)=>{
        if (e.key === 'Enter') {
          e.preventDefault();
          if (loginRef && loginRef.current) {
            loginRef.current.click();
            if (emailRef && emailRef.current) {
              emailRef.current.blur();
            }
          }
        }
      }} inputRef={emailRef} InputProps={{autoFocus: true}} id="email" autoComplete="email" label="Email" variant="outlined" onChange={handleChange} />}
      {emailFound && <TextField error={!!responseError} onKeyDown={(e)=>{
        if (e.key === 'Enter') {
          e.preventDefault();
          if (loginRef && loginRef.current) {
            loginRef.current.click();
            if (passwordRef && passwordRef.current) {
              passwordRef.current.blur();
            }
          }
        }
      }} inputRef={passwordRef} InputProps={{autoFocus: true, type: 'password'}} id="password" label="Password" variant="outlined" onChange={handleChange} />}

      {<div tabIndex={0} ref={loginRef} className={`login-button ${( (emailComplete && !emailFound) || password) && "active"}`} 
      style={{
        width: 60,
        marginLeft: 0,
        fontSize: 22,
        height: 55,
        borderColor: '#8a8a8a',
        outline: 'none'
      }}
      onClick={async ()=>{

        if (password) {
          localStorage.removeItem("vf-modal-state");
          const { data } = await login({variables:{email: email, password: password}})
          if (data.login.token) {
            localStorage.setItem("jwt-viewfinder", data.login.token);
            setLoggedIn(true);
            setTimeout(()=>{
              history.push({
                pathname: '/builder' 
              })
            }, 1000)
          } else {
            setResponseError('Wrong password');
            setShowRecover(true);
          }
        } else if (email) {
          const { data } = await client.query({query: GET_USER, fetchPolicy: 'network-only', variables: {email: email}});
          if (data.user) {
            if (data.user.account.iss) {
              localStorage.removeItem("vf-modal-state");
              window.location.replace(window.location.href.replace("builder/login","")+`auth/openid`);
            } else {
              setEmailFound(true);
              setResponseError('');
            }
          } else {
            setResponseError('Email address not found');
          }
        }
      }}><BsBoxArrowInRight title="Login" /></div>}
    </form>
    {showRecover && <>
      {!recoverEmailSent && <div className="recovery-link" onClick={async ()=>{
        const { data } = await recover({variables:{email: email}})
        if (data.recoverPassword.success) {
          setRecoverEmailSent(true);
        }
      }}>Forgot password?</div>}
      {recoverEmailSent && <div>Password recovery email sent</div>}
    </>}
    </div>}
  </div>
  </div>
}