import React, { useEffect, useState } from "react";
import "./Map.css";
import InputField from "../InputField/InputField";
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import L from "leaflet";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { UPDATE_GRID_MAP } from "../graphql/mutations";
import { GET_MAP } from "../graphql/queries";
import { MuiThemeProvider, TextField, createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#232323'
    }
  }
});

function Map(props: any) {
  const { gridId, position} = props;

  const [updateGridMap] = useMutation(UPDATE_GRID_MAP)
  const [map, setMap] = useState<any>(null);
  const { data, loading, refetch } = useQuery(GET_MAP, {variables: {itemId: gridId+"_item_"+position}, fetchPolicy: 'no-cache'})
  const [input, setInput] = useState("")

  async function geoCode(address: string, initial?: boolean) {
    const provider = new OpenStreetMapProvider();
    return await provider.search({ query: address }).then(async function(result) {
      await updateGridMap({variables:{gridId: gridId, lat: parseFloat(result[0].y), lng: parseFloat(result[0].x), position: position}});
      let target = L.latLng(parseFloat(result[0].y), parseFloat(result[0].x));
      map.setView(target, 15);
      if (!initial) {
        let marker = L.marker(target, {draggable: true})
        .addTo(map)
        .on('dragend', function() {
            var coord = String(marker.getLatLng());
            let latLng = coord.split('(')[1].split(")")[0].split(",");
            let lat = latLng[0]
            let lng = latLng[1].replace(" ","")
            updateGridMap({variables:{gridId: gridId, lat: parseFloat(lat), lng: parseFloat(lng), position: position}})
            refetch();
        });
      }
      refetch();
    });
  }

  useEffect(()=>{
    if (map) return;
    if (loading) return
    var element = document.getElementById('map-'+gridId+"_"+position);
    if (element) {
      var newMap = L.map(element, {
        scrollWheelZoom: false
      });
      L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          tileSize: 128,
          zoomOffset: 1
      }).addTo(newMap);
      setMap(newMap)
    }
  }, [loading])

  useEffect(()=>{
    if (!map) return;
    map.on('zoomend', function() {
      if (data && data.map && data.map.zoom && data.map.zoom !== map.getZoom()) {
        updateGridMap({variables:{gridId: gridId, position: position, zoom: map.getZoom()}})
      }
    });
    if (!data || !data.map) {
      // No map in cache -> set lat lng from geocoder
      geoCode("Stockholm", true)
    } else {
      // Map exists in cache
      let target = L.latLng(data.map.lat, data.map.lng);
      map.setView(target, data.map.zoom);
      let marker = L.marker(target, {draggable: true})
      .addTo(map)
      .on('dragend', function() {
          var coord = String(marker.getLatLng());
          let latLng = coord.split('(')[1].split(")")[0].split(",");
          let lat = latLng[0]
          let lng = latLng[1].replace(" ","")
          updateGridMap({variables:{gridId: gridId, lat: parseFloat(lat), lng: parseFloat(lng), position: position}})
          refetch();
      });
    }
  }, [map])

  return (
    <>
      <div style={{zIndex: 2, height: '100%'}} id={'map-'+gridId+"_"+position}></div>
      <div className="map-input">
        <MuiThemeProvider theme={theme}>
          <TextField value={input} label="Address" onChange={(event:any)=>{
            setInput(event.target.value)
          }}/>
        </MuiThemeProvider>
        <div className="border-button ion-checkmark" style={{width: 60, height: 40, marginLeft: '5%'}} onClick={()=>{
          geoCode(input)
        }}></div>
      </div>
    </>
  );
}

export default Map;
