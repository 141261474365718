import { useQuery } from '@apollo/react-hooks';
import { MenuItem, TextField } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { BiArchiveIn, BiMailSend } from 'react-icons/bi';
import { IoMdClose, IoMdCheckmark } from 'react-icons/io';
import { IoMdAdd } from 'react-icons/io';
import { GET_DIVISION } from '../graphql/queries';
import './DataInput.css'

function DataInput(props:any) {

  const { label, initValue, onConfirm, disabled, autoFocus } = props

  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState("")

  const inputRef = useRef(null)

  useEffect(() => {
    if (value !== initValue) {
      setValue(initValue ? initValue : "")
      if (inputRef && autoFocus) {
        inputRef.current.focus();
      }
    }
  }, [initValue])

  return <div style={{position: 'relative', display:'flex', width: '100%'}}><TextField
    label={label}
    value={value}
    disabled={disabled}
    autoFocus={autoFocus ? true : false}
    inputRef={inputRef}
    inputProps={{
      style:{fontSize: 24}
    }}
    onKeyDown={(e)=>{
      if (e.key === "Enter") {
        onConfirm(value)
        setEditing(false);
      }
    }}
    onChange={(e)=>{
      setEditing(true);
      setValue(e.target.value)
    }}
  />
  {editing && 
  <div className="confirm-cancel-button" 
    style={{
      position: 'absolute',
      right:10,
      bottom:10
    }}
    onClick={()=>{
      onConfirm(value)
      setEditing(false);
    }}
  ><IoMdCheckmark title="Confirm" /></div>}
</div>

}

export default DataInput