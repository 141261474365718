import gql from "graphql-tag";

export const UPLOAD_IMAGE_TO_BUCKET = gql`
  mutation uploadImgToBucket($videoContentImageIndex: Int!) {
    uploadImgToBucket(videoContentImageIndex: $videoContentImageIndex)
  }
`;

export const SAVE_VIDEO_STATE = gql`
  mutation saveVideoState($videoState: String!) {
    saveVideoState(videoState: $videoState) {
      success
    }
  }
`;

export const EXPORT_VIDEO = gql`
  mutation exportVideo {
    exportVideo {
      success
    }
  }
`;
