import React from 'react';
import './Footer.css';
import InputField from '../InputField/InputField';
import GridItem from '../GridItem/GridItem';
function Footer(props:any) {
  const {gridId } = props;
  return (
    <div id="bottom" className="section">
      <div className="footer-grid">
          <div id="footer-info-container">
              <div id="footer-info-grid">
                  <GridItem className="bg-img broker frame footer-broker-img" gridId={gridId} position={1} />
                  <div id="footer-broker-info">
                        <InputField position={1} type={"text"} gridId={gridId} className="heading-4"/>
                        <InputField position={2} type={"text"} gridId={gridId} className="heading-1 heading-1-special"/>
                  </div>
                  <div id="footer-broker-info-2">
                      <div id="footer-broker-info-2-inner">
                          <InputField position={3} type={"text"} gridId={gridId} className="heading-4 no-margin"/>
                          <div id="footer-broker-mail" className="flexbox">
                              <div className="bg-img mail" style={{backgroundImage: 'url("/build/img/mail.png")'}}></div>
                            <InputField position={4} type={"text"} gridId={gridId} className="text-block text-1"/>
                          </div>
                          <div id="footer-broker-tel" className="flexbox">
                              <div className="bg-img tel" style={{backgroundImage: 'url("/build/img/tel.png")'}}></div>
                              <InputField position={5} type={"text"} gridId={gridId} className="text-block text-1"/>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div id="footer-contact-button" className="link-box">
              <a className="link"><div className="text-block text-3 flexbox"><div>KONTAKT</div></div></a>
          </div>
          <div id="footer-link-top">
              <div className="text-block text-3 flexbox" onClick={()=>{if(window){window.scrollTo(0, 0)}}}><span className="link" style={{cursor:'pointer'}}>TILL TOPPEN</span></div>
          </div> 
      </div>
  </div>
  );
}

export default Footer;