import React, { useEffect, useState } from "react";
import SelectBoxResolution from "./SelectBoxResolution";
import { MetaData, VideoState } from "./Video";

type Props = {
  subNavRef: React.MutableRefObject<any>;
  videoContentMetaData: MetaData;
  videoState: VideoState;
  setVideoState: Function;
  expanded: boolean;
};

function SubNavbar(props: Props) {
  const {
    subNavRef,
    videoContentMetaData,
    videoState,
    setVideoState,
    expanded,
  } = props;

  return (
    <div
      ref={subNavRef}
      style={{
        height: 30,
        backgroundColor: "rgb(79, 79, 79)",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 10,
        position: "relative",
        marginBottom: 20,
      }}
    >
      <div
        style={{
          color: "white",
          marginLeft: 20,
          fontWeight: "500",
          fontSize: 12,
          paddingRight: 20,
        }}
      >
        {videoContentMetaData && videoContentMetaData.address}
      </div>
      <SelectBoxResolution
        videoState={videoState}
        setVideoState={setVideoState}
        expanded={expanded}
      />
    </div>
  );
}

export default SubNavbar;
