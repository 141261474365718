import React, { useContext } from 'react';
import './AddButton.css';
import { ModalContext } from '../context';
import { useMutation } from '@apollo/react-hooks';
import { ADD_SECTION, ADD_HEADER, ADD_FOOTER } from '../graphql/mutations';

export enum AddButtonType {
  "grid",
  "section",
  "header",
  "footer"
}

function AddButton(props:any) {
  const type = props.type;
  const sectionId = props.sectionId;
  const modal = useContext(ModalContext);
  const [mutate] = useMutation(ADD_SECTION);
  const [addheader] = useMutation(ADD_HEADER);
  const [addFooter] = useMutation(ADD_FOOTER);
  return (
    <div className="add-button" style={type === AddButtonType.footer ? {marginBottom: '2vh'} : {}} onClick={() => {
      if (type === AddButtonType.grid) {
        modal.toggle(sectionId);
      } else if (type === AddButtonType.section) {
        mutate();
      } else if (type === AddButtonType.header) {
        addheader();
      } else if (type === AddButtonType.footer) {
        addFooter();
      }
    }}>
      {type === AddButtonType.grid && <div className="icon-plus ion-plus"> <div style={{marginLeft: 10, height: '3vw'}}>Layout</div></div>}
      {type === AddButtonType.section && <div className="icon-plus ion-plus"> <div style={{marginLeft: 10, height: '3vw'}}>Section</div></div>}
      {type === AddButtonType.header && <div className="icon-plus ion-plus"> <div style={{marginLeft: 10, height: '3vw'}}>Header</div></div>}
      {type === AddButtonType.footer && <div className="icon-plus ion-plus"> <div style={{marginLeft: 10, height: '3vw'}}>Footer</div></div>}
    </div>
  );
}

export default AddButton;