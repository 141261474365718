import React, { useEffect, useState } from "react";
import FadeIn from "./FadeIn";
import Zoom from "./Zoom";

export type TextSize = "SMALL" | "MEDIUM";

export type VideoTemplate = {
  name: string;
  textColor: string;
  textBackground: string;
  textSize: TextSize;
  maxSlides: number;
  secondsPerSlide: number;
  logotype: string;
  animation: slideAnimation;
  transition: slideTransition;
  fps: number;
};

export type VideoState = {
  slides: Slide[];
  resolution: Resolution;
};

export type Resolution = "LANDSCAPE" | "PORTRAIT";

export type Slide = {
  type?: "INTRO";
  imageUrl: string;
  thumbnailUrl: string;
  text: string;
  animation: slideAnimation;
  bgPosition?: { x: number; y: number };
};

export type slideAnimation = "NONE" | "ZOOM";
export type slideTransition = "FADE";

export type MetaData = {
  address: string;
  seller: {
    name: string;
    title: string;
    email: string;
    phone: string;
  };
};

export const resolutionDimensions = {
  LANDSCAPE: { width: 1200, height: 800 },
  PORTRAIT: { width: 1080, height: 1920 },
};

type Props = {
  frame: number;
  videoState: VideoState;
  videoTemplate: VideoTemplate;
  previewResolution?: { width: number; height: number };
};

function hexIsLight(color) {
  const hex = color.replace("#", "");
  const c_r = parseInt(hex.substring(0, 0 + 2), 16);
  const c_g = parseInt(hex.substring(2, 2 + 2), 16);
  const c_b = parseInt(hex.substring(4, 4 + 2), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
  return brightness > 155;
}

const VideoSlide = ({ id, imageUrl, bgPosition, imageWidth, imageHeight }) => {
  return (
    <>
      <div
        id={id}
        style={{
          backgroundImage: `url('${imageUrl}')`,
          backgroundPosition: !!bgPosition
            ? `${bgPosition.x}% ${bgPosition.y}%`
            : "50% 50%",
          backgroundSize: "cover",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* {frame} */}
      </div>
    </>
  );
};

type TextProps = {
  text: string;
  videoState: VideoState;
  videoResolution: { width: number; height: number };
  textBackground: string;
  textColor: string;
  textSize: TextSize;
  frame?: number;
  startFrame?: number;
  endFrame?: number;
  showOnTop?: boolean;
};

const Text = (props: TextProps) => {
  const {
    text,
    videoState,
    videoResolution,
    textBackground,
    textColor,
    textSize,
    frame,
    startFrame,
    endFrame,
    showOnTop,
  } = props;
  let scale;
  if (videoState.resolution === "LANDSCAPE") {
    scale = videoResolution.width * 0.0015;
  } else if (videoState.resolution === "PORTRAIT") {
    scale = videoResolution.width * 0.003;
  }

  let width;
  const animateUnderline = frame && startFrame && endFrame;
  if (animateUnderline) {
    let widthDuration = endFrame - startFrame;
    if (frame >= startFrame && frame <= endFrame) {
      width = ((frame - startFrame) / widthDuration) * 100;
    }
  }

  return (
    <div
      style={{
        position: "absolute",
        zIndex: showOnTop ? 2 : 1,
        bottom: videoResolution.height * 0.1,
        transform: `scale(${scale})`,
        transformOrigin: "bottom",
        width: (videoResolution.width * 0.8) / scale,
        maxWidth: (videoResolution.width * 0.8) / scale,
      }}
    >
      <div
        style={{
          color: textColor,
          fontWeight: 600,
          fontSize: textSize === "SMALL" ? 12 : 16,
          textAlign: "center",
          letterSpacing: 1,
          lineHeight: 1.5,
          padding: textSize === "SMALL" ? "10px 20px" : "20px 30px",
          fontFamily: "sans-serif",
          backgroundColor: animateUnderline ? "transparent" : textBackground,
          width: "fit-content",
          margin: "auto",
        }}
      >
        <span
          style={{
            textShadow: animateUnderline
              ? hexIsLight(textColor)
                ? "0px 2px 2px rgb(0 0 0 / 75%)"
                : "0px 2px 2px rgb(255 255 255 / 75%)"
              : "none",
          }}
        >
          {text}
        </span>
        {animateUnderline && (
          <div
            style={{
              backgroundColor: textBackground,
              height: 6,
              marginTop: 10,
              width: `${width}%`,
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

function Video(props: Props) {
  const { frame, videoState, videoTemplate, previewResolution } = props;
  let prevSlidesDuration = 0;
  let nextSlide: Slide = null;
  const slides = videoState.slides;

  const fps = videoTemplate.fps;
  const textColor = videoTemplate.textColor;
  const textBackground = videoTemplate.textBackground;
  const textSize = videoTemplate.textSize;
  const secondsPerSlide = videoTemplate.secondsPerSlide;
  const slideDuration = videoTemplate.secondsPerSlide * fps;

  let currentSlideIndex = 0;
  const currentSlide = slides.find((slide, i) => {
    if (prevSlidesDuration + slideDuration >= frame) {
      if (slides[i + 1]) {
        nextSlide = slides[i + 1];
      }
      currentSlideIndex = i;
      return true;
    } else {
      prevSlidesDuration += slideDuration;
    }
  });
  const prevSlide = slides[currentSlideIndex - 1];
  if (!currentSlide) {
    return <></>;
  }

  let imageWidth;
  let imageHeight;
  let videoResolution = { width: 0, height: 0 };
  let zooming = currentSlide.animation === "ZOOM" ? true : false;
  let nextSlidezooming =
    nextSlide && nextSlide.animation === "ZOOM" ? true : false;

  if (previewResolution) {
    videoResolution = {
      width: previewResolution.width,
      height: previewResolution.height,
    };
    if (previewResolution.width > previewResolution.height) {
      imageWidth = previewResolution.width;
    } else {
      imageHeight = previewResolution.height;
    }
  } else {
    videoResolution = {
      width: resolutionDimensions[videoState.resolution].width,
      height: resolutionDimensions[videoState.resolution].height,
    };
    if (
      resolutionDimensions[videoState.resolution].width >
      resolutionDimensions[videoState.resolution].height
    ) {
      imageWidth = resolutionDimensions[videoState.resolution].width;
    } else {
      imageHeight = resolutionDimensions[videoState.resolution].height;
    }
  }

  if (zooming) {
    imageWidth = imageWidth * 1.2;
    imageHeight = imageHeight * 1.2;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      {/* Transition: Fade */}
      {/* Fades in nextSlide */}
      {nextSlide && (
        <FadeIn
          frame={frame}
          // Start fading in next slide 1/6 of duration before the slide ends
          startFrame={prevSlidesDuration + Math.round(slideDuration * (5 / 6))}
          // Fade until the end of the slide
          endFrame={prevSlidesDuration + slideDuration}
          isNextSlide={true}
        >
          <Zoom
            frame={frame}
            startFrame={
              prevSlidesDuration + Math.round(slideDuration * (5 / 6))
            }
            endFrame={prevSlidesDuration + slideDuration}
            zoomFactor={nextSlidezooming ? secondsPerSlide * (1 / 80) : 0}
          >
            <VideoSlide
              id={"video-slide-" + (currentSlideIndex + 1)}
              imageUrl={nextSlide.imageUrl}
              bgPosition={nextSlide.bgPosition}
              imageHeight={imageHeight}
              imageWidth={imageWidth}
            />
          </Zoom>
        </FadeIn>
      )}

      {/* Default slide */}
      {currentSlide.type !== "INTRO" && (
        <Zoom
          frame={frame}
          startFrame={prevSlidesDuration}
          endFrame={prevSlidesDuration + slideDuration}
          zoomFactor={
            currentSlide.animation === "ZOOM" ? secondsPerSlide * (6 / 80) : 0
          }
          initialZoom={
            currentSlide.animation === "ZOOM"
              ? 1 + secondsPerSlide * (1 / 80)
              : 0
          }
        >
          <VideoSlide
            id={"video-slide-" + currentSlideIndex}
            imageUrl={currentSlide.imageUrl}
            bgPosition={currentSlide.bgPosition}
            imageHeight={imageHeight}
            imageWidth={imageWidth}
          />
        </Zoom>
      )}

      {/* Intro slide */}
      {currentSlide.type === "INTRO" && (
        <FadeIn
          frame={frame}
          startFrame={0}
          endFrame={Math.round(slideDuration * (1 / 6))}
        >
          <Zoom
            frame={frame}
            startFrame={prevSlidesDuration}
            endFrame={prevSlidesDuration + slideDuration}
            zoomFactor={
              currentSlide.animation === "ZOOM" ? secondsPerSlide * (6 / 80) : 0
            }
            initialZoom={
              currentSlide.animation === "ZOOM"
                ? 1 + secondsPerSlide * (1 / 80)
                : 0
            }
          >
            <VideoSlide
              id={"video-slide-" + currentSlideIndex}
              imageUrl={currentSlide.imageUrl}
              bgPosition={currentSlide.bgPosition}
              imageHeight={imageHeight}
              imageWidth={imageWidth}
            />
          </Zoom>
        </FadeIn>
      )}

      {/* TEXT (default) */}
      {currentSlide.text && currentSlide.type !== "INTRO" && (
        <>
          {prevSlide.text !== currentSlide.text || currentSlideIndex === 1 ? (
            // Text not the same as previous text (or is first slide after intro): fade in the text
            <FadeIn
              frame={frame}
              startFrame={
                prevSlidesDuration + Math.round(slideDuration * (1 / 6))
              }
              endFrame={
                prevSlidesDuration + Math.round(slideDuration * (2 / 6))
              }
              // When the next slide has the same text as the current: Keep this slide on top (which will make it not fade out)
              isNextSlide={nextSlide && nextSlide.text === currentSlide.text}
            >
              <Text
                text={currentSlide.text}
                textBackground={textBackground}
                textColor={textColor}
                textSize={textSize}
                videoResolution={videoResolution}
                videoState={videoState}
              />
            </FadeIn>
          ) : (
            // Text the same as previous text: do not fade in the text
            <Text
              text={currentSlide.text}
              textBackground={textBackground}
              textColor={textColor}
              textSize={textSize}
              videoResolution={videoResolution}
              videoState={videoState}
              // When the next slide has the same text as the current: Keep this text on top (which will make it not fade out)
              showOnTop={nextSlide && nextSlide.text === currentSlide.text}
            />
          )}
        </>
      )}

      {/* TEXT (intro) */}
      {currentSlide.text && currentSlide.type === "INTRO" && (
        <FadeIn
          frame={frame}
          startFrame={prevSlidesDuration + Math.round(slideDuration * (1 / 6))}
          endFrame={prevSlidesDuration + Math.round(slideDuration * (2 / 6))}
        >
          <Text
            text={currentSlide.text}
            textBackground={textBackground}
            textColor={textColor}
            textSize={textSize}
            videoResolution={videoResolution}
            videoState={videoState}
            frame={frame}
            startFrame={
              prevSlidesDuration + Math.round(slideDuration * (1 / 6))
            }
            endFrame={prevSlidesDuration + Math.round(slideDuration * (3 / 6))}
          />
        </FadeIn>
      )}
    </div>
  );
}

export default Video;
