import React, { useState, useEffect, useRef } from 'react';
import './Navbar.css';
import Snackbar from '../Snackbar/Snackbar';
import { SAVE_CACHE, BUILD, UNPUBLISH, UPDATE_TEMPLATE_ACCOUNT } from '../graphql/mutations';
import { useMutation, useApolloClient, useQuery } from '@apollo/react-hooks';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { GET_PROJECT, GET_PROJECT_STATUS, GET_PROJECT_PREVIEW_STATUS, GET_SAVED_STATE, GET_USER, GET_ACCOUNTS } from '../graphql/queries';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import InputField from '../InputField/InputField';
import {ReactComponent as Logo}  from '../logo-hor.svg';
import { deflateData, inflateData } from '../compression-utils';
import formatDate from '../formatDate'
import moment from 'moment';
import { IoSettingsSharp } from "react-icons/io5";

function VisibilityOptions(props:any) {
  const { enabled, activeAccounts, templateId, refetchProject } = props;
  const [open, setOpen] = useState(false)
  const { data, loading, refetch } = useQuery(GET_ACCOUNTS);
  const [ updateTemplateAccount ] = useMutation(UPDATE_TEMPLATE_ACCOUNT);
  return <>
  <div className={`navbar-button visibility ${!enabled && 'disabled'}`} 
    onClick={()=>{
      if (enabled) {
        setOpen(!open);
      }
    }} 
    style={{position: 'relative', outline: 'none'}}
    >
    <div className="navbar-button-text visibility">Visibility</div>
    <div className={open ? "ion-ios-arrow-up navbar-options-icon" : "ion-ios-arrow-down navbar-options-icon"}></div>
  </div>
  {open && <div className="options-container visibility">
      {data && data.accounts.map((account:any)=>{
        let visible = activeAccounts && activeAccounts.find((a:any)=>a.id === account.id);
        return <div className={"navbar-button visibility"} style={{marginLeft: 0}} onClick={async ()=>{
            const { data } =  await updateTemplateAccount({variables:{template: templateId, accountId: account.id, disconnect: !!visible}})
            if (data.updateTemplateAccount.success) {
              refetchProject()
              setOpen(false);
            } 
          }}>
          <div className="navbar-button-text visibility">{account.name}</div>
          <div className={`${visible && "ion-eye"}`} style={{width: 16, fontSize: 14}}></div>
        </div>
      })}
    </div>}
  </>
}

function Navbar(props:any) {
  const {toggleDrawer} = props;
  let client = useApolloClient();
  let history = useHistory();
  let { projectId }:any = useParams();
  const [ saveCache ] = useMutation(SAVE_CACHE);
  const [ build ] = useMutation(BUILD);
  const [ unpublish ] = useMutation(UNPUBLISH);
  const [snackbarData, setSnackbarData] = useState({text: "", success:true});
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [openOptions, setOpenOptions] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState("")
  const [projectStatus, setProjectStatus] = useState<any>(null);
  const [projectPreviewStatus, setProjectPreviewStatus] = useState<any>(null);
  const { data: userData, refetch: refetchUserData } = useQuery(GET_USER, {fetchPolicy: 'network-only'});
  const { data:projectData, refetch: refetchProject } = useQuery(GET_PROJECT, {variables:{projectId: projectId}, skip: !projectId, fetchPolicy: 'no-cache'});
  const { data:projectStatusData, refetch: refetchProjectStatus } = useQuery(GET_PROJECT_STATUS, {skip: !projectId, variables:{projectId: projectId}, fetchPolicy: 'network-only', pollInterval: projectId && (projectStatus === "BUILDING" || projectStatus === "INVALIDATING") ? 5000 : 0});
  const { data:projectPreviewStatusData, refetch: refetchProjectPreviewStatus } = useQuery(GET_PROJECT_PREVIEW_STATUS, {skip: !projectId, variables:{projectId: projectId}, fetchPolicy: 'network-only', pollInterval: projectId && (projectPreviewStatus === "BUILDING") ? 5000 : 0});
  const { data:savedData } = useQuery(GET_SAVED_STATE);
  const [project, setProject] = useState<any>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [showMenu, setShowMenu] = useState<any>(window.innerWidth >= 768)
  const menuEl = useRef(null);
  const location = useLocation();

  const lastSaved = project && project.lastSaved ? moment(project.lastSaved) : moment('2020-01-01');
  const lastPreviewed = project && project.lastPreviewed ? moment(project.lastPreviewed) : moment('2020-01-01');
  const lastPublished = project && project.lastPublished ? moment(project.lastPublished) : moment('2020-01-01');

  const isTemplate = project && project.template;

  const buttonStates = {
    saveButton: savedData && savedData.saved === false,
    previewButton: project && ((moment(lastSaved) > moment(lastPreviewed) && (moment(lastSaved) > moment(lastPublished))) || projectPreviewStatus === "BUILT"),
    buildPreview: project && (moment(lastSaved) > moment(lastPreviewed) && (moment(lastSaved) > moment(lastPublished))),
    preview: projectPreviewStatus === "BUILT",
    previewBuilding: projectPreviewStatus === "BUILDING",
    optionsButton: project && ((moment(lastSaved) > moment(lastPublished) && projectStatus !== "BUILDING" && projectStatus !== "INVALIDATING") || projectStatus === "BUILT"),
    publishButton: project && (moment(lastSaved) > moment(lastPublished)) && projectStatus !== "BUILDING" && projectStatus !== "INVALIDATING",
    update: project && project.lastPublished,
    publishing: projectStatus === "BUILDING" || projectStatus === "INVALIDATING",
    unpublishButton: projectStatus === "BUILT",
    viewButton: projectStatus === "BUILT",
    lastPublishedText: project && project.lastPublished && projectStatus === "BUILT",
    lastPreviewedText: project && project.lastPreviewed && projectPreviewStatus === "BUILT",
    visibilityButton: projectPreviewStatus === "BUILT"
  }

 /* Responsive menu */
  useEffect(()=>{
    const handleResize = () => {
      if (window.innerWidth !== windowWidth) {
        setWindowWidth(window.innerWidth);
        if (window.innerWidth <= 768) {
          setShowMenu(false);
        }
      }
    }
    window.addEventListener('resize', handleResize)
  }, [])

  if (window.innerWidth >= 768 && !showMenu) {
    setShowMenu(true);
  }
  useEffect(()=>{
    if (showMenu && window.innerWidth <= 768) {
      //@ts-ignore
      menuEl.current.focus();
    }
  }, [showMenu])
  
  if (projectData && project !== projectData.project) {
    setProject(projectData.project)
  }

  if (projectStatusData && projectStatus !== projectStatusData.projectStatus) {
    setProjectStatus(projectStatusData.projectStatus)
    if (projectStatusData.projectStatus === "BUILT") {
      // Refetch lastPublished when build is finished
      refetchProject();
    }
  }

  if (projectPreviewStatusData && projectPreviewStatus !== projectPreviewStatusData.projectPreviewStatus) {
    setProjectPreviewStatus(projectPreviewStatusData.projectPreviewStatus)
    if (projectPreviewStatusData.projectPreviewStatus === "BUILT") {
      // Refetch lastPreviewed when build is finished
      refetchProject();
    }
  }

  useEffect(()=>{
    if (openSnackbar) {
      setTimeout(()=>{
        setOpenSnackbar(false);
      }, 4000)
    }
  }, [openSnackbar])

  const getCache = () => {
    //@ts-ignore
    var cache = { ...client.cache.data.data };
    for (var key in cache) {
      // Remove ROOT_MUTATION data to avoid saving saveCache mutation
      // AND remove cached project which contains the cache
      if (key.includes("ROOT_MUTATION") || key === projectId) {
          delete cache[key];
      }
    }
    delete cache.ROOT_QUERY.saved;
    const compressedCache = deflateData(cache);
    return compressedCache;
  }

  return (
    <>
    {showConfirmDialog && <ConfirmDialog 
      type={showConfirmDialog}
      onConfirm={async ()=>{
        if (showConfirmDialog === "save") {
          history.push({
            pathname: '/builder' 
          })
        } else if (showConfirmDialog === "publish" || showConfirmDialog === "update") {
          setSnackbarData({text: buttonStates.update ? 'Updating...' : 'Publishing...', success: true})
          setOpenSnackbar(true);
          const { data } = await build({variables:{projectId: projectId, preview:false}})
          if (data.build.success) {
            refetchProjectStatus();
            refetchProjectPreviewStatus();
            refetchProject();
            setProjectStatus(null)
            setProjectPreviewStatus(null)
          } else {
            setSnackbarData({text: buttonStates.update ? 'Updating failed' : 'Publishing failed', success: false})
            setOpenSnackbar(true);
          }
        } else if (showConfirmDialog === "unpublish") {
          const { data } =  await unpublish({variables:{projectId: projectId}})
          if (data.unpublish.success) {
            refetchProjectStatus();
            refetchProject();
            setProjectStatus(null)
          } else {
            setSnackbarData({text: 'Building failed', success: false})
            setOpenSnackbar(true);
          }
        }
        setShowConfirmDialog("");
      }} 
      onCancel={()=>{
        setShowConfirmDialog("");
      }} 
    />}
    <div className="navbar">
      <div className="navbar-back ion-ios-arrow-back" style={location.pathname === "/builder" || location.pathname === "/builder/" ? {marginLeft: -65} : {}}onClick={()=>{
        if (projectId && buttonStates.saveButton) {
          setShowConfirmDialog("save");
        } else {
          history.goBack();
        }
      }}></div>
      {projectId && <div className="menu-icon ion-android-menu" onClick={()=>{
        toggleDrawer();
      }
      }></div>}
      <div style={{position: 'relative', marginLeft: !projectId ? 60 : 0}}>
        <div style={{width: 180}}>
          <Logo style={{fill: '#eee'}} />
        </div>
        {projectId && <div className="project-name">
          {projectStatusData === "BUILT" && <div className="ion-eye" style={{marginTop: 1, color: '#d7d7d7', marginRight: 10}}></div>}
          {project && <InputField type={"project"}/>}
        </div>}
      </div>
      {windowWidth <= 768 && projectId && <div 
        className="ion-android-more-horizontal navbar-more-icon" 
        onClick={()=>{setShowMenu(!showMenu)}}
      ></div>}
      {showMenu && <div 
        ref={menuEl} 
        className="navbar-buttons-container"
        onBlur={()=>{
          if (windowWidth <= 768) {
            setShowMenu(false);
          }
        }}
        tabIndex={0}
      >
        {projectId && <><div className={buttonStates.saveButton ? "navbar-button" : "navbar-button disabled"} onClick={async ()=>{
          if (buttonStates.saveButton) {
            const cache = getCache();
            const { data } = await saveCache({variables:{projectId: projectId, cache: cache}})
            if (data.saveCache.success) {
              setSnackbarData({text: 'Saved', success: true})
              setOpenSnackbar(true);
              refetchProjectPreviewStatus();
              refetchProject();
            } else {
              setSnackbarData({text: 'Save failed', success: false})
              setOpenSnackbar(true);
            }
          }
        }}>
          <div className="navbar-button-text">Save</div>
        </div>

        <div className={buttonStates.previewButton ? "navbar-button" : "navbar-button disabled"}
          onClick={async ()=>{
            if (buttonStates.preview) window.open('http://viewfinder-static-sites-preview.s3-website.eu-north-1.amazonaws.com/'+projectId,'_blank');
            if (buttonStates.buildPreview) {
              setSnackbarData({text: 'Creating preview...', success: true})
              setOpenSnackbar(true);
              // const cache = getCache();
              // await saveCache({variables:{projectId: projectId, cache: cache}})
              const { data } = await build({variables:{projectId: projectId, preview:true}})
              if (data.build.success) {
                refetchProjectPreviewStatus();
                refetchProject();
                setProjectPreviewStatus(null)
              } else {
                setSnackbarData({text: 'Building preview failed', success: false})
                setOpenSnackbar(true);
              }
            }
          }}>
          <div className="navbar-button-text">PREVIEW</div>
          {(buttonStates.previewBuilding) && <div className="ion-android-sync publishing"></div>}
          {(buttonStates.preview) && <div className="ion-android-open open-ad"></div>}
          <div className="navbar-date-text">{buttonStates.lastPreviewedText && formatDate(project.lastPreviewed)}</div>
        </div>

        {!isTemplate && <div className={buttonStates.optionsButton ? "navbar-button" : "navbar-button disabled"} 
          onClick={()=>{
            setOpenOptions(!openOptions);
          }} 
          style={{position: 'relative', outline: 'none'}}
          >
          <div className="navbar-button-text">Options</div>
          <div className={openOptions ? "ion-ios-arrow-up navbar-options-icon" : "ion-ios-arrow-down navbar-options-icon"}></div>
        </div>}
        {openOptions && <div className="options-container">
            <div className={buttonStates.publishButton ? "navbar-button" : "navbar-button disabled"} style={{marginLeft: 0}} onClick={async ()=>{
            if (buttonStates.publishButton) {
              setShowConfirmDialog(buttonStates.update ? "update" : "publish");
            }
          }}>
              <div className="navbar-button-text">{buttonStates.update ? "UPDATE" : "PUBLISH"}</div>
              {buttonStates.publishing && <div className="ion-android-sync publishing"></div>}
            </div>
            <div className={buttonStates.unpublishButton ? "navbar-button" : "navbar-button disabled"} style={{marginLeft: 0}}
            onClick={async ()=>{
              if (buttonStates.unpublishButton) {
                setShowConfirmDialog("unpublish")
              }
            }}>
              <div className="navbar-button-text">UNPUBLISH</div>
            </div>
          </div>}

        {isTemplate && <VisibilityOptions 
          enabled={buttonStates.visibilityButton} 
          activeAccounts={project && project.template.accounts} 
          templateId={project && project.template.id} 
          refetchProject={refetchProject}
        />}
        
        {!isTemplate && <div className={buttonStates.viewButton ? "navbar-button" : "navbar-button disabled"} onClick={()=>{
            if (buttonStates.viewButton) window.open('/'+projectId,'_blank');
          }}>
          <div className="navbar-button-text">VIEW</div>
          <div className="ion-android-open open-ad"></div>
          <div className="navbar-date-text">{buttonStates.lastPublishedText && formatDate(project.lastPublished)}</div>
        </div>}
        </>}
      </div>}
      <div className="navbar-logout-container">
        {!projectId && userData && (userData.user.permissions.includes("EditDivisions") || userData.user.permissions.includes("EditUsers")) && 
        <div className={"navbar-button settings"} style={location.pathname === "/builder/settings" ? {backgroundColor: '#3f3f3f'} : {}} onClick={()=>{
            history.push({
              pathname: '/builder/settings' 
            })
          }}>
          <div className="navbar-button-text">ADMIN</div>
        </div>}
        {!projectId && userData && <div className={"navbar-button settings"} onClick={()=>{
            localStorage.setItem("jwt-viewfinder", "");
            if (userData.user.account && userData.user.account.iss) {
              window.location.replace(userData.user.account.iss+'connect/logout?post_logout_redirect_uri=https://viewfinder.se/builder/login')
            } else {
              refetchUserData();
            }
          }}>
          <div className="navbar-button-text">LOG OUT</div>
        </div>}
        {!projectId && userData && <div id="user-name" style={{marginLeft:20, cursor: 'default'}}>{userData.user.name}</div>}
        {!projectId && userData && userData.user.account && <img className="account-logo" style={{marginLeft: 10, height: 40}} src={userData.user.account.logoUrl}></img>}
      </div>
      <Snackbar text={snackbarData.text} success={snackbarData.success} open={openSnackbar} />
    </div>
    </>
  );
}

export default Navbar;