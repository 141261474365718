import React, { useState, useRef, useEffect } from "react";
import "./Image.css";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { UPDATE_GRID_IMAGE } from "../graphql/mutations";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { GET_ITEM } from "../graphql/queries";
import { useParams } from "react-router-dom";
import {ReactComponent as SlideShowOn}  from '../slideshow-on.svg';
import {ReactComponent as SlideShowOff}  from '../slideshow-off.svg';

function Image(props: any) {
  let { projectId } = useParams<any>();
  const { gridId, position} = props;
  const [toolbarExpanded, setToolbarExpanded] = useState(false);
  const [editingFrame, setEditingFrame] = useState("");
  const [animationSpeed, setAnimationSpeed] = useState(1);
  const [animationPreset, setAnimationPreset] = useState(0);
  const [resizedImageUrl, setResizedImageUrl] = useState("");
  const [startScale, setStartScale] = useState(1);
  const [endScale, setEndScale] = useState(1);
  const [startTranslateX, setStartTranslateX] = useState(0);
  const [startTranslateY, setStartTranslateY] = useState(0);
  const [endTranslateX, setEndTranslateX] = useState(0);
  const [endTranslateY, setEndTranslateY] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [showInSlideshow, setShowInSlideshow] = useState(true);
  const {data} = useQuery(GET_ITEM, {variables:{gridId: gridId, position:position}})
  const [updateGridImage] = useMutation(UPDATE_GRID_IMAGE);
  const containerEl = useRef(null);
  const imageEl = useRef(null);

  let prevScale = 1;
  let prevX = 0;
  let prevY = 0;

  const animationPresets = [
    {name: 'ZC', startX: 0, startY: 0, startScale: 1, endX: -56, endY: -77, endScale: 2.13, speed: 1},
    {name: 'ZR', startX: 0, startY: 0, startScale: 1, endX: -80, endY: -60, endScale: 2, speed: 2},
    {name: 'ZL', startX: 0, startY: 0, startScale: 1, endX: -21, endY: -74, endScale: 2, speed: 3},
    {name: 'PR', startX: -6.6, startY: -54.5, startScale: 1.9, endX: -87.4, endY: -54.5, endScale: 1.9, speed: 1},
    {name: 'PL', startX: -86, startY: -59, startScale: 1.9, endX: -6.6, endY: -54.5, endScale: 1.9, speed: 2}
  ]

  useEffect(()=>{
    const handleResize = () => {
      // Update width and height state (so that the relative translate stays correct)
      const imageContainer:any = containerEl.current;
      if (imageContainer) {
        setWidth(imageContainer.clientWidth);
        setHeight(imageContainer.clientHeight);
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize();
  }, [])


  useEffect(()=>{
      if (!data || !data.item.endFrame) return
      // Set initial or updated data
      setStartTranslateX(data.item.startFrame.translateX);
      setStartTranslateY(data.item.startFrame.translateY);
      setStartScale(data.item.startFrame.scale);
      setEndScale(data.item.endFrame.scale);
      setEndTranslateX(data.item.endFrame.translateX);
      setEndTranslateY(data.item.endFrame.translateY);
      setAnimationSpeed(data.item.animationSpeed);
      setShowInSlideshow(data.item.showInSlideshow);

      for (let i = 0; i < animationPresets.length; i++) {
        if (data.item.startFrame.translateX === animationPresets[i].startX &&
          data.item.startFrame.translateY === animationPresets[i].startY &&
          data.item.startFrame.scale === animationPresets[i].startScale &&
          data.item.endFrame.translateX === animationPresets[i].endX &&
          data.item.endFrame.translateY === animationPresets[i].endY &&
          data.item.endFrame.scale === animationPresets[i].endScale &&
          data.item.animationSpeed === animationPresets[i].speed
          ) {
          setAnimationPreset(i+1)
        }
      }

      const imageContainer:any = containerEl.current;
      const containerWidth = imageContainer.clientWidth;
      const CloudFrontUrl = "https://dkj4aap2z9ncw.cloudfront.net"
      let scaleCompensate = data.item.startFrame.scale > data.item.endFrame.scale ? data.item.startFrame.scale : data.item.endFrame.scale;
      let resizeWidth = parseInt((containerWidth*scaleCompensate).toString());
      if (resizeWidth > 1920) {
        // Avoid AWS lambda limit of 6MB
        resizeWidth = 1920;
      }
      const imageRequest = JSON.stringify({
        bucket: "husfoto-exposure",
        key: data.item.url,
        edits: {
          resize: {
            width: resizeWidth,
            fit: "inside"
          }
        }
      });
      setResizedImageUrl(`${CloudFrontUrl}/${btoa(imageRequest)}`);
  }, [data])

  useEffect(()=> {
    if (editingFrame === "" && toolbarExpanded && endTranslateX) {
      // Create and play animation
      let imageElement:any = imageEl.current;
      if (imageElement) {
        imageElement.animate([
          { transform: `translate(${startTranslateX}%, ${startTranslateY}%) scale(${startScale})` }, 
          { transform: `translate(${endTranslateX}%, ${endTranslateY}%) scale(${endScale})` }
        ], { 
          duration: (25/animationSpeed)*1000,
          direction: 'alternate',
          easing: 'ease-in-out',
          iterations: Infinity
        });
      }
    } else {
      // Cancel animation
      let imageElement:any = imageEl.current;
      if (imageElement) {
        imageElement.getAnimations().forEach(
           (animation:any) => {
            animation.cancel();
          }
        );
      }
    }
  }, [editingFrame, animationSpeed, toolbarExpanded])

  function updateState(prevEditingFrame:string) {
    // Save changes to State
    if (!prevEditingFrame) {
      return
    }
    if (prevEditingFrame === "start") {
      setStartScale(prevScale);
      setStartTranslateX(prevX);
      setStartTranslateY(prevY);

      if (animationPreset) {
        if (animationPresets[animationPreset-1].startScale != prevScale ||
          animationPresets[animationPreset-1].startX != prevX ||
          animationPresets[animationPreset-1].startY != prevY) {
          setAnimationPreset(0);
        }
      }
    } 
    
    else if (prevEditingFrame === "end") {
      setEndScale(prevScale);
      setEndTranslateX(prevX);
      setEndTranslateY(prevY);
      if (animationPreset) {
        if (animationPresets[animationPreset-1].endScale != prevScale ||
          animationPresets[animationPreset-1].endX != prevX ||
          animationPresets[animationPreset-1].endY != prevY) {
          setAnimationPreset(0);
        }
      }
    }
  }

  useEffect(()=>{
    // Save changes to Cache when closing the toolbar
    if (editingFrame === "" && !toolbarExpanded) {
      if (width) {
        // To avoid updating cache initally
        updateCache();
      }
    }
  }, [editingFrame, toolbarExpanded])

  useEffect(()=>{
    if (animationPreset) {
      setStartScale(animationPresets[animationPreset-1].startScale);
      setStartTranslateX(animationPresets[animationPreset-1].startX);
      setStartTranslateY(animationPresets[animationPreset-1].startY);
      setEndScale(animationPresets[animationPreset-1].endScale);
      setEndTranslateX(animationPresets[animationPreset-1].endX);
      setEndTranslateY(animationPresets[animationPreset-1].endY);
      setAnimationSpeed(animationPresets[animationPreset-1].speed);
      setEditingFrame("");
    } 
    else if (animationPreset === 0 && editingFrame === "") {
      if (width) {
        // To avoid reseting initally
        resetState();
      }
    }

  }, [animationPreset])

  function updateCache() {
    updateGridImage({variables: {
      imageId: gridId+"_item_"+position, 
      frame: 1, 
      scale: startScale, 
      translateX: startTranslateX, 
      translateY: startTranslateY
    }})
    updateGridImage({variables: {
      imageId: gridId+"_item_"+position, 
      frame: 2, 
      scale: endScale, 
      translateX: endTranslateX, 
      translateY: endTranslateY
    }})
    updateGridImage({variables: {
      imageId: gridId+"_item_"+position,
      speed: animationSpeed
    }})
  }

  function resetState() {
    prevX = 0;
    prevY = 0;
    prevScale = 1;
    setStartScale(1);
    setStartTranslateX(0);
    setStartTranslateY(0);
    setEndScale(1);
    setEndTranslateX(0);
    setEndTranslateY(0);
    if (editingFrame !== "start") {
      setEditingFrame("start");
    } else {
      setEditingFrame("end");
    }
    setAnimationSpeed(1);
    setAnimationPreset(0);
  }
  return (
    <>
    <div className="settings-container" style={toolbarExpanded ? { width: 340, overflowX: 'auto', height: 85} : { width: 50, overflowX: 'hidden', height:50 }}>
        <div style={{display: 'flex', height: toolbarExpanded ? '50%' : '100%'}}>
          <div style={{position: 'relative', padding: '0px 15px'}} title={toolbarExpanded ? "Confirm image settings" : "Image settings"} className={toolbarExpanded ? "ion-checkmark settings-icon expanded" : "ion-android-settings settings-icon"} onClick={()=>{
              if (!toolbarExpanded) {
                setEditingFrame("start")
              } else {
                updateState(editingFrame);
                setEditingFrame("")
              }
              setToolbarExpanded(!toolbarExpanded)
            }}>{!toolbarExpanded && (startTranslateX || endTranslateX) ? 
              <div style={{position:'absolute', color:'#a0ffa0', top:10, left: 15, fontSize: 16}} 
              className="ion-checkmark-circled settings-icon"></div> : ""}</div>
          <div title="Edit start frame" className={editingFrame === "start" ? "ion-ios-skipbackward settings-icon" : "ion-ios-skipbackward-outline settings-icon"} onClick={()=>{
            updateState(editingFrame);
            setEditingFrame("start")}
          }></div>
          <div title="Edit end frame" className={editingFrame === "end" ? "ion-ios-skipforward settings-icon": "ion-ios-skipforward-outline settings-icon"} onClick={()=>{
            updateState(editingFrame);
            setEditingFrame("end")
            }}></div>
          <div title="Animation speed" className={"ion-ios-speedometer-outline settings-icon"} onClick={()=>{
            updateState(editingFrame);
            let newSpeed = animationSpeed === 5 ? 1 : animationSpeed+1;
            setAnimationSpeed(newSpeed);
            if (animationPreset) {
              setEditingFrame("start");
            } else {
              setEditingFrame("");
            }
            setAnimationPreset(0);
            
            }}><p className="speed-text">{"x"+animationSpeed}</p></div>
            <div title={!editingFrame ? "Pause animation" : "Play animation"} className={!editingFrame ? "ion-ios-pause settings-icon": "ion-ios-play-outline settings-icon"} onClick={()=>{
              updateState(editingFrame);
              if (!editingFrame) {
                setEditingFrame("start");
              } else {
                setEditingFrame("");
              }
            }}></div>
            <div title="Reset image settings" className={"ion-ios-undo-outline settings-icon"} onClick={()=>{
              resetState();
            }}></div>
            <div title="Animation preset" className={animationPreset ? "ion-ios-star settings-icon" : "ion-ios-star-outline settings-icon"} onClick={()=>{
              let newPreset = animationPreset === animationPresets.length ? 0 : animationPreset+1;
              setAnimationPreset(newPreset);
            }}>{animationPreset ? <p className="speed-text">{animationPresets[animationPreset-1].name}</p> : null}</div>
        </div>
        {toolbarExpanded && editingFrame && <div className="settings-text">{"Editing "+editingFrame+" frame"}</div>}
        {toolbarExpanded && !editingFrame && <div className="settings-text">{"Preview"}</div>}
      </div>
      <div className="slideshow-settings" title="Visible in slideshow" style={{ width: 32, height:32, cursor: 'pointer' }} onClick={()=>{
        setShowInSlideshow(!showInSlideshow)
        updateGridImage({variables: {
          imageId: gridId+"_item_"+position,
          showInSlideshow: !showInSlideshow
        }})
      }}>
        {showInSlideshow ? <SlideShowOn style={{width: '100%', fill: '#3b3b3bb3'} } /> : <SlideShowOff style={{width: '100%', fill: '#3b3b3bb3'}} />}
      </div>
    <div ref={containerEl} style={{position: 'relative', width: '100%', height: '100%', zIndex: editingFrame ? 3 : 1, cursor: editingFrame ? "all-scroll" : "auto"}}>
    {!editingFrame && <div ref={imageEl} className="img-container bg-img"  style={{
      backgroundColor: 'transparent',
      backgroundSize: "contain", 
      backgroundImage:'url("'+resizedImageUrl+'")', 
      transform: "translate("+startTranslateX+"%, "+startTranslateY+"%"+")"+" scale("+startScale+")", 
      transformOrigin: "0% 0%"
    }}></div>}
    {editingFrame && <TransformWrapper
        defaultScale={editingFrame === "end" ? endScale : startScale}
        defaultPositionX={editingFrame === "end" ? (endTranslateX/100)*width : (startTranslateX/100)*width}
        defaultPositionY={editingFrame === "end" ? (endTranslateY/100)*height : (startTranslateY/100)*height}
        scale={editingFrame === "end" ? endScale : startScale}
        positionX={editingFrame === "end" ? (endTranslateX/100)*width : (startTranslateX/100)*width}
        positionY={editingFrame === "end" ? (endTranslateY/100)*height : (startTranslateY/100)*height}
        options={{
          limitToBounds: false,
          minScale:0.1
        }}
        wheel={{
          step: width/height > 1.6 ? 30 : 1
        }}
      >
      {({ scale, positionX, positionY }:any) => {
          if (scale !== prevScale) {
            prevScale = scale;
          } 
          if (positionX !== prevX) {
            prevX = (positionX/width)*100;
          }
          if (positionY !== prevY) {
            prevY = (positionY/height)*100;
  
          }
        return <React.Fragment>
          <TransformComponent>
            {toolbarExpanded && <img src={resizedImageUrl} alt="test" className="transform-img" />}            
          </TransformComponent>
        </React.Fragment>
      }}
    </TransformWrapper>}
    </div>
    </>
  );
}

export default Image;
