import React from 'react';
import './StructureList.css';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_SECTIONS } from '../graphql/queries';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { REORDER_GRID, REORDER_SECTION, DELETE_SECTION, DELETE_GRID } from '../graphql/mutations';
import Grid1 from '../Grid/Grid1';
import Grid2 from '../Grid/Grid2';
import Grid3 from '../Grid/Grid3';
import Grid4 from '../Grid/Grid4';
import Grid5 from '../Grid/Grid5';
import Grid6 from '../Grid/Grid6';
import Grid7 from '../Grid/Grid7';
import Grid8 from '../Grid/Grid8';
import Grid9 from '../Grid/Grid9';
import Expandable from '../Expandable/Expandable';


function StructureList(props:any) {
  const {data} = useQuery(GET_SECTIONS)
  const [reorderGrid] = useMutation(REORDER_GRID);
  const [reorderSection] = useMutation(REORDER_SECTION);
  const [deleteSection] = useMutation(DELETE_SECTION);
  const [deleteGrid] = useMutation(DELETE_GRID);

  const onDragEnd = (res: DropResult) => {
    const {destination, source, draggableId, type} = res;

    let hasHeader = data && data.sections && data.sections[0] && data.sections[0].id === "0";
    let hasFooter = data && data.sections && data.sections[data.sections.length-1] && data.sections[data.sections.length-1].id === "99";

    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && 
        destination.index === source.index) {
      return;
    }

    if (destination.droppableId === "section-delete") {
      deleteSection({variables:{sectionIndex: source.index}})
    } else if (destination.droppableId === "grid-delete") {
      deleteGrid({variables:{sectionId:source.droppableId, gridIndex:source.index}})
    } else if (type === 'section') {
      if (hasHeader && (source.index === 0 || destination.index === 0)) {
        return;
      }
      if (hasFooter && (source.index === data.sections.length-1 || destination.index === data.sections.length-1)) {
        return;
      }
      reorderSection({variables:{
        sectionId: draggableId, 
        sectionSourceIndex: source.index, 
        sectionDestinationIndex: destination.index
      }})
    } else if (type === 'grid') {
      reorderGrid({variables:{
        gridId: draggableId, 
        sectionSourceId: source.droppableId, 
        sectionDestinationId: destination.droppableId, 
        gridSourceIndex: source.index, 
        gridDestinationIndex: destination.index
      }})
    }
  }

  const onBeforeDragStart = (res: DropResult) => {
    let sectionDelete = document.getElementById("section-delete");
    let gridDelete = document.getElementById("grid-delete");

    if (sectionDelete && gridDelete) {
      sectionDelete.style.zIndex = (res.type === "section" ? "1" : "0");
      gridDelete.style.zIndex = (res.type === "grid" ? "1" : "0");
    }
  }

  const getPreviewGrid = (gridType:string) => {
    switch (gridType) {
      case "1": return <Grid1 preview />
      case "2": return <Grid2 preview />
      case "3": return <Grid3 preview />
      case "4": return <Grid4 preview />
      case "5": return <Grid5 preview />
      case "6": return <Grid6 preview />
      case "7": return <Grid7 preview />
      case "8": return <Grid8 preview />
      case "9": return <Grid9 preview />
      case "10": return <Expandable preview />
      default:
        return <></>
    }
  }

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onBeforeDragStart={onBeforeDragStart}
    >
      <Droppable droppableId={"droppable"} type="section">
            {(provided)=> (
    <div ref={provided.innerRef} {...provided.droppableProps}>
      <div id="draggable-list-container">
      {data && data.sections.map((section:any, index:any)=>{
        return (<Draggable draggableId={section.id} index={index} key={section.id}>
          {(provided, snapshot) => {
          if (section.id === "0") {
            return <div id="structure-list-section" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}> Header </div>
          } 
          if (section.id === "99") {
            return <div id="structure-list-section" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}> Footer </div>
          }
          return <div id="structure-list-section" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <Droppable droppableId={section.id} type="grid">
            {(provided)=> (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 30}}>
                  <div>{section.title !== null ? (section.title === "__DELETED__" ? "" : section.title) : '- TEXT -'}</div> 
                  <span style={{position: 'absolute', right: '0.8vw', top: '0.4vw'}} className="ion-arrow-move"></span>
                </div>
                {section.grids.map((grid:any, index:any)=> {
                  return (
                    <div key={grid.id}>
                      <Draggable draggableId={grid.id} index={index}>
                        {(provided, snapshot) => {
                          return <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} >
                            <div id="structure-list-grid" style={{backgroundColor: snapshot.isDragging ? '#8484846e' : 'none'}}>
                              {getPreviewGrid(grid.type)}
                            </div>
                          </div>
                        }}
                      </Draggable>
                    </div>
                  )
                })}
                {<h1>{provided.placeholder}</h1>}
              </div>
            )}
          </Droppable>
        </div>}}
        </Draggable>)
      })}
      </div>
      <div style={{position:'fixed', height: 70, width: 200, bottom: 0, zIndex: 4}}>
      <Droppable droppableId={"section-delete"} type={"section"}>
        {(provided, snapshot)=> (
          <div id="section-delete" ref={provided.innerRef} {...provided.droppableProps} 
          style={{
            height: '100%', 
            backgroundColor: snapshot.isDraggingOver ? '#ff00004a' : '#882e2e', 
            width:'100%', 
            position: 'absolute', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems:'center',
            zIndex: 2
          }}>
            <div style={{fontSize: 40, color:'white', position:'absolute'}} className="ion-ios-trash"></div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Droppable droppableId={"grid-delete"} type={"grid"}>
        {(provided, snapshot)=> (
          <div id="grid-delete" ref={provided.innerRef} {...provided.droppableProps} style={{height: '100%', backgroundColor: snapshot.isDraggingOver ? '#ff00004a' : '#ff00001c', width:'100%', position: 'absolute', display: 'flex', justifyContent: 'center', alignItems:'center'}}>
            <div style={{fontSize: 40, color:'white', position:'absolute'}} className="ion-ios-trash"></div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      </div>
    {provided.placeholder}</div>)}
    </Droppable>
    </DragDropContext>
  );
}

export default StructureList;