import React from "react";
import "./Grid.css";
import GridItem from "../GridItem/GridItem";

function Grid6(props: any) {
  const { gridId, preview } = props;
  return (
    <>
    {preview ?
    <div className="grid grid6 preview">
      <div className="bg-img grid6-1"></div>
      <div className="bg-img grid6-2"></div>
    </div> : <div className="grid grid6">
      <GridItem className="bg-img grid6-1" gridId={gridId} position={1}/>
      <GridItem className="bg-img grid6-2" gridId={gridId} position={2}/>
    </div>}
    </>
  );
}

export default Grid6;
