import React, { useContext, useEffect, useState } from 'react';
import '../Modal/Modal.css';
import { ModalContext } from '../context';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ADD_GRID, DUPLICATE_PROJECT } from '../graphql/mutations';
import { GET_ACCOUNTS, GET_TEMPLATES } from '../graphql/queries';
import moment from 'moment';
import { IoMdClose } from 'react-icons/io';

function TemplateModal(props:any) {
  const {sectionId, closeModal, refetchProjects} = props;
  const modal = useContext(ModalContext);
  const [mutate] = useMutation(ADD_GRID);
  const [page, setPage] = useState(1);
  const [templates, setTemplates] = useState([]);
  const [currentTemplates, setCurrentTemplates] = useState<any>([]);
  const [showNextPage, setShowNextPage] = useState(false);
  const { data, loading, refetch } = useQuery(GET_TEMPLATES);
  const [ duplicateProject ] = useMutation(DUPLICATE_PROJECT);

  if (data && templates.length === 0) {
    if (data.templates.length) {
      setTemplates(data.templates)
    }
  }

  useEffect(() => {
    if (templates) {
      setCurrentTemplates(templates.slice((page-1)*3,(page-1)*3+3))
      let gotMore = templates.slice((page)*3,(page)*3+3).length > 0;
      if (gotMore) {
        setShowNextPage(true)
      } else {
        setShowNextPage(false);
      }
    }
  }, [page, templates])

  useEffect(()=>{
    document.addEventListener('keydown', function(e) {
      if (e.key == "Escape") {
        closeModal();
      }
    });
  }, [])
  return (
    <div className="modal-container" onClick={()=>{closeModal()}}>
      <div className="modal-content-container" onClick={(e)=>e.stopPropagation()}>
        <div 
        style={{height:'10%', 
        backgroundColor: '#232323', 
        display:'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        position: 'relative'
        }}
        onClick={()=>{closeModal()}}>
          <div className="layout-modal-title">TEMPLATE</div>
          <div onClick={()=>closeModal()} style={{
            position: 'absolute',
            color:'white',
            fontSize: '3vw',
            cursor: 'pointer',
            right: 20
          }}><IoMdClose title="Close" /></div>
        </div>
        <div className="divider"></div>
        <div className="template-layout-grid">
          {currentTemplates.map((template:any, index:number)=>{
            return <div key={index} className="template-layout-container" onClick={async ()=>{
                await duplicateProject({variables:{projectId: template.id, fromTemplate: true}})
                refetchProjects();
                closeModal()
              }}>
                <iframe className="template-iframe" scrolling="0" frameBorder="0" src={'https://d2xq4de70dn5p9.cloudfront.net/'+template.id}></iframe>
              <div className="template-button">{template.name}<span className="android-arrow-forward"></span></div>
            </div>
          })}
        </div>
        {showNextPage && <div className="ion-ios-arrow-right modal-arrow right" onClick={()=>{setPage(page+1)}}></div>}
        {page > 1 && <div className="ion-ios-arrow-left modal-arrow left" onClick={()=>{setPage(page-1)}}></div>}
      </div>
    </div>
  );
}

export default TemplateModal;
