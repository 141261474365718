import gql from 'graphql-tag';

export const FRAGMENT_SECTION_GRIDS = gql`
fragment sectionGrids on Section {
  grids {
    id,
    type,
    items {
      ... on Item {
        id,
        url,
      }
      ... on Map {
        id,
        lat,
        lng,
        zoom
      }
      ... on Slider {
        id,
        images {
          url,
          startFrame {
            scale,
            translateX,
            translateY
          },
        }
      }
      ... on Image {
        url,
        id,
        startFrame {
          scale,
          translateX,
          translateY
        },
        endFrame {
          scale,
          translateX,
          translateY
        },
        animationSpeed,
        showInSlideshow
      }
      ... on TextArea {
        id,
        text
      }
    },
    texts {
      id,
      text
    }
  }
}
`;

export const FRAGMENT_SECTION = gql`
fragment section on Section {
  grids {
    id,
    type,
    items {
      ... on Item {
        id,
        url,
      }
      ... on Map {
        id,
        lat,
        lng,
        zoom
      }
      ... on Slider {
        id,
        images {
          url,
          startFrame {
            scale,
            translateX,
            translateY
          },
        }
      }
      ... on Image {
        url,
        id,
        startFrame {
          scale,
          translateX,
          translateY
        },
        endFrame {
          scale,
          translateX,
          translateY
        },
        animationSpeed,
        showInSlideshow
      },
      ... on TextArea {
        id,
        text
      }
    },
    texts {
      id,
      text
    }
  }
}
`;

export const FRAGMENT_SECTION_TAG = gql`
fragment sectionTag on Section {
  tag
}
`;

export const FRAGMENT_SECTION_TITLE = gql`
fragment sectionTitle on Section {
  title
}
`;

export const FRAGMENT_ITEM = gql`
fragment item on Item {
  url,
  id
}
`

export const FRAGMENT_IMAGE = gql`
fragment image on Image {
  startFrame {
    scale,
    translateX,
    translateY
  },
  endFrame {
    scale,
    translateX,
    translateY
  },
  animationSpeed,
  showInSlideshow
}
`

export const FRAGMENT_MAP = gql`
fragment map on Map {
  lat,
  lng,
  id
}
`

export const FRAGMENT_TEXTAREA = gql`
fragment textArea on TextArea {
  id,
  text
}
`

export const FRAGMENT_MAP_FULL = gql`
fragment mapFull on Map {
  lat,
  lng,
  zoom,
  id
}
`

export const FRAGMENT_MAP_ZOOM = gql`
fragment mapZoom on Map {
  zoom,
  id
}
`

export const FRAGMENT_SLIDER = gql`
fragment slider on Slider {
  id,
  images {
    url,
    startFrame {
      scale,
      translateX,
      translateY
    }
  }
}
`

export const FRAGMENT_GRID_ITEMS = gql`
fragment gridItems on Grid {
  items {
    ... on Item {
      id,
      url,
    }
    ... on Map {
      id,
      lat,
      lng,
      zoom
    }
    ... on Slider {
      id,
      images {
        url,
        startFrame {
          scale,
          translateX,
          translateY
        }
      }
    }
    ... on Image {
      url,
      id,
      startFrame {
        scale,
        translateX,
        translateY
      },
      endFrame {
        scale,
        translateX,
        translateY
      },
      animationSpeed,
      showInSlideshow
    }
    ... on TextArea {
      id,
      text
    }
  }
}
`;

export const FRAGMENT_TEXT = gql`
fragment text on Text {
  text,
  id
}
`;

export const FRAGMENT_GRID_TEXTS = gql`
fragment gridTexts on Grid {
  texts {
    id,
    text
  }
}
`
export const FRAGMENT_TIMES_USED = gql`
fragment timesUsed on TimesUsed {
  timesUsed
}
`