import React, { useState } from 'react';
import './Iframe.css';
import { MuiThemeProvider, TextField, createMuiTheme } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_GRID_IFRAME } from '../graphql/mutations';
import Snackbar from '../Snackbar/Snackbar';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#232323'
    }
  }
});

function Iframe(props:any) {
  const { gridId, position, refetchGridItem, url } = props;
  const [input, setInput] = useState("https://")
  const [updateGridIframe] = useMutation(UPDATE_GRID_IFRAME);
  const [showError, setShowError] = useState(false)
  return (
    <>
      <div className="iframe-input-inner">
        <MuiThemeProvider theme={theme}>
          <TextField value={input} label="URL" onChange={(event:any)=>{
            setInput(event.target.value)
          }}/>
        </MuiThemeProvider>
        <div className="border-button ion-checkmark" style={{width: 60, height: 40, marginLeft: '5%'}} onClick={()=>{
          if (!input.startsWith("https://")) {
            setShowError(true);
            setTimeout(()=>{
              // setShowError(false);
            }, 4000)
          } else {
            setShowError(false);
            updateGridIframe({variables:{gridId: gridId, url: input, position: position}})
            refetchGridItem();
          }
        }}></div>
      </div>
      {url.includes("http") && <iframe className="iframe" src={url} frameBorder="0" allowFullScreen></iframe>}
      {showError && <div className="iframe-error">URL not valid: HTTPS required</div>}
    </>
  );
}

export default Iframe;