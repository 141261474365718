// Custom hook to handle issue with state not updating in scroll event listener callback
// https://stackoverflow.com/questions/53845595/wrong-react-hooks-behaviour-with-event-listener
import moment from "moment";
import { useCallback, useRef, useState, SetStateAction, Dispatch } from "react";

const isFunction = <S>(
  setStateAction: SetStateAction<S>
): setStateAction is (prevState: S) => S =>
  typeof setStateAction === "function";

type ReadOnlyRefObject<T> = {
  readonly current: T;
};

type UseStateRef = {
  <S>(initialState: S | (() => S)): [
    S,
    Dispatch<SetStateAction<S>>,
    ReadOnlyRefObject<S>
  ];
  <S = undefined>(): [
    S | undefined,
    Dispatch<SetStateAction<S | undefined>>,
    ReadOnlyRefObject<S | undefined>
  ];
};

//@ts-ignore
export const useStateRef: UseStateRef = <S>(initialState?: S | (() => S)) => {
  const [state, setState] = useState(initialState);
  const ref = useRef(state);

  const dispatch: typeof setState = useCallback((setStateAction: any) => {
    ref.current = isFunction(setStateAction)
      ? setStateAction(ref.current)
      : setStateAction;

    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};

var timeout: any;
export const debounce = (func: any, wait: number, immediate?: boolean) => {
  clearTimeout(timeout);
  return function executedFunction() {
    //@ts-ignore
    let context = this;
    let args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

var idleTimeout: any;
export const idleDebounce = (func: any, wait: number, immediate?: boolean) => {
  console.log("SET IDLE DEBOUNCE, wait time", wait);
  clearTimeout(idleTimeout);
  return function executedFunction() {
    //@ts-ignore
    let context = this;
    let args = arguments;
    let later = function () {
      idleTimeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !idleTimeout;
    clearTimeout(idleTimeout);
    idleTimeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const estimateVideoRenderTime = (
  nSlides,
  secondsPerSlide,
  fps,
  exportedAt
) => {
  const timeSinceStart = moment().diff(moment(exportedAt), "s");
  const totalFrames = nSlides * secondsPerSlide * fps;

  const AVARAGE_LAMBDA_DURATION = 10;
  // Maximum concurrent lambda functions are 1000
  // e.g. 1200 frames will take approx. twice as long
  const SEQUENTIAL = Math.ceil((nSlides * secondsPerSlide * fps) / 1000);
  const totalLambdaDuration = AVARAGE_LAMBDA_DURATION * SEQUENTIAL;

  const MEDIA_CONVERT_QUEUE = 3;
  const MEDIA_CONVERT_TIME_PER_FRAME = 0.03;
  const totalMediaConvertDuration =
    MEDIA_CONVERT_QUEUE + MEDIA_CONVERT_TIME_PER_FRAME * totalFrames;

  const ADDITIONAL_ACTIONS = 3;

  console.log(
    "ESTIMATED TIME:",
    totalLambdaDuration +
      totalMediaConvertDuration +
      ADDITIONAL_ACTIONS -
      timeSinceStart
  );

  return (
    totalLambdaDuration +
    totalMediaConvertDuration +
    ADDITIONAL_ACTIONS -
    timeSinceStart
  );
};
