import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../icon.svg";

type Props = {
  showLockedMessage: boolean;
  showIdleMessage: boolean;
};

function LoadingScreen(props: Props) {
  const { showLockedMessage, showIdleMessage } = props;

  return (
    <motion.div
      id="loading-screen"
      exit={{ translateY: "-100%" }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "#232323",
        position: "absolute",
        zIndex: 9999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <motion.div
        animate={{ scale: 1.5 }}
        initial={{ scale: 1 }}
        transition={{
          delay: 0.5,
          // repeat: Infinity,
          // repeatType: "mirror",
          duration: 1,
        }}
      >
        <Logo style={{ fill: "white", height: 50 }} />
      </motion.div>

      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        style={{ textAlign: "center", color: "white", padding: 20 }}
      >
        {showLockedMessage && (
          <>
            <div>Someone else is currently editing this video</div>
            <div>Please try again later</div>
          </>
        )}
        {showIdleMessage && !showLockedMessage && (
          <>
            <div>You have been logged out due to inactivity</div>
            <div>Please reload the page</div>
          </>
        )}
      </motion.div>
    </motion.div>
  );
}

export default LoadingScreen;
