import React, { useEffect, useRef, useState } from "react";
import {
  MdChevronRight,
  MdOutlineCropLandscape,
  MdOutlineCropPortrait,
} from "react-icons/md";
import { VideoState } from "./Video";

type SelectBoxResolutionProps = {
  videoState: VideoState;
  setVideoState: Function;
  expanded: boolean;
};

function SelectBoxResolution(props: SelectBoxResolutionProps) {
  const { videoState, setVideoState, expanded } = props;
  const [open, setOpen] = useState(false);
  const selectBoxRef = useRef(null);

  function useOutsideAlerter(ref, func) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          func();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(selectBoxRef, () => setOpen(false));

  return (
    <div
      ref={selectBoxRef}
      id="select-box-resolution"
      style={{
        display: "flex",
        padding: "6px 14px",
        color: "white",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 20,
        borderRadius: 10,
        fontSize: 10,
        fontWeight: 700,
        backgroundColor: "rgb(47, 47, 47)",
        position: "relative",
        width: 80,
        cursor: "pointer",
        zIndex: expanded ? 0 : 30,
      }}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <div
        style={{
          marginRight: 6,
          width: 60,
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        {videoState.resolution === "LANDSCAPE" ? "Landscape" : "Portrait"}
      </div>
      {/* {resolution === Resolution.LANDSCAPE && (
          <MdOutlineCropLandscape style={{ fontSize: 20, marginRight: 6 }} />
        )}
        {resolution === Resolution.PORTRAIT && (
          <MdOutlineCropPortrait style={{ fontSize: 20, marginRight: 6 }} />
        )} */}
      <MdChevronRight
        style={{
          transform: open ? "rotate(-90deg)" : "rotate(90deg)",
          transition: "transform 0.5s",
          fontSize: 14,
        }}
      />

      {/* Select box items */}
      {open && (
        <div
          style={{
            position: "absolute",
            top: 32,
            backgroundColor: "rgb(47, 47, 47)",
            left: 0,
            display: "flex",
            flexDirection: "column",
            padding: "6px 14px",
            borderRadius: "10px",
            width: 90,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "4px 0px",
              opacity: videoState.resolution === "LANDSCAPE" ? 0.5 : 1,
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (videoState.resolution !== "LANDSCAPE") {
                setVideoState({ ...videoState, resolution: "LANDSCAPE" });
                setOpen(false);
              }
            }}
          >
            <div style={{ marginRight: 6, width: 60 }}>Landscape</div>
            <MdOutlineCropLandscape style={{ fontSize: 20, marginRight: 6 }} />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "4px 0px",
              opacity: videoState.resolution === "PORTRAIT" ? 0.5 : 1,
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (videoState.resolution !== "PORTRAIT") {
                setVideoState({ ...videoState, resolution: "PORTRAIT" });
                setOpen(false);
              }
            }}
          >
            <div style={{ marginRight: 6, width: 60 }}>Portrait</div>
            <MdOutlineCropPortrait style={{ fontSize: 20, marginRight: 6 }} />
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectBoxResolution;
