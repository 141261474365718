import React, { useContext, useState, useEffect, useRef } from 'react';
import './Drawer.css';
import StructureList from '../StructureList/StructureList';
import Dropzone from '../Dropzone/Dropzone';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_PROJECT, GET_PROJECT_UPLOADS, GET_SECTIONS } from '../graphql/queries';
import { DELETE_UPLOAD } from '../graphql/mutations';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import FileUpload from '../FileUpload/FileUpload'

enum drawerType {
  "none",
  "images",
  "structure",
  "other",
  "publish",
  "video"
}

function Drawer(props:any) {

  const [expanded, setExpanded] = useState(true)
  const [deleteDialog, setDeleteDialog] = useState(null)
  const [uploads, setUploads] = useState<any>([])
  const [processing, setProcessing] = useState(false)
  const [ deleteUpload ] = useMutation(DELETE_UPLOAD)
  const [uploadsFetched, setUploadsfetched] = useState<any>([]) 
  const { data: sectionsData } = useQuery(GET_SECTIONS);
  const [type, setType] = useState(drawerType.images)
  const textInput = useRef<HTMLDivElement>(null);
  let { projectId }:any = useParams();
  const { data:projectData, loading: projectLoading, refetch } = useQuery(GET_PROJECT_UPLOADS, {variables:{projectId: projectId}, fetchPolicy: 'network-only', pollInterval: processing ? 10000 : 0});
  
  if (!projectLoading && projectData && projectData.projectUploads.uploads && uploads !== projectData.projectUploads.uploads) {
    setUploads(projectData.projectUploads.uploads)
  }

  const imgs = uploads.filter((file:any)=>{
    return file.type === "IMAGE"
  })

  const vids = uploads.filter((file:any)=>{
    return file.type === "VIDEO"
  })

  const other = [
    {name:"Iframe"}, 
    {name:"Gallery"},
    {name:"Map"},
    {name:"Text"}
  ]

  function dragOther(ev:any) {
    if (ev.target.id === "Iframe") {
      ev.dataTransfer.setData("iframe", true);
    } else if (ev.target.id === "Gallery") {
      ev.dataTransfer.setData("slider", true);
    } else if (ev.target.id === "Map") {
      ev.dataTransfer.setData("map", true);
    } else if (ev.target.id === "Text") {
      ev.dataTransfer.setData("text", true);
    }
  }

  const CloudFrontUrl = "https://dkj4aap2z9ncw.cloudfront.net"

  let isProcessing = false;
  vids.forEach((vid:any) => {
    if (vid.status === "PROCESSING") {
      isProcessing = true;
      return;
    }
  });

  if (processing !== isProcessing) {
    setProcessing(isProcessing);
  }

  return (
    <div className="drawer-container">
      <div className="drawer-icons">
          <div className="item ion-images" style={{backgroundColor: type === drawerType.images ? '#232323' : 'initial'}} onClick={()=>{
            setType(drawerType.images);
          }}></div>
          <div className="item ion-ios-videocam" style={{backgroundColor: type === drawerType.video ? '#232323' : 'initial'}} onClick={()=>{
            setType(drawerType.video);
          }}></div>
          <div className="item ion-cube" style={{backgroundColor: type === drawerType.other ? '#232323' : 'initial'}} onClick={()=>{
            setType(drawerType.other);
          }}></div>
          <div className="item" style={{backgroundColor: type === drawerType.structure ? '#232323' : 'initial'}} onClick={()=>{
            setType(drawerType.structure);
          }}><div className="ion-arrow-swap" style={{ transform: 'rotate(90deg)'}}></div></div>
      </div>

          <div className="drawer-content-inner-container" style={expanded ? {transition: 'opacity 1s ease 0.7s', opacity:1} : {transition: 'opacity 0s', opacity:0, height:'0%'}}>
            {type === drawerType.images && <Dropzone refetchProject={()=>{refetch()}} type={"Image"} />}
            {type === drawerType.video && <Dropzone refetchProject={()=>{refetch()}} type={"Video"} />}
            {deleteDialog && <ConfirmDialog 
              type="file"
              onConfirm={async ()=>{
                await deleteUpload({variables:{fileId: deleteDialog, projectId: projectId}})
                setDeleteDialog(null)
                refetch();
              }} 
              onCancel={()=>{
                setDeleteDialog(null)
              }} 
            />}
            {type === drawerType.images && imgs.map((img:any)=>{
              const imageRequest = JSON.stringify({
                bucket: "husfoto-exposure",
                key: projectId+"/img/"+img.name,
                hash: Math.random(), // If the user removes an image and then uploads a new version of the image the old one is otherwised cached
                edits: {
                  resize: {
                    width: 200,
                    fit: "inside"
                  }
                }
              });
              const url = `${CloudFrontUrl}/${btoa(imageRequest)}`;
              return <FileUpload id={img.id} name={img.name} type={"image"} url={url} setDeleteDialog={setDeleteDialog} />
            })}
            {type === drawerType.video && vids.map((vid:any)=>{
              if (vid.status === "PROCESSING") {
                return <div className="ion-android-sync video-loading"></div>
              }
              const imageRequest = JSON.stringify({
                bucket: "husfoto-exposure",
                key: projectId+"/vid/"+vid.name,
                edits: {
                  resize: {
                    width: 200,
                    fit: "inside"
                  }
                }
              });
              const url = `${CloudFrontUrl}/${btoa(imageRequest)}`;
              return <FileUpload id={vid.id} name={vid.name} type={"video"} url={url} setDeleteDialog={setDeleteDialog} />
            })}
            {type === drawerType.structure && <StructureList />}
            {type === drawerType.other && other.map((item)=>{
              let className = "";
              if (item.name === "Iframe") {
                className="ion-ios-browsers"
              } else if (item.name === "Gallery") {
                className="ion-ios-albums"
              } else if (item.name === "Map") {
                className="ion-android-pin"
              } else if (item.name === "Text") {
                className="ion-text"
              }
              return <div key={item.name} className="drawer-other" id={item.name} draggable="true" onDragStart={(e)=>dragOther(e)}>
                  <div className={className} style={{fontSize: '1.4rem'}}>{className==="ion-text" && <span style={{fontVariant: 'initial'}}>Aa</span>} </div>
                  <div>{item.name}</div>
                </div>
            })}
          </div>
    </div>
  );
}

export default Drawer;