var pako = require('pako');

export const deflateData = (data:any) => {
  // Convert String
  let str = JSON.stringify(data);
  // Encode to utf8
  str = unescape(encodeURIComponent(str));
  // Paki compress
  var compressed  = pako.deflate(str, { to: 'string' });
  // Encode base64
  let b64String = btoa(compressed)
  return b64String
}

export const inflateData = (data:any) => {
  // Decode base64
  var str = window.atob(data);
  // Convert binary string to character-number array
  var charData = str.split('').map(function(x:any){return x.charCodeAt(0);});
  // Turn number array into byte-array
  var binData = new Uint8Array(charData);
  // Pako decompress
  let decompressed = pako.inflate(binData, { to: 'string' });
  // Convert utf8 -> utf16 (native JavaScript string format)
  decompressed = decodeURIComponent(escape(decompressed));
  // Convert to JSON
  let json = JSON.parse(decompressed)
  return json;
}