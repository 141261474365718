import React, { useEffect, useRef, useState } from 'react';
import { addNodeUnderParent } from './tree-data-utils';
import { IoMdAdd } from 'react-icons/io';
import SortableTree from 'react-sortable-tree';
import './SortableTree.css';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_DIVISIONS, GET_USER } from '../graphql/queries';
import { CgListTree } from 'react-icons/cg';
import { UPDATE_DIVISION } from '../graphql/mutations';

export default function Sortable(props:any) {
  const { setSelectedCallback, accountId, selectedId } = props;

  const { data:userData} = useQuery(GET_USER, {fetchPolicy: 'network-only'});

  const [treeData, setTreeData] = useState<any>([])
  const [selectedNodeId, setSelectedNodeId] = useState<any>(0)
  const [editingNewDivision, setEditingNewDivision] = useState(false);
  const [expandedNodes, setExpandedNodes] = useState([]);

  const { data:divisionsData } = useQuery(GET_DIVISIONS, {fetchPolicy: 'network-only', variables: {accountId: accountId}, skip: !accountId});

  const [ updateDivision ] = useMutation(UPDATE_DIVISION)

  const formatTreeData = (data) => {
    // Format treeData: "divisions -> children" and "name -> title"
    let stringified = JSON.stringify(data.divisions);
    stringified = stringified.split("divisions").join("children");
    stringified = stringified.split("name").join("title");

    // Expand nodes after refetch
    expandedNodes.forEach((expandedNode)=>{
      stringified = stringified.split(`"id":"${expandedNode}"`).join(`"id":"${expandedNode}","expanded":true`);
    })

    let newTreeData = JSON.parse(stringified);
    return newTreeData
  }

  useEffect(() => {
    if (divisionsData) {
      const formatted = formatTreeData(divisionsData)
      setTreeData(formatted)
      setEditingNewDivision(false);
    }
  }, [divisionsData])

  useEffect(() => {
    if (accountId) {
      // Switched account -> collapse & deselect
      setExpandedNodes([])
      setSelectedNodeId(0)
    } else {
      // New account being created -> Hide tree
      setTreeData([]);
    }
  }, [accountId])

  useEffect(() => {
    if (selectedId) {
      // New division created OR division updated -> need to reselect the division
      setSelectedNodeId(selectedId)
    } else {
      // Division removed -> deselect
      setSelectedNodeId(0)
    }
  }, [selectedId])

  const nodeClicked = (event:any, rowInfo:any) => {
    if (event.target.className.includes('collapseButton') ||event.target.className.includes('expandButton')) {
      if (event.target.className.includes('collapseButton')) {
        let tmp = expandedNodes;
        tmp.splice(expandedNodes.indexOf(rowInfo.node.id), 1)
        setExpandedNodes( tmp );
      } else {
        setExpandedNodes( [...expandedNodes, rowInfo.node.id] )
      }
    } else {
      if (selectedNodeId === "new-division" && rowInfo.node.id !== "new-division") {
        // Remove temp new division node (cancel creation of division)
        const formatted = formatTreeData(divisionsData);
        setTreeData(formatted);
        setEditingNewDivision(false);
      }
      setSelectedNodeId(rowInfo.node.id);
      setSelectedCallback({type:"division", id: rowInfo.node.id});
    }
  };


  useEffect(() => {
    if (expandedNodes) {
      // Scroll right when expanding nodes
      if (document.getElementById("virualized-list") && document.getElementById("virualized-list").children[0]) {
        document.getElementById("virualized-list").children[0].scrollLeft = document.getElementById("virualized-list").children[0].scrollWidth;
      }
    }
  }, [expandedNodes])

  const getNodeKey = ({ node }:any) => node.id;

  const canDrop = ({ node, nextParent, prevPath, nextPath }:any) => {

    if (nextParent === null) {
      return false;
    }

    if (node.isTwin && nextParent && nextParent.isTwin) {
      return false;
    }

    if (editingNewDivision) {
      return false;
    }

    if (userData && !userData.user.permissions.includes("EditDivisions")) {
      return false;
    }

    return true;
  };

  if (!userData) {
    return <></>;
  }

  return (<>
    {userData && userData.user.permissions.includes("CreateDivisions") && <div 
      className="people-row register"
      style={{
        width: '100%', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10,
        opacity: !accountId || editingNewDivision ? 0.5 : 1,
        cursor: !accountId || editingNewDivision ? 'default' : 'pointer'
      }}
      onClick={()=>{
        if (!accountId || editingNewDivision) {
          return
        }
        const tempId = "new-division";
        if (selectedNodeId && selectedNodeId !== "new-division") {
          const newTreeData = addNodeUnderParent({
            //@ts-ignore
            treeData: treeData,
            parentKey: selectedNodeId,
            expandParent: true,
            getNodeKey,
            newNode: {
              title: "",
              id: tempId
            },
            addAsFirstChild: false,
            //@ts-ignore
          }).treeData
          setTreeData(newTreeData);
        } else {
          setTreeData(addNodeUnderParent({
            //@ts-ignore
            treeData: treeData,
            parentKey: treeData[0].id,
            expandParent: true,
            getNodeKey,
            newNode: {
              title: "",
              id: tempId
            },
            addAsFirstChild: false,
            //@ts-ignore
          }).treeData)
        }
        setEditingNewDivision(true)
        setSelectedCallback({type:"division", id: tempId, parent: selectedNodeId ? selectedNodeId : treeData[0].id});
        setSelectedNodeId(tempId);
        // To expand parent when new node is created
        setExpandedNodes( [...expandedNodes, selectedNodeId ? selectedNodeId : treeData[0].id] )
      }}
    >
      <span style={{marginBottom: 4}}>Add division</span>
      <div className="number-of-projects" style={{position: 'static', marginLeft: 10}}><IoMdAdd /></div>
    </div>}

    <div 
      className="people-row register overview" 
      style={{
        opacity: !accountId ? 0.5 : 1,
        cursor: !accountId ? 'default' : 'pointer'
      }}
      onClick={()=>{
        if (!accountId) {
          return
        }
        setSelectedNodeId(0);
        setSelectedCallback({type:"overview"});
      }}>
      <CgListTree style={{marginRight: 10}} />
      <div style={{marginBottom:5}}>Overview</div>
    </div>

    <div style={{ height: 'calc(100vh - 390px)', width:'100%' }}>
      <div style={{ height: '100%', width:'100%' }}>
        <SortableTree
          treeData={treeData}
          getNodeKey={({ node }) => node.id}
          onChange={treeData => {
              setTreeData(treeData)
          }}
          reactVirtualizedListProps={{
            id:"virualized-list"
         }}
          canDrop={canDrop}
          onMoveNode={info =>{
            updateDivision({variables:{divisionId: info.node.id, parentDivisionId: info.nextParentNode.id}})
            .catch(()=>{
              const formatted = formatTreeData(divisionsData);
              setTreeData(formatted);
            })
          }}
          generateNodeProps={rowInfo => {
            let nodeProps = { onClick: (event:any) => nodeClicked(event, rowInfo) };
            if (selectedNodeId === rowInfo.node.id) {
                //@ts-ignore
                nodeProps.className = 'selected-node';
            }
            return nodeProps;
          }}
        />
      </div>
    </div>
  </>);
}
