import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { AiOutlineExpandAlt, AiOutlineDrag } from "react-icons/ai";
import { BsArrowsMove, BsHandIndex } from "react-icons/bs";
import { IoMdTrash } from "react-icons/io";
import { IoMoveOutline } from "react-icons/io5";
import { TbHandMove } from "react-icons/tb";
import { GetVideoOrderQuery } from "../graphql/graphqlTypes-video-queries";
import { debounce, useStateRef } from "../utils";
import Controls from "./Controls";
import Video, { VideoState, VideoTemplate } from "./Video";
import { Panel } from "./VideoEditor";

type Props = {
  expanded: boolean;
  setExpanded: Function;
  currentSlide: "new" | number;
  videoState: VideoState;
  setVideoState: Function;
  setCurrentFrame: Function;
  subNavRef: React.MutableRefObject<any>;
  videoTemplate: VideoTemplate;
  currentFrame: number;
  videoOrderData: GetVideoOrderQuery;
  openPanelRef: any;
  openPanel: Panel;
  currentFrameRef: any;
  progressBarShown: boolean;
};

let pressTimer;
function VideoPlayer(props: Props) {
  const {
    expanded,
    setExpanded,
    currentSlide,
    videoState,
    setVideoState,
    setCurrentFrame,
    subNavRef,
    videoTemplate,
    currentFrame,
    videoOrderData,
    openPanel,
    openPanelRef,
    currentFrameRef,
    progressBarShown,
  } = props;

  const [innerHeight, setInnerHeight] = useState(0);
  const [innerWidth, setInnerWidth] = useState(0);
  const [play, setPlay, playRef] = useStateRef(false);
  const [playInterval, setPlayInteval] = useState(null);

  // States for changing slide image bg position
  const [movable, setMovable] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isTouchDown, setIsTouchDown] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [startBgPos, setStartBgPos] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const appHeight = () => {
      setInnerHeight(window.innerHeight);
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", appHeight);
    appHeight();

    window.onkeyup = (e) => {
      if (e.keyCode == 32) {
        if (openPanelRef.current === Panel.NONE) {
          setPlay(!playRef.current);
        }
      }
    };

    return () => {
      window.removeEventListener("resize", appHeight);
    };
  }, []);

  useEffect(() => {
    if (videoState && videoTemplate) {
      const slides = videoState.slides;
      const slideDuration = videoTemplate.secondsPerSlide;
      const fps = videoTemplate.fps;
      const totalFps =
        slides.length *
        videoOrderData.getVideoOrder.template.secondsPerSlide *
        videoOrderData.getVideoOrder.template.fps;

      if (play) {
        const playInterval = setInterval(() => {
          if (currentFrameRef.current >= totalFps) {
            setCurrentFrame(0);
          } else {
            setCurrentFrame(currentFrameRef.current + 1);
          }
        }, 1000 / fps);
        setPlayInteval(playInterval);
      } else {
        if (playInterval) {
          clearInterval(playInterval);
        }
      }
    }
  }, [play]);

  useEffect(() => {
    // Pause playback when opening panel
    setPlay(false);
  }, [openPanel]);

  const videoBgPositionHandlers = {
    onMouseLeave: (e) => {
      if (isMouseDown) {
        e.preventDefault();
        var rect = e.currentTarget.getBoundingClientRect();
        var x = e.clientX - rect.left;
        var y = e.clientY - rect.top;
        let newPosX = startBgPos.x - (x - startPos.x);
        let newPosY = startBgPos.y - (y - startPos.y);

        if (newPosX < 0) {
          newPosX = 0;
        } else if (newPosX > 100) {
          newPosX = 100;
        }

        if (newPosY < 0) {
          newPosY = 0;
        } else if (newPosY > 100) {
          newPosY = 100;
        }

        let newPos = { x: newPosX, y: newPosY };

        let slides = videoState.slides;
        slides = slides.map((slide, i) => {
          if (currentSlide === i) {
            return { ...slide, bgPosition: newPos };
          }
          return slide;
        });
        setVideoState({ ...videoState, slides: slides });

        setIsMouseDown(false);

        document.getElementById("move-icon").style.display = `none`;
      }
    },
    onMouseDown: (e) => {
      e.preventDefault();
      const clientX = e.clientX;
      const clientY = e.clientY;
      pressTimer = window.setTimeout(function () {
        document.getElementById("move-icon").style.display = `block`;
        document.getElementById("move-icon").style.left = `${clientX - 15}px`;
        document.getElementById("move-icon").style.top = `${clientY - 50}px`;
        setIsMouseDown(true);
      }, 500);

      var rect = e.currentTarget.getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      var y = e.clientY - rect.top;

      setStartPos({ x: x, y: y });

      const startBgPercentageX = document.getElementById(
        "video-slide-" + currentSlide
      ).style.backgroundPositionX;
      const startBgPercentageY = document.getElementById(
        "video-slide-" + currentSlide
      ).style.backgroundPositionY;

      setStartBgPos({
        x: parseInt(startBgPercentageX.split("%")[0]),
        y: parseInt(startBgPercentageY.split("%")[0]),
      });
    },
    onMouseMove: (e) => {
      if (isMouseDown) {
        e.preventDefault();
        var rect = e.currentTarget.getBoundingClientRect();
        var x = e.clientX - rect.left;
        var y = e.clientY - rect.top;

        var returnedFunction = debounce(function () {
          let newPosX = startBgPos.x - (x - startPos.x);
          let newPosY = startBgPos.y - (y - startPos.y);

          if (newPosX < 0) {
            newPosX = 0;
          } else if (newPosX > 100) {
            newPosX = 100;
          }

          if (newPosY < 0) {
            newPosY = 0;
          } else if (newPosY > 100) {
            newPosY = 100;
          }

          let newPos = { x: newPosX, y: newPosY };

          document.getElementById(
            "video-slide-" + currentSlide
          ).style.backgroundPositionX = `${newPos.x}%`;

          document.getElementById(
            "video-slide-" + currentSlide
          ).style.backgroundPositionY = `${newPos.y}%`;
        }, 1);
        returnedFunction();

        document.getElementById("move-icon").style.display = `block`;
        document.getElementById("move-icon").style.left = `${e.clientX - 15}px`;
        document.getElementById("move-icon").style.top = `${e.clientY - 50}px`;
      }
    },
    onMouseUp: (e) => {
      clearTimeout(pressTimer);
      if (isMouseDown) {
        setIsMouseDown(false);
        e.preventDefault();
        var rect = e.currentTarget.getBoundingClientRect();
        var x = e.clientX - rect.left;
        var y = e.clientY - rect.top;

        let newPosX = startBgPos.x - (x - startPos.x);
        let newPosY = startBgPos.y - (y - startPos.y);

        if (newPosX < 0) {
          newPosX = 0;
        } else if (newPosX > 100) {
          newPosX = 100;
        }

        if (newPosY < 0) {
          newPosY = 0;
        } else if (newPosY > 100) {
          newPosY = 100;
        }

        let newPos = { x: newPosX, y: newPosY };

        let slides = videoState.slides;
        slides = slides.map((slide, i) => {
          if (currentSlide === i) {
            return { ...slide, bgPosition: newPos };
          }
          return slide;
        });
        setVideoState({ ...videoState, slides: slides });

        document.getElementById("move-icon").style.display = `none`;
      } else {
        setExpanded(!expanded);
      }
    },
    onTouchStart: (e) => {
      var touch = e.touches[0] || e.changedTouches[0];
      if (touch) {
        pressTimer = window.setTimeout(function () {
          document.getElementById("move-icon").style.display = `block`;
          document.getElementById("move-icon").style.left = `${
            touch.clientX - 15
          }px`;
          document.getElementById("move-icon").style.top = `${
            touch.clientY - 50
          }px`;
          setIsTouchDown(true);
        }, 500);
        var rect = e.currentTarget.getBoundingClientRect();
        var x = touch.clientX - rect.left;
        var y = touch.clientY - rect.top;

        setStartPos({ x: x, y: y });

        const startBgPercentageX = document.getElementById(
          "video-slide-" + currentSlide
        ).style.backgroundPositionX;
        const startBgPercentageY = document.getElementById(
          "video-slide-" + currentSlide
        ).style.backgroundPositionY;

        setStartBgPos({
          x: parseInt(startBgPercentageX.split("%")[0]),
          y: parseInt(startBgPercentageY.split("%")[0]),
        });
      }
    },
    onTouchMove: (e) => {
      clearTimeout(pressTimer);
      setIsTouchDown(true);
      var touch = e.touches[0] || e.changedTouches[0];
      if (touch) {
        var rect = e.currentTarget.getBoundingClientRect();
        var x = touch.clientX - rect.left;
        var y = touch.clientY - rect.top;

        var returnedFunction = debounce(function () {
          let newPosX = startBgPos.x - (x - startPos.x);
          let newPosY = startBgPos.y - (y - startPos.y);

          if (newPosX < 0) {
            newPosX = 0;
          } else if (newPosX > 100) {
            newPosX = 100;
          }

          if (newPosY < 0) {
            newPosY = 0;
          } else if (newPosY > 100) {
            newPosY = 100;
          }

          let newPos = { x: newPosX, y: newPosY };

          document.getElementById("move-icon").style.display = `block`;
          document.getElementById("move-icon").style.left = `${
            touch.clientX - 15
          }px`;
          document.getElementById("move-icon").style.top = `${
            touch.clientY - 50
          }px`;

          document.getElementById(
            "video-slide-" + currentSlide
          ).style.backgroundPositionX = `${newPos.x}%`;
          document.getElementById(
            "video-slide-" + currentSlide
          ).style.backgroundPositionY = `${newPos.y}%`;
        }, 1);
        returnedFunction();
      }
    },
    onTouchEnd: (e) => {
      clearTimeout(pressTimer);
      document.getElementById("move-icon").style.display = `none`;
      if (isTouchDown) {
        var touch = e.touches[0] || e.changedTouches[0];
        if (touch) {
          var rect = e.currentTarget.getBoundingClientRect();
          var x = touch.clientX - rect.left;
          var y = touch.clientY - rect.top;

          setIsTouchDown(false);

          let newPosX = startBgPos.x - (x - startPos.x);
          let newPosY = startBgPos.y - (y - startPos.y);

          if (newPosX < 0) {
            newPosX = 0;
          } else if (newPosX > 100) {
            newPosX = 100;
          }

          if (newPosY < 0) {
            newPosY = 0;
          } else if (newPosY > 100) {
            newPosY = 100;
          }

          let newPos = { x: newPosX, y: newPosY };

          let slides = videoState.slides;
          slides = slides.map((slide, i) => {
            if (currentSlide === i) {
              return { ...slide, bgPosition: newPos };
            }
            return slide;
          });
          setVideoState({ ...videoState, slides: slides });
        }
      } else {
        setExpanded(!expanded);
      }
    },
  };

  let videoPlayerWidth;
  let videoPlayerHeight;
  let subNavHeight = 66;
  if (subNavRef && subNavRef.current) {
    // Get subNav height using ref, because the height depends on the length of address
    subNavHeight = subNavRef.current.clientHeight + 20; //height + marginBottom
    if (progressBarShown) {
      subNavHeight += 4;
    }
  }

  if (videoState.resolution === "LANDSCAPE") {
    videoPlayerWidth = (innerHeight - 66 - subNavHeight - 70 - 128 - 78) * 1.5; // available height - navbar - subnavbar - controls - timeline - bottom navbar
    if (videoPlayerWidth > innerWidth) {
      videoPlayerWidth = innerWidth;
    }
    videoPlayerHeight = videoPlayerWidth * 0.6667; // to get 3:2 ratio
  } else if (videoState.resolution === "PORTRAIT") {
    videoPlayerWidth =
      (innerHeight - 66 - subNavHeight - 70 - 128 - 78) * 0.5625;
    if (videoPlayerWidth > innerWidth) {
      videoPlayerWidth = innerWidth;
    }
    videoPlayerHeight = videoPlayerWidth * 1.777777777777778; // to get 9:16 ratio
  }

  if (videoState.resolution === "LANDSCAPE" && movable) {
    setMovable(false);
  }

  return (
    <>
      <BsArrowsMove
        id="move-icon"
        size={30}
        style={{
          display: "none",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 100,
          color: "white",
        }}
      />
      <AnimateSharedLayout>
        {!expanded && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
                position: "relative",
              }}
            >
              <div
                id="video-expand-button"
                style={{
                  padding: 6,
                  backgroundColor: "rgb(47, 47, 47)",
                  opacity: currentSlide !== "new" ? 1 : 0,
                  position: "absolute",
                  width: 28,
                  height: 28,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  left: 10,
                  top: 0,
                  zIndex: 25,
                  borderRadius: "6px",
                }}
                onClick={(e) => {
                  if (currentSlide !== "new") {
                    setExpanded(true);
                  }
                }}
              >
                <AiOutlineExpandAlt
                  size={18}
                  style={{
                    color: "white",
                    transform: "rotate(90deg)",
                  }}
                />
              </div>

              {videoState.resolution === "PORTRAIT" && (
                <div
                  id="video-move-button"
                  style={{
                    padding: 6,
                    backgroundColor: movable ? "white" : "rgb(47, 47, 47)",
                    opacity: currentSlide !== "new" ? 1 : 0,
                    position: "absolute",
                    width: 28,
                    height: 28,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    left: 10,
                    top: 50,
                    zIndex: 25,
                    borderRadius: "6px",
                  }}
                  onClick={(e) => {
                    if (currentSlide !== "new") {
                      if (movable) {
                        document.getElementById(
                          "move-icon"
                        ).style.display = `none`;
                      }
                      setMovable(!movable);
                    }
                  }}
                >
                  <BsArrowsMove
                    size={18}
                    style={{
                      color: movable ? "rgb(47, 47, 47)" : "white",
                    }}
                  />
                </div>
              )}

              <div
                id="video-delete-button"
                style={{
                  padding: 6,
                  backgroundColor: "rgb(47, 47, 47)",
                  opacity: currentSlide !== "new" && currentSlide !== 0 ? 1 : 0,
                  position: "absolute",
                  width: 28,
                  height: 28,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  right: 10,
                  bottom: 0,
                  zIndex: 100,
                  borderRadius: "6px",
                }}
                onClick={(e) => {
                  if (currentSlide !== "new" && currentSlide !== 0) {
                    let slides = videoState.slides;
                    slides.splice(currentSlide, 1);
                    setVideoState({ ...videoState, slides: slides });
                    setCurrentFrame(0);
                  }
                }}
              >
                <IoMdTrash
                  size={16}
                  style={{
                    color: "white",
                  }}
                />
              </div>

              <motion.div
                id="unexpanded-video"
                style={{
                  // width: "80vw",
                  // height: `calc(80vw * 0.6667)`,
                  width: videoPlayerWidth,
                  height: videoPlayerHeight,
                  position: "relative",
                  zIndex: 20,
                  cursor: currentSlide !== "new" ? "pointer" : "default",
                }}
                onMouseLeave={
                  movable && currentSlide !== "new"
                    ? videoBgPositionHandlers.onMouseLeave
                    : () => {}
                }
                onMouseDown={
                  movable && currentSlide !== "new"
                    ? videoBgPositionHandlers.onMouseDown
                    : () => {}
                }
                onMouseMove={
                  movable && currentSlide !== "new"
                    ? videoBgPositionHandlers.onMouseMove
                    : () => {}
                }
                onMouseUp={
                  currentSlide !== "new"
                    ? videoBgPositionHandlers.onMouseUp
                    : () => {}
                }
                onTouchStart={
                  movable && currentSlide !== "new"
                    ? videoBgPositionHandlers.onTouchStart
                    : () => {}
                }
                onTouchMove={
                  movable && currentSlide !== "new"
                    ? videoBgPositionHandlers.onTouchMove
                    : () => {}
                }
                onTouchEnd={
                  currentSlide !== "new"
                    ? videoBgPositionHandlers.onTouchEnd
                    : () => {}
                }
                layoutId={"video"}
              >
                {movable && currentSlide !== "new" && (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 3,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgb(32 32 32 / 50%)",
                      width: "100%",
                      padding: "min(10px, 1vh) 0px min(16px, 2vh) 0px",
                    }}
                  >
                    <div
                      style={{
                        color: "white",
                        fontSize: "min(10px, 1vh)",
                        fontWeight: 500,
                        letterSpacing: 1,
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                    >
                      Drag to position
                    </div>
                    <div
                      style={{ position: "relative", width: 16, height: 16 }}
                    >
                      <BsHandIndex
                        style={{
                          fontSize: "min(14px, 1.3vh)",
                          top: 8,
                          fill: "white",
                          position: "absolute",
                          zIndex: 2,
                        }}
                      />
                      <BsArrowsMove
                        style={{
                          fontSize: "min(12px, 1.3vh)",
                          fill: "white",
                          position: "absolute",
                          zIndex: 1,
                        }}
                      />
                    </div>
                  </div>
                )}
                {videoState && videoTemplate && (
                  <Video
                    frame={currentFrame}
                    videoState={videoState}
                    videoTemplate={videoTemplate}
                    previewResolution={{
                      width: videoPlayerWidth,
                      height: videoPlayerHeight,
                    }}
                  />
                )}
              </motion.div>
            </div>
            <Controls
              play={play}
              setPlay={setPlay}
              currentFrame={currentFrame}
              setCurrentFrame={setCurrentFrame}
              totalFps={
                videoState
                  ? (videoState.slides.length + 1) *
                    videoOrderData.getVideoOrder.template.secondsPerSlide *
                    videoOrderData.getVideoOrder.template.fps
                  : 0
              }
            />
          </>
        )}

        {expanded && (
          <>
            <motion.div
              id="expanded-video"
              style={{
                width: "100vw",
                height: "100%",
                position: "fixed",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                top: 0,
                zIndex: 30,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={() => {
                setExpanded(false);
              }}
            >
              <motion.div
                onMouseLeave={
                  movable ? videoBgPositionHandlers.onMouseLeave : () => {}
                }
                onMouseDown={
                  movable ? videoBgPositionHandlers.onMouseDown : () => {}
                }
                onMouseMove={
                  movable ? videoBgPositionHandlers.onMouseMove : () => {}
                }
                onMouseUp={videoBgPositionHandlers.onMouseUp}
                onTouchStart={
                  movable ? videoBgPositionHandlers.onTouchStart : () => {}
                }
                onTouchMove={
                  movable ? videoBgPositionHandlers.onTouchMove : () => {}
                }
                onTouchEnd={videoBgPositionHandlers.onTouchEnd}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{
                  width:
                    videoState.resolution === "LANDSCAPE"
                      ? innerWidth * 0.6667 > innerHeight - 74
                        ? innerHeight
                        : innerWidth
                      : innerHeight * 0.5625 * 0.7,
                  height:
                    videoState.resolution === "LANDSCAPE"
                      ? innerWidth * 0.6667 > innerHeight - 74
                        ? innerHeight * 0.6667
                        : innerWidth * 0.6667
                      : innerHeight * 0.7,
                  opacity: 1,
                }}
                layoutId={"video"}
              >
                {videoState && videoTemplate && (
                  <Video
                    frame={currentFrame}
                    videoState={videoState}
                    videoTemplate={videoTemplate}
                    previewResolution={{
                      width:
                        videoState.resolution === "LANDSCAPE"
                          ? innerWidth * 0.6667 > innerHeight - 74
                            ? innerHeight
                            : innerWidth
                          : innerHeight * 0.5625 * 0.7,
                      height:
                        videoState.resolution === "LANDSCAPE"
                          ? innerWidth * 0.6667 > innerHeight - 74
                            ? innerHeight * 0.6667
                            : innerWidth * 0.6667
                          : innerHeight * 0.7,
                    }}
                  />
                )}
              </motion.div>
              <Controls
                play={play}
                setPlay={setPlay}
                currentFrame={currentFrame}
                setCurrentFrame={setCurrentFrame}
                totalFps={
                  videoState
                    ? (videoState.slides.length + 1) *
                      videoOrderData.getVideoOrder.template.secondsPerSlide *
                      videoOrderData.getVideoOrder.template.fps
                    : 0
                }
              />
            </motion.div>
          </>
        )}

        {/* Overlay for fading in and out when expanding video */}
        <AnimatePresence exitBeforeEnter={true}>
          {expanded && (
            <motion.div
              id="exapand-bg"
              style={{
                backgroundColor: "#232323",
                width: "100%",
                height: "100%",
                position: "absolute",
                zIndex: 10,
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            ></motion.div>
          )}
        </AnimatePresence>
      </AnimateSharedLayout>
    </>
  );
}

export default VideoPlayer;
