import gql from "graphql-tag";

export const GET_VIDEO_ORDER = gql`
  query getVideoOrder {
    getVideoOrder {
      id
      revisionLimit
      revisionCount
      socketIdEditing
      video {
        state
      }
      content {
        images {
          category
          thumbnailUrl
          imageUrl
        }
        metaData
      }
      template {
        name
        textColor
        textBackground
        textSize
        maxSlides
        secondsPerSlide
        logotype
        animation
        transition
        fps
      }
      export {
        exportedAt
        url
        status
        exportedVideoState
      }
    }
  }
`;

export const GET_VIDEO_EXPORT_STATUS = gql`
  query getVideoExportStatus {
    getVideoExportStatus
  }
`;
