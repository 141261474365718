import gql from 'graphql-tag';
const typeDefs = gql`

  type Position {
    scale: Float,
    translateX: Float,
    translateY: Float
  }

  type Image {
    url: String,
    startFrame: Position,
    endFrame: Position,
    animationSpeed: Int,
    id: String
  }

  type Text {
    id: String
    text: String
  }

  type Item {
    url: String,
    id: String
  }

  type Slider {
    id: String,
    images: [String]
  }

  type Map {
    lat: Float,
    lng: Float,
    zoom: Int,
    id: String
  }

  union ItemTypes = Item | Slider | Map | Image

  type Grid {
    id: String,
    type: String,
    items: [ItemTypes],
    texts: [Text],
  }

  type Section {
    id: String,
    tag: String,
    title: String,
    grids: [Grid]
  }

  type Query {
    sections: [Section],
    sectionGrids(sectionId: String): [Grid],
    item(gridId: String, position: Int): ItemTypes,
    image(imageId: String): Image,
    map(itemId: String): Map,
    slider(itemId: String): Slider,
    text(textId: String): Text,
    sectionTitle(sectionId: String): String,
    sectionTag(sectionId: String): String
  }

  type Mutation {
    addSection: Boolean,
    addHeader: Boolean,
    addFooter: Boolean,
    addGrid(sectionId: String, type: String): Boolean,
    updateSectionTag(sectionId: String, tag: String): Boolean,
    updateSectionTitle(sectionId: String, title: String): Boolean,
    updateGridTexts(gridId: String, text: String, position: Int): Boolean,
    deleteGridItem(gridId: String, position: Int): Boolean,
    createGridItem(gridId:String, position:String, type:String, url:String, lat:String, lng:String ): Boolean,
    updateGridIframe(gridId: String, url: String, position: Int): Boolean,
    updateGridTextArea(gridId: String, text: String, position: Int): Boolean,
    updateGridSliderImage(itemId:String, index:Int, scale: Float, translateX: Float, translateY: Float): Boolean,
    deleteGridSliderImage(itemId:String, index:Int): Boolean,
    updateGridImage(imageId:String, frame:Int, scale: Float, translateX: Float, translateY: Float, speed: Int): Boolean,
    updateGridMap(gridId: String, lat: Float, lng: Float, position: Int, zoom: Int): Boolean,
    updateGridSlider(gridId: String, position: Int, index:Int, url: String): Boolean,
    reorderGrid(gridId: String, sectionSourceId: String, sectionDestinationId: String, gridSourceIndex: Int, gridDestinationIndex: Int): Boolean,
    reorderSection(sectionId: String, sectionSourceIndex: Int, sectionDestinationIndex: Int): Boolean,
    deleteSection(sectionIndex:Int): Boolean,
    deleteGrid(sectionId:String, gridIndex:Int): Boolean
  }
`;
  export default typeDefs;