import React, { useEffect, useRef, useState } from "react";
import { debounce } from "../utils";
import Thumbnails from "./Thumbnails";
import { VideoState, VideoTemplate } from "./Video";

type Props = {
  videoTemplate: VideoTemplate;
  setCurrentSlide: Function;
  currentSlide: number | "new";
  setOpenPanel: Function;
  setCurrentFrame: Function;
  currentFrame: number;
  videoState: VideoState;
  setVideoState: Function;
};

function Timeline(props: Props) {
  const [left, setLeft] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startOffsetX, setStartOffsetX] = useState(0);
  const [startX, setStartX] = useState(0);
  const [isTouchDown, setIsTouchDown] = useState(false);
  const [currentTime, setCurrentTime] = useState<string>("00 : 00");

  const timelineRef = useRef(null);
  const {
    videoState,
    setVideoState,
    videoTemplate,
    setCurrentSlide,
    currentSlide,
    setOpenPanel,
    setCurrentFrame,
    currentFrame,
  } = props;

  const slides = videoState ? videoState.slides : [];

  const leftToCurentFrame = (x) => {
    const percentage = x / timelineRef.current.offsetWidth;
    const totalFps =
      (slides.length + 1) * videoTemplate.secondsPerSlide * videoTemplate.fps;
    const currentFrame = Math.round(totalFps * percentage);
    return currentFrame;
  };

  useEffect(() => {
    if (timelineRef && timelineRef.current) {
      const totalFps =
        (slides.length + 1) * videoTemplate.secondsPerSlide * videoTemplate.fps;
      const percentage = currentFrame / totalFps;
      setLeft(timelineRef.current.offsetWidth * percentage);
    }
  }, [currentFrame]);

  useEffect(() => {
    if (videoTemplate) {
      // Setting time display text
      const totSeconds = currentFrame / videoTemplate.fps;

      const minute = Math.floor(totSeconds / 60);
      const second = Math.floor(totSeconds % 60);
      const frame = currentFrame % videoTemplate.fps;

      if (minute > 9 && second > 9) {
        setCurrentTime(`${minute} : ${second}`);
      } else if (minute > 9) {
        setCurrentTime(`${minute} : 0${second}`);
      } else if (second > 9) {
        setCurrentTime(`0${minute} : ${second}`);
      } else {
        setCurrentTime(`0${minute} : 0${second}`);
      }
    }
  }, [currentFrame, videoTemplate]);

  if (!videoTemplate) {
    return <></>;
  }

  const totDuration = (slides.length + 1) * videoTemplate.secondsPerSlide; // + 1 for add button

  let largeLines = Array(totDuration + 1)
    .fill(0)
    .map((value, i) => {
      return i;
    });

  let showEveryXth = 1;
  if (largeLines.length > 42) {
    showEveryXth = 12;
  } else if (largeLines.length > 28) {
    showEveryXth = 8;
  } else if (largeLines.length > 12) {
    showEveryXth = 4;
  }

  const nSmallLines =
    showEveryXth === 1 || showEveryXth === 2
      ? largeLines.length * 2 - 1
      : largeLines.length;
  let smallLines = Array(nSmallLines)
    .fill(0)
    .map((value, i) => {
      return i;
    });

  const interactionHandlers = {
    onTouchStart: (e) => {
      e.preventDefault();
      var touch = e.touches[0] || e.changedTouches[0];
      if (touch) {
        var rect = e.currentTarget.getBoundingClientRect();
        var x = touch.clientX - rect.left;
        setIsTouchDown(true);
        setStartOffsetX(x - left);
      }
    },
    onTouchMove: (e) => {
      e.preventDefault();
      var touch = e.touches[0] || e.changedTouches[0];
      if (touch) {
        var rect = e.currentTarget.getBoundingClientRect();
        var x = touch.clientX - rect.left;
        if (isTouchDown) {
          var returnedFunction = debounce(function () {
            if (!isDragging) {
              setIsDragging(true);
            }
            const newPos = x - startOffsetX;
            if (newPos < 0) {
              setCurrentFrame(leftToCurentFrame(0));
            } else if (newPos > timelineRef.current.offsetWidth) {
              setCurrentFrame(
                leftToCurentFrame(timelineRef.current.offsetWidth)
              );
            } else {
              setCurrentFrame(leftToCurentFrame(newPos));
            }
          }, 1);
          returnedFunction();
        }
      }
    },
    onTouchEnd: (e) => {
      e.preventDefault();
      var touch = e.touches[0] || e.changedTouches[0];
      if (touch) {
        var rect = e.currentTarget.getBoundingClientRect();
        var x = touch.clientX - rect.left;
        if (!isDragging) {
          if (x < 0) {
            setCurrentFrame(leftToCurentFrame(0));
          } else if (x > timelineRef.current.offsetWidth) {
            setCurrentFrame(leftToCurentFrame(timelineRef.current.offsetWidth));
          } else {
            setCurrentFrame(leftToCurentFrame(x));
          }
        }
        setIsTouchDown(false);
        setIsDragging(false);
      }
    },
    onMouseDown: (e) => {
      e.preventDefault();
      var rect = e.currentTarget.getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      setIsMouseDown(true);
      setStartOffsetX(x - left);
      setStartX(x);
    },
    onMouseMove: (e) => {
      if (isMouseDown) {
        e.preventDefault();
        var rect = e.currentTarget.getBoundingClientRect();
        var x = e.clientX - rect.left;
        if (
          Math.abs(startX - x) > 4 // prevent activating dragging when mouse moves small distance (user often moves the mouse a bit when clicking)
        ) {
          var returnedFunction = debounce(function () {
            if (!isDragging) {
              setIsDragging(true);
            }
            const newPos = x - startOffsetX;
            if (newPos < 0) {
              setCurrentFrame(leftToCurentFrame(0));
            } else if (newPos > timelineRef.current.offsetWidth) {
              setCurrentFrame(
                leftToCurentFrame(timelineRef.current.offsetWidth)
              );
            } else {
              setCurrentFrame(leftToCurentFrame(newPos));
            }
          }, 1);
          returnedFunction();
        }
      }
    },
    onMouseUp: (e) => {
      e.preventDefault();
      var rect = e.currentTarget.getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      if (!isDragging) {
        if (x < 0) {
          setCurrentFrame(leftToCurentFrame(0));
        } else if (x > timelineRef.current.offsetWidth) {
          setCurrentFrame(leftToCurentFrame(timelineRef.current.offsetWidth));
        } else {
          setCurrentFrame(leftToCurentFrame(x));
        }
      }
      setIsMouseDown(false);
      setIsDragging(false);
    },
    onMouseLeave: (e) => {
      setIsMouseDown(false);
      setIsDragging(false);
    },
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#2F2F2F",
        // minHeight: 200,
      }}
    >
      {/* Timeline */}
      <div
        ref={timelineRef}
        onMouseDown={interactionHandlers.onMouseDown}
        onMouseMove={interactionHandlers.onMouseMove}
        onMouseUp={interactionHandlers.onMouseUp}
        onMouseLeave={interactionHandlers.onMouseLeave}
        onTouchStart={interactionHandlers.onTouchStart}
        onTouchMove={interactionHandlers.onTouchMove}
        onTouchEnd={interactionHandlers.onTouchEnd}
        style={{
          // height: 60,
          // backgroundColor: "red",
          width: "85%",
          paddingTop: 20,
          paddingBottom: 20,
          marginLeft: "7.5%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "white",
          position: "relative",
        }}
      >
        {/* Timeline cursor + dashed line */}
        <>
          <div
            style={{
              left: left - 28,
              right: 0,
              top: 18,
              padding: "4px 8px",
              backgroundColor: "white",
              borderRadius: 10,
              width: 40,
              textAlign: "center",
              position: "absolute",
              fontSize: 10,
              color: "#2F2F2F",
              fontWeight: "bold",
              cursor: "default",
            }}
          >
            {currentTime}
          </div>
          <div
            style={{
              width: 0,
              height: 0,
              borderLeft: "6px solid transparent",
              borderRight: "6px solid transparent",
              borderTop: "6px solid white",
              left: left - 5.5,
              top: 38,
              position: "absolute",
            }}
          ></div>
          <div
            style={{
              width: 1,
              backgroundColor: "transparent",
              position: "absolute",
              borderLeft: "1px dashed white",
              height: 154,
              left: left,
              top: 26,
            }}
          ></div>
        </>

        {/* Small lines - absolute positioned over large lines */}
        <div
          style={{
            position: "absolute",
            left: 0,
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {smallLines.map((second, i) => {
            return (
              <div
                key={"small-" + i}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 24,
                  alignItems: "center",
                  width: 0,
                }}
              >
                <div
                  style={{
                    width: 2,
                    height: 4,
                    backgroundColor: "white",
                    // marginLeft: 2,
                  }}
                ></div>
              </div>
            );
          })}
        </div>

        {/* Large lines and numbers */}
        {largeLines.map((second, i) => {
          return (
            <div
              key={"large-" + i}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: 0,
              }}
            >
              {(second % showEveryXth === 0 || largeLines.length === i + 1) && (
                <div style={{ fontSize: 10, marginBottom: 4 }}>{second}</div>
              )}
              <div
                style={{
                  width: 2,
                  height: 6,
                  backgroundColor:
                    second % showEveryXth === 0 || largeLines.length === i + 1
                      ? "white"
                      : "transparent",
                }}
              ></div>
            </div>
          );
        })}
      </div>
      {/* Slides thumbnails */}
      <Thumbnails
        videoState={videoState}
        setVideoState={setVideoState}
        currentSlide={currentSlide}
        setCurrentFrame={setCurrentFrame}
        videoTemplate={videoTemplate}
        setCurrentSlide={setCurrentSlide}
        setOpenPanel={setOpenPanel}
      />
    </div>
  );
}

export default Timeline;
