import moment from 'moment-timezone';
import 'moment/locale/sv'
export default function formatDate(d:Date) {
    const date = moment(d);
    const today = moment.tz(new Date(), "Europe/Stockholm")
    const yesterday = moment.tz(new Date(), "Europe/Stockholm").subtract(1,'day');

    if (date.format('YYYY-MM-DD') === today.format('YYYY-MM-DD')) {
      return "Today, "+ date.format('LT');
    } else if (date.format('YYYY-MM-DD') === yesterday.format('YYYY-MM-DD')) {
      return "Yesterday, "+ date.format('LT');
    } else {
      return (date.format("D MMM") +" "+ date.format('LT'));
    }
  }
