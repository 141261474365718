import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { Panel } from "./VideoEditor";
import { GetVideoOrderQuery } from "../graphql/graphqlTypes-video-queries";
import { MdAdd } from "react-icons/md";
import { Slide, VideoState, VideoTemplate } from "./Video";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

type ThumbnailsProps = {
  videoState: VideoState;
  setVideoState: Function;
  currentSlide: number | "new";
  videoTemplate: VideoTemplate;
  setCurrentFrame: Function;
  setCurrentSlide: Function;
  setOpenPanel: Function;
};

const Thumbnails = (props: ThumbnailsProps) => {
  const {
    videoState,
    setVideoState,
    currentSlide,
    videoTemplate,
    setCurrentFrame,
    setCurrentSlide,
    setOpenPanel,
  } = props;

  const slides = videoState ? videoState.slides : [];

  const [items, setItems] = useState<any>([]);

  const [thumbnailWidth, setThumbnailWidth] = useState(
    (window.innerWidth * 0.85) / (slides.length + 1)
  );

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    setVideoState({
      ...videoState,
      slides: reorder(items, result.source.index, result.destination.index),
    });
    console.log("SET CURRENT SLIDE TO", result.destination.index);

    // "Force" currentSlide change to update text state in ModalPanel
    setCurrentSlide(0);
    setItems(reorder(items, result.source.index, result.destination.index));
  };

  useEffect(() => {
    const calculateThumbnailWidth = () => {
      setThumbnailWidth((window.innerWidth * 0.85) / (slides.length + 1));
    };
    window.addEventListener("resize", calculateThumbnailWidth);
    calculateThumbnailWidth();

    return () => {
      window.removeEventListener("resize", calculateThumbnailWidth);
    };
  }, [slides]);

  useEffect(() => {
    if (videoState) {
      setItems(
        videoState.slides.map((slide, i) => {
          return { ...slide, id: i + "" };
        })
      );
    }
  }, [videoState]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              height: 44,
              width: slides.length ? `calc(85% - ${thumbnailWidth}px)` : "85%",
              marginLeft: "7.5%",
              padding: "0px 0px 20px 0px",
              position: "relative",
            }}
            {...provided.droppableProps}
          >
            {items.map((item, index) => (
              <React.Fragment key={index}>
                {index === 0 ? (
                  <ThumbNail
                    index={index}
                    slide={item}
                    currentSlide={currentSlide}
                    setCurrentFrame={setCurrentFrame}
                    videoTemplate={videoTemplate}
                  />
                ) : (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <ThumbNail
                        provided={provided}
                        snapshot={snapshot}
                        index={index}
                        slide={item}
                        currentSlide={currentSlide}
                        setCurrentFrame={setCurrentFrame}
                        videoTemplate={videoTemplate}
                      />
                    )}
                  </Draggable>
                )}
              </React.Fragment>
            ))}
            <div
              style={{
                width: slides.length
                  ? currentSlide === "new"
                    ? thumbnailWidth - 6
                    : thumbnailWidth - 2
                  : "100%",
                height: 40,
                marginTop: currentSlide === "new" ? 0 : 2,
                position: "absolute",
                right: slides.length ? -thumbnailWidth : 0,
                top: 0,
                backgroundColor: "white",
                borderRadius: 10,
                borderTopLeftRadius: slides.length ? 0 : 10,
                borderBottomLeftRadius: slides.length ? 0 : 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                border: currentSlide === "new" ? "2px solid white" : "none",
                opacity:
                  videoTemplate.maxSlides > videoState.slides.length ? 1 : 0.5,
              }}
              onClick={() => {
                if (videoTemplate.maxSlides > videoState.slides.length) {
                  setCurrentSlide("new");
                  setOpenPanel(Panel.IMAGE);
                }
              }}
            >
              <MdAdd style={{ fontSize: 20, color: "rgb(47, 47, 47)" }} />
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

type ThumbNailProps = {
  provided?: DraggableProvided;
  snapshot?: DraggableStateSnapshot;
  slide: Slide;
  index: number;
  currentSlide: number | "new";
  videoTemplate: VideoTemplate;
  setCurrentFrame: Function;
};

const ThumbNail = (props: ThumbNailProps) => {
  const {
    provided,
    snapshot,
    slide,
    index,
    currentSlide,
    videoTemplate,
    setCurrentFrame,
  } = props;

  const draggableProps = provided
    ? { ...provided.draggableProps, ...provided.dragHandleProps }
    : {};
  const draggableStyle = provided ? { ...provided.draggableProps.style } : {};
  return (
    <div
      ref={provided ? provided.innerRef : null}
      {...draggableProps}
      style={{
        width: "100%",
        backgroundImage: `url('${slide.thumbnailUrl}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: 40,
        margin: 2,
        borderTopLeftRadius: index === 0 ? 10 : 0,
        borderBottomLeftRadius: index === 0 ? 10 : 0,
        cursor: "pointer",
        border: currentSlide === index ? "2px solid white" : "none",
        ...draggableStyle,
        opacity: snapshot && snapshot.isDragging ? 0.5 : 1,
      }}
      onClick={() => {
        let currentFrame = Math.round(
          index * videoTemplate.secondsPerSlide * videoTemplate.fps +
            (videoTemplate.secondsPerSlide * videoTemplate.fps) / 2
        );
        if (currentFrame !== 0) {
          currentFrame += 1;
        }
        setCurrentFrame(currentFrame);
      }}
    ></div>
  );
};

export default Thumbnails;
