import React, { useContext, useEffect, useState } from 'react';
import './Modal.css';
import { ModalContext } from '../context';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ADD_GRID } from '../graphql/mutations';
import Grid1 from '../Grid/Grid1';
import Grid2 from '../Grid/Grid2';
import Grid3 from '../Grid/Grid3';
import Grid4 from '../Grid/Grid4';
import Grid5 from '../Grid/Grid5';
import Grid6 from '../Grid/Grid6';
import Grid7 from '../Grid/Grid7';
import Grid8 from '../Grid/Grid8';
import Grid9 from '../Grid/Grid9';
import Expandable from '../Expandable/Expandable';
import { IoMdClose } from 'react-icons/io';

function Modal(props:any) {
  const sectionId = props.sectionId;
  const modal = useContext(ModalContext);
  const [mutate] = useMutation(ADD_GRID);
  const [page, setPage] = useState(1);

  useEffect(()=>{
    document.addEventListener('keydown', function(e) {
      if (e.key == "Escape") {
        modal.toggle()
      }
    });
  }, [])
  return (
    <div className="modal-container" onClick={()=>{modal.toggle()}}>
      <div className="modal-content-container" onClick={(e)=>e.stopPropagation()}>
        <div style={{height:'10%', backgroundColor: '#232323', display:'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div className="layout-modal-title">LAYOUT</div>
          <div onClick={()=>modal.toggle()} style={{
            position: 'absolute',
            color:'white',
            fontSize: '3vw',
            cursor: 'pointer',
            right: 20
          }}><IoMdClose title="Close" /></div>
        </div>
        <div className="divider"></div>
        {page === 1 && <div className="layout-grid">
          <div className="layout-container" onClick={()=>{mutate({variables:{sectionId:sectionId, type:"1"}});modal.toggle();}}><Grid1 preview /></div>
          <div className="layout-container" onClick={()=>{mutate({variables:{sectionId:sectionId, type:"2"}});modal.toggle();}}><Grid2 preview /></div>
          <div className="layout-container" onClick={()=>{mutate({variables:{sectionId:sectionId, type:"3"}});modal.toggle();}}><Grid3 preview /></div>
          <div className="layout-container" onClick={()=>{mutate({variables:{sectionId:sectionId, type:"4"}});modal.toggle();}}><Grid4 preview /></div>
          <div className="layout-container" onClick={()=>{mutate({variables:{sectionId:sectionId, type:"5"}});modal.toggle();}}><Grid5 preview /></div>
          <div className="layout-container" onClick={()=>{mutate({variables:{sectionId:sectionId, type:"6"}});modal.toggle();}}><Grid6 preview /></div>
          <div className="layout-container" onClick={()=>{mutate({variables:{sectionId:sectionId, type:"7"}});modal.toggle();}}><Grid7 preview /></div>
          <div className="layout-container" onClick={()=>{mutate({variables:{sectionId:sectionId, type:"8"}});modal.toggle();}}><Grid8 preview /></div>
          <div className="layout-container" onClick={()=>{mutate({variables:{sectionId:sectionId, type:"9"}});modal.toggle();}}><Grid9 preview /></div>
        </div>}
        {page === 2 && <div className="layout-grid">
        <div className="layout-container" onClick={()=>{mutate({variables:{sectionId:sectionId, type:"10"}});modal.toggle();}}><Expandable preview /></div>

        </div>}
        {page === 1 && <div className="ion-ios-arrow-right modal-arrow right" onClick={()=>{setPage(2)}}></div>}
        {page === 2 && <div className="ion-ios-arrow-left modal-arrow left" onClick={()=>{setPage(1)}}></div>}
      </div>
    </div>
  );
}

export default Modal;
