import React, {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import './Dropzone.css'
import axios from 'axios';
import { useParams } from 'react-router-dom';
 
function Dropzone(props:any) {
  const { type, refetchProject } = props;
  const [loading, setLoading] = useState(false);
  let { projectId } = useParams();

  const onDrop = useCallback(async acceptedFiles => {
    if (!acceptedFiles.length) {
      return;
    }
    setLoading(true);
    for (var i = 0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('projectId', projectId);
      const token = localStorage.getItem('jwt-viewfinder')
      try {
        await axios.post('/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'authorization': `Bearer ${token}`
          }
        })
      } catch(err) {
        console.log(err);
      }
    }
    refetchProject();
    setLoading(false);
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop: onDrop,
    accept: type === "Image" ? 'image/jpeg, image/png' : 'video/mp4, video/webm, video/quicktime'
  })

  const dropArea = () => {
    if (!loading) {
      return  isDragActive ?
      <div className="drop-area-container"><div className="drop-area active ion-upload"><span style={{fontSize:10, position: 'absolute', bottom: 10}}>{type === "Image" ? "JPEG/PNG" : "MP4/MOV/WEBM"}</span></div></div> :
        <div className="drop-area-container"><div className="drop-area ion-upload"><span style={{fontSize:10, position: 'absolute', bottom: 10}}>{type === "Image" ? "JPEG/PNG" : "MP4/MOV/WEBM"}</span></div></div>
    } else {
      return <div className="drop-area-container">
        <div className="drop-area">
          <div style={{height:10, width:'50%', border: '1px solid white'}}>
            <div className="load-bar"></div>
          </div>
          <span style={{fontSize:10, position: 'absolute', bottom: 10}}>
            {type === "Image" ? "JPEG/PNG" : "MP4/MOV/WEBM"}
          </span>
        </div>
      </div>
    }

  }

  return (
    <>
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {
       dropArea()
      } 
    </div>
    </>
  )
}

export default Dropzone;