import React from 'react';
import './Snackbar.css';

function Snackbar(props:any) {
  const { text, success, open } = props;

  let bgColor = success ? '#232323' : '#f58f00';
  return (
    <div className="snackbar" style={open ? {bottom: 70, backgroundColor: bgColor} : {bottom:-70, backgroundColor: bgColor}}>
      <div className={success ? "ion-checkmark" : "ion-close"} style={{marginRight:'5%'}}></div>
      <div>{text}</div>
    </div>
  );
}

export default Snackbar;