import React from 'react';
import './Grid.css';
import GridItem from '../GridItem/GridItem';

function Grid5(props:any) {
  const {gridId, preview } = props;
  return (
    <>
      {preview ? 
      <div className="grid grid5 preview">
        <div className="bg-img grid5-1"/>
        <div className="bg-img grid5-2"/>
      </div> : <div className="grid grid5">
        <GridItem className="bg-img grid5-1" gridId={gridId} position={1}/>
        <GridItem className="bg-img grid5-2" gridId={gridId} position={2}/>
      </div>}
    </>
  );
}

export default Grid5;