import React from 'react';
import './Grid.css';
import GridItem from '../GridItem/GridItem';

function Grid3(props:any) {
  const {gridId, preview } = props;
  return (
    <>
    {preview ? 
      <div className="grid grid3 preview">
        <div className="bg-img grid3-1"/>
      </div>
      : <div className="grid grid3">
        <GridItem className="bg-img grid3-1" gridId={gridId} position={1}/>
      </div>
    }
    </>
  );
}

export default Grid3;