import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;

export const REGISTER = gql`
  mutation register($name: String, $email: String, $disabled: Boolean, $password: String, $divisionId: String, $registerToken: String) {
    register(name: $name, email: $email, disabled: $disabled, password: $password, divisionId: $divisionId, registerToken: $registerToken) {
      id
      name
      email
      disabled
      success
      error {
        code
        msg
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation createProject($userId: String, $newTemplate: Boolean) {
    createProject(userId: $userId, newTemplate: $newTemplate) {
      id,
      name
    }
  }
`;

export const LAST_ACCESSED = gql`
  mutation lastAccessed($projectId: String!) {
    lastAccessed(projectId: $projectId) {
      success
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation deleteProject($projectId: String!) {
    deleteProject(projectId: $projectId) {
      success
    }
  }
`;

export const DUPLICATE_PROJECT = gql`
  mutation duplicateProject($projectId: String!, $fromTemplate: Boolean, $template: Boolean, $toTemplate: Boolean ) {
    duplicateProject(projectId: $projectId, fromTemplate: $fromTemplate, template: $template, toTemplate: $toTemplate) {
      success
    }
  }
`;

export const SAVE_CACHE = gql`
  mutation saveCache($projectId: String!, $cache: String!) {
    saved(saved: true) @client
    saveCache(projectId: $projectId, cache: $cache) {
      success
    }
  }
`;

export const BUILD = gql`
  mutation build($projectId: String!, $preview: Boolean) {
    build(projectId: $projectId, preview: $preview) {
      success
    }
  }
`;

export const DELETE_UPLOAD = gql`
  mutation deleteUpload($fileId: String!, $projectId: String!) {
    deleteUpload(fileId: $fileId, projectId: $projectId) {
      success
    }
  }
`;

export const UPDATE_PROJECT_NAME = gql`
  mutation updateProjectName($projectId: String!, $name: String!) {
    updateProjectName(projectId: $projectId, name: $name) {
      success
    }
  }
`;

export const UNPUBLISH = gql`
  mutation unpublish($projectId: String!) {
    unpublish(projectId: $projectId) {
      success
    }
  }
`;

export const UPDATE_TEMPLATE_ACCOUNT = gql`
  mutation updateTemplateAccount($template: String!, $accountId: String!, $disconnect: Boolean) {
    updateTemplateAccount(template: $template, accountId: $accountId, disconnect: $disconnect) {
      success
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount($accountId: String!, $name: String, $logoUrl: String, $disabled: Boolean) {
    updateAccount(accountId: $accountId, name: $name, logoUrl: $logoUrl, disabled: $disabled) {
      success
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount($accountId: String!) {
    deleteAccount(accountId: $accountId) {
      success
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation createAccount($name: String) {
    createAccount(name: $name) {
      id
      name
      logoUrl
      disabled
    }
  }
`;

export const RECOVER_PASSWORD = gql`
  mutation recoverPassword($email: String!) {
    recoverPassword(email: $email) {
      success
    }
  }
`;

export const UPDATE_DIVISION = gql`
  mutation updateDivision($divisionId: String!, $name: String, $parentDivisionId: String) {
    updateDivision(divisionId: $divisionId, name: $name, parentDivisionId: $parentDivisionId) {
      success
    }
  }
`;

export const CREATE_DIVISION = gql`
  mutation createDivision($name: String!, $parentDivisionId: String!) {
    createDivision(name: $name, parentDivisionId: $parentDivisionId) {
      id
    }
  }
`;

export const DELETE_DIVISION = gql`
  mutation deleteDivision($divisionId: String!) {
    deleteDivision(divisionId: $divisionId) {
      success
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($userId: String!, $name: String!, $divisionId: String!, $email: String!, $disabled: Boolean! ) {
    updateUser(userId: $userId, name: $name, divisionId: $divisionId, email: $email, disabled: $disabled) {
      id
      name
      email
      disabled
      success
      error {
        code
        msg
      }
    }
  }
`;

export const SEND_REGISTRATION_MAIL = gql`
  mutation sendRegistrationMail($userId: String!) {
    sendRegistrationMail(userId: $userId) {
      success
    }
  }
`;

export const UPDATE_USER_ARCHIVED = gql`
  mutation updateUserArchived($userId: String!, $archived: Boolean!) {
    updateUserArchived(userId: $userId, archived: $archived) {
      success
    }
  }
`;

export const UPDATE_DIVISION_USER_ROLES = gql`
  mutation updateDivisionUserRoles($userId: String!, $divisionId: String!, $addedRoleIds: [String], $removedRoleIds: [String]) {
    updateDivisionUserRoles(userId: $userId, divisionId: $divisionId, addedRoleIds: $addedRoleIds, removedRoleIds: $removedRoleIds) {
      success
    }
  }
`;

export const ADD_SECTION = gql`
  mutation addSection {
    addSection @client
    saved(saved: false) @client
  }
`;

export const ADD_HEADER = gql`
  mutation addHeader {
    addHeader @client
    saved(saved: false) @client
  }
`;

export const ADD_FOOTER = gql`
  mutation addFooter {
    addFooter @client
    saved(saved: false) @client
  }
`;

export const ADD_GRID = gql`
  mutation addGrid($sectionId: String, $type: String) {
    addGrid(sectionId: $sectionId, type: $type) @client
    saved(saved: false) @client
  }
`;

export const UPDATE_SECTION_TAG = gql`
  mutation updateSectionTag($sectionId: String, $tag: String) {
    updateSectionTag(sectionId: $sectionId, tag: $tag) @client
    saved(saved: false) @client
  }
`;

export const UPDATE_SECTION_TITLE = gql`
  mutation updateSectionTitle($sectionId: String, $title: String) {
    updateSectionTitle(sectionId: $sectionId, title: $title) @client
    saved(saved: false) @client
  }
`;

export const UPDATE_GRID_TEXTS = gql`
  mutation updateGridTexts($gridId: String, $text: String, $position: Int) {
    updateGridTexts(gridId: $gridId, text: $text, position: $position) @client
    saved(saved: false) @client
  }
`;

export const UPDATE_GRID_IFRAME = gql`
  mutation updateGridIframe($gridId: String, $url: String, $position: Int) {
    updateGridIframe(gridId: $gridId, url: $url, position: $position) @client
    saved(saved: false) @client
  }
`;

export const UPDATE_GRID_MAP = gql`
  mutation updateGridMap($gridId: String, $lat: Float, $lng: Float, $position: Int, $zoom: Int) {
    updateGridMap(gridId: $gridId, lat: $lat, lng: $lng, position: $position, zoom: $zoom) @client
    saved(saved: false) @client
  }
`;

export const UPDATE_GRID_SLIDER = gql`
  mutation updateGridSlider($gridId: String, $position: Int, $index: Int, $url: String) {
    updateTimesUsed(url: $url, gridId: $gridId, position: $position, index: $index) @client
    updateGridSlider(gridId: $gridId, position: $position, index: $index, url: $url) @client
    saved(saved: false) @client
  }
`;

export const UPDATE_GRID_SLIDER_IMAGE = gql`
  mutation updateGridSliderImage($itemId: String, $index: Int, $scale: Int, $translateX: Float, $translateY: Float) {
    updateGridSliderImage(itemId: $itemId, index: $index, scale: $scale, translateX: $translateX, translateY: $translateY) @client
    saved(saved: false) @client
  }
`;

export const DELETE_GRID_SLIDER_IMAGE = gql`
  mutation deleteGridSliderImage($itemId: String, $index: Int) {
    deleteGridSliderImage(itemId: $itemId, index: $index) @client
    saved(saved: false) @client
  }
`;

export const CREATE_GRID_ITEM = gql`
  mutation createGridItem($gridId: String, $url: String, $position: Int, $type: String, $lat: Float, $lng: Float ) {
    updateTimesUsed(url: $url, gridId: $gridId, position: $position) @client
    deleteGridItem(gridId: $gridId, position: $position) @client
    createGridItem(gridId: $gridId, url: $url, position: $position, type: $type, lat: $lat, lng: $lng) @client
    saved(saved: false) @client
  }
`;

export const UPDATE_GRID_TEXTAREA = gql`
  mutation updateGridTextArea($gridId: String, $text: String, $position: Int) {
    updateGridTextArea(gridId: $gridId, text: $text, position: $position) @client
    saved(saved: false) @client
  }
`;

export const UPDATE_GRID_IMAGE = gql`
  mutation updateGridImage($imageId: String, $frame: Int, $scale: Int, $translateX: Float, $translateY: Float, $speed: Int, $showInSlideshow: Boolean) {
    updateGridImage(imageId: $imageId, frame: $frame, scale: $scale, translateX: $translateX, translateY: $translateY, speed: $speed, showInSlideshow: $showInSlideshow) @client
    saved(saved: false) @client
  }
`;

export const REORDER_GRID = gql`
  mutation reorderGrid($gridId: String, $sectionSourceId: String, $sectionDestinationId: String, $gridSourceIndex: Int, $gridDestinationIndex: Int) {
    reorderGrid(gridId: $gridId, sectionSourceId: $sectionSourceId, sectionDestinationId: $sectionDestinationId, gridSourceIndex: $gridSourceIndex, gridDestinationIndex: $gridDestinationIndex) @client
    saved(saved: false) @client
  }
`;

export const REORDER_SECTION = gql`
  mutation reorderSection($sectionId: String, $sectionSourceIndex: Int, $sectionDestinationIndex: Int) {
    reorderSection(sectionId: $sectionId, sectionSourceIndex: $sectionSourceIndex, sectionDestinationIndex: $sectionDestinationIndex) @client
    saved(saved: false) @client
  }

`;

export const DELETE_SECTION = gql`
  mutation deleteSection($sectionIndex: Int) {
    deleteSection(sectionIndex: $sectionIndex) @client
    saved(saved: false) @client
  }

`;

export const DELETE_GRID = gql`
  mutation deleteGrid($sectionId: String, $gridIndex: Int) {
    deleteGrid(sectionId: $sectionId, gridIndex: $gridIndex) @client
    saved(saved: false) @client
  }
`;