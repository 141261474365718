import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ApolloClient, { InMemoryCache } from 'apollo-boost';
import resolvers from './graphql/resolvers'
import schema from './graphql/schema'
import { ApolloProvider } from '@apollo/react-hooks';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from './fragmentTypes.json';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Login from './Login';
import Register from './Register';
import Settings from './Settings'
import VideoEditor from './VFVideo/VideoEditor';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const cache = new InMemoryCache({
  fragmentMatcher: fragmentMatcher,
  addTypename: true,
  dataIdFromObject: object => object.id
});

let win = window as any;
if (win.jwt != "{{JWT}}" && win.jwt != localStorage.getItem("jwt-viewfinder")) {
  localStorage.setItem("jwt-viewfinder", win.jwt)
  window.location.reload();
}

if (window.location.pathname.includes("/video/") && window.location.search.includes("token")) {
  const params:any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop as string),
  });

  let token = params.token;
  const orderId = window.location.pathname.split("/video/")[1];
  localStorage.setItem("jwt-"+orderId, token)
  // Commented out - When saving to PWA the whole url (including token has to be present)
  // window.location.replace(window.location.href.split("?")[0]);
}

const hist = createBrowserHistory();

const client = new ApolloClient({
  cache,
  uri: '/graphql',
  resolvers,
  typeDefs:schema,
  request: (operation) => {

    let token = "";
    if (window.location.pathname.includes("/video/")) {
      const orderId = window.location.pathname.split("/video/")[1];
      token = localStorage.getItem("jwt-"+orderId)
    } else {
      token = localStorage.getItem('jwt-viewfinder')
    }
    
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        "Accept-Encoding": "gzip"
      }
    })
  },
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        console.log(err.extensions)
        if (err.extensions && err.extensions.code === 'UNAUTHENTICATED') {
          console.log("UNAUTHENTICATED")
          hist.push('/builder/login');
        } else if (err.extensions && err.extensions.code === 'FORBIDDEN') {
          console.log("FORBIDDEN")
          // Commented out to prevent loops
          // hist.goBack();
        }
      }
    }
    if (networkError) {
      console.log("network error")
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router history={hist}>
        <Switch>
          <Route path="/builder/settings" component={Settings} />
          <Route path="/builder/register" component={Register} />
          <Route path="/builder/recover" component={Register} />
          <Route path="/builder/login" component={Login} />
          <Route path="/builder/:projectId" component={App} />
          <Route path="/builder" component={App} />
          <Route path="/video" component={VideoEditor} />
        </Switch>
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
